var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal" }, [
    _vm._m(0),
    _c("div", { staticClass: "modal__body" }, [
      _c(
        "div",
        { staticClass: "modal__body__list" },
        [
          _vm._l(_vm.stepsType, function (item, i) {
            return [
              _c("ActionItemButton", {
                key: i,
                staticClass: "modal__body__list__item",
                class: { active: _vm.currentSelect == item.name },
                attrs: { "data-prop": item },
                on: { clicked: _vm.setSelectItem },
              }),
            ]
          }),
        ],
        2
      ),
    ]),
    _c(
      "div",
      { staticClass: "modal--actions" },
      [
        _c("z-btn", {
          attrs: { small: "", text: "Cancelar" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        }),
        _c("z-btn", {
          attrs: { small: "", primary: "", text: "Selecionar" },
          on: { click: _vm.select },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal__header" }, [
      _c("div", { staticClass: "modal__header__left" }, [
        _c("span", [_vm._v("Escolha de step: ")]),
        _c("br"),
        _c("small", [
          _vm._v("Escolha abaixo, qual step deseja criar para fazer a união"),
        ]),
      ]),
      _c("div", { staticClass: "modal__header__right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }