var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modal" },
    [
      _c("div", { staticClass: "modal__header" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "action" },
          [
            _c(
              "v-icon",
              {
                on: {
                  click: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              },
              [_vm._v("mdi-close")]
            ),
          ],
          1
        ),
      ]),
      !_vm.loading
        ? _c("v-form", { ref: "form" }, [
            _c("div", { staticClass: "modal__body" }, [
              _c("div", { staticClass: "content" }, [
                _c(
                  "div",
                  { staticClass: "content__timer-type mb-5" },
                  [
                    _c("div", { staticClass: "repeat-each mb-0" }, [
                      _c("strong", { staticClass: "mb-2" }, [
                        _vm._v(" Tipo: "),
                      ]),
                    ]),
                    _c(
                      "v-radio-group",
                      {
                        attrs: {
                          rules: [(v) => !!v || "Campo obrigatório"],
                          row: "",
                          "hide-details": "",
                          small: "",
                        },
                        model: {
                          value: _vm.timerType,
                          callback: function ($$v) {
                            _vm.timerType = $$v
                          },
                          expression: "timerType",
                        },
                      },
                      [
                        _c("v-radio", {
                          staticStyle: { width: "150px" },
                          attrs: { value: "punctual", small: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#000000" } },
                                      [_vm._v("Pontual")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            4223672352
                          ),
                        }),
                        _c("v-radio", {
                          staticStyle: { width: "150px" },
                          attrs: { value: "recurring" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#000000" } },
                                      [_vm._v("Recorrente")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1782303900
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.timerType == "punctual"
                  ? _c(
                      "section",
                      { staticClass: "content__punctual-info mt-3" },
                      [
                        _c(
                          "v-radio-group",
                          {
                            attrs: {
                              rules: [(v) => !!v || "Campo obrigatório"],
                              "hide-details": "",
                            },
                            on: {
                              change: function ($event) {
                                _vm.executeTime = ""
                              },
                            },
                            model: {
                              value: _vm.startType,
                              callback: function ($$v) {
                                _vm.startType = $$v
                              },
                              expression: "startType",
                            },
                          },
                          [
                            _c("v-radio", {
                              staticStyle: { width: "150px" },
                              attrs: { value: "immediate" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "#000000" } },
                                          [_vm._v("Imediato")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1163317323
                              ),
                            }),
                            _c("v-radio", {
                              attrs: { value: "date" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "mr-5",
                                            staticStyle: { color: "#000000" },
                                          },
                                          [_vm._v("Agendamento ")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1725176911
                              ),
                            }),
                          ],
                          1
                        ),
                        _vm.startType == "date"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "v-row",
                                  { attrs: { dense: "" } },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          sm: "6",
                                          md: "6",
                                          lg: "6",
                                        },
                                      },
                                      [
                                        _c("z-input-date", {
                                          staticClass: "mr-1",
                                          attrs: {
                                            rules: [
                                              (v) => !!v || "Campo obrigatório",
                                            ],
                                            label: "",
                                            small: "",
                                            format: "pt-BR",
                                            type: "date",
                                            min: _vm.minimumStartDate,
                                            hideDetails: true,
                                            disabled: _vm.startType != "date",
                                          },
                                          on: {
                                            input: function ($event) {
                                              _vm.isSchedulingOk = true
                                            },
                                          },
                                          model: {
                                            value: _vm.punctualTimerType,
                                            callback: function ($$v) {
                                              _vm.punctualTimerType = $$v
                                            },
                                            expression: "punctualTimerType",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          sm: "3",
                                          md: "3",
                                          lg: "3",
                                        },
                                      },
                                      [
                                        _c("z-select", {
                                          attrs: {
                                            small: "",
                                            items: _vm.actualSelectedDateIsToday
                                              ? _vm.avaliableHours
                                              : _vm.hoursArr,
                                            label: "Hora",
                                            hideDetails: true,
                                            hint: "asdfasdf",
                                            readonly: !_vm.punctualTimerType,
                                          },
                                          model: {
                                            value: _vm.hourInfo,
                                            callback: function ($$v) {
                                              _vm.hourInfo = $$v
                                            },
                                            expression: "hourInfo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12",
                                          sm: "3",
                                          md: "3",
                                          lg: "3",
                                        },
                                      },
                                      [
                                        _c("z-select", {
                                          attrs: {
                                            small: "",
                                            items: _vm.actualSelectedDateIsToday
                                              ? _vm.avaliableMinutes
                                              : _vm.minutesArr,
                                            label: "Minuto",
                                            hideDetails: true,
                                            readonly: !_vm.punctualTimerType,
                                          },
                                          model: {
                                            value: _vm.minuteInfo,
                                            callback: function ($$v) {
                                              _vm.minuteInfo = $$v
                                            },
                                            expression: "minuteInfo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm.actualSelectedDateIsToday &&
                                    _vm.hourInfo &&
                                    _vm.minuteInfo
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass: "mt-3",
                                            attrs: { cols: "12" },
                                          },
                                          [
                                            _c(
                                              "small",
                                              { staticClass: "d-flex" },
                                              [
                                                _vm._v(
                                                  "*Agendado previamente para: "
                                                ),
                                                _c(
                                                  "strong",
                                                  { staticClass: "ml-2" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatDate(
                                                          _vm.punctualTimerType
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "strong",
                                                  { staticClass: "ml-2" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.hourInfo) +
                                                        ":" +
                                                        _vm._s(_vm.minuteInfo)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.timerType == "recurring"
                  ? _c("section", { staticClass: "content__recurring-info" }, [
                      _c(
                        "div",
                        { staticClass: "start mb-5" },
                        [
                          _c("strong", { staticClass: "mb-0" }, [
                            _vm._v(" Início"),
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              attrs: {
                                inline: "",
                                rules: [(v) => !!v || "Campo obrigatório"],
                                "hide-details": "",
                              },
                              on: {
                                change: function ($event) {
                                  _vm.startType != "date"
                                    ? (_vm.punctualTimerType = "")
                                    : ""
                                },
                              },
                              model: {
                                value: _vm.startType,
                                callback: function ($$v) {
                                  _vm.startType = $$v
                                },
                                expression: "startType",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-radio", {
                                    staticStyle: {
                                      width: "150px",
                                      "margin-top": "10px",
                                    },
                                    attrs: { value: "immediate" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#000000",
                                                  },
                                                },
                                                [_vm._v("Imediato")]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1163317323
                                    ),
                                  }),
                                  _c("v-radio", {
                                    attrs: { value: "date" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "9" } },
                                                [
                                                  _c("z-input-date", {
                                                    staticClass: "mr-1",
                                                    attrs: {
                                                      rules:
                                                        _vm.startType === "date"
                                                          ? [
                                                              (v) =>
                                                                !!v ||
                                                                "Campo obrigatório",
                                                            ]
                                                          : [],
                                                      label: "",
                                                      format: "pt-BR",
                                                      type: "date",
                                                      hideDetails: true,
                                                      disabled:
                                                        _vm.startType != "date",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.punctualTimerType,
                                                      callback: function ($$v) {
                                                        _vm.punctualTimerType =
                                                          $$v
                                                      },
                                                      expression:
                                                        "punctualTimerType",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      3562059987
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.startType == "date"
                            ? _c(
                                "div",
                                [_c("v-row", { attrs: { dense: "" } })],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "end mb-5" },
                        [
                          _c("strong", { staticClass: "mb-0" }, [
                            _vm._v(" Fim "),
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              attrs: {
                                inline: "",
                                "hide-details": "",
                                rules: [(v) => !!v || "Campo obrigatório"],
                              },
                              on: {
                                change: function ($event) {
                                  _vm.punctualTimerTypeEnd = ""
                                },
                              },
                              model: {
                                value: _vm.endType,
                                callback: function ($$v) {
                                  _vm.endType = $$v
                                },
                                expression: "endType",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-radio", {
                                    staticStyle: {
                                      width: "150px",
                                      "margin-top": "10px",
                                    },
                                    attrs: { value: "undefined" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#000000",
                                                  },
                                                },
                                                [_vm._v("Indefinido ")]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      3296433528
                                    ),
                                  }),
                                  _c("v-radio", {
                                    attrs: { value: "date" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "9" } },
                                                [
                                                  _c("z-input-date", {
                                                    attrs: {
                                                      rules:
                                                        _vm.endType === "date"
                                                          ? [
                                                              (v) =>
                                                                !!v ||
                                                                "Campo obrigatório",
                                                            ]
                                                          : [],
                                                      min: _vm.minimumDate,
                                                      label: "",
                                                      format: "pt-BR",
                                                      hideDetails: true,
                                                      disabled:
                                                        _vm.endType != "date",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.punctualTimerTypeEnd,
                                                      callback: function ($$v) {
                                                        _vm.punctualTimerTypeEnd =
                                                          $$v
                                                      },
                                                      expression:
                                                        "punctualTimerTypeEnd",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1594993851
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "repeat-each mb-0" }, [
                        _c("strong", { staticClass: "mb-2" }, [
                          _vm._v(" Repetir a cada: "),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "repeat-each mb-5" },
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    sm: "12",
                                    md: "6",
                                    lg: "3",
                                  },
                                },
                                [
                                  _c("z-input", {
                                    ref: "inptRef",
                                    staticClass: "custom_inpt",
                                    attrs: {
                                      rules: [
                                        (v) => !!v || "Campo obrigatório",
                                      ],
                                      itemText: "text",
                                      itemValue: "value",
                                      type: "number",
                                      "hide-details": true,
                                    },
                                    model: {
                                      value: _vm.every,
                                      callback: function ($$v) {
                                        _vm.every = $$v
                                      },
                                      expression: "every",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    sm: "12",
                                    md: "6",
                                    lg: "4",
                                  },
                                },
                                [
                                  _c("z-select", {
                                    ref: "selectRef",
                                    attrs: {
                                      rules: [
                                        (v) => !!v || "Campo obrigatório",
                                      ],
                                      items: _vm.repeatItems,
                                      itemText: "text",
                                      itemValue: "value",
                                      "hide-details": true,
                                      "value-position": "center",
                                    },
                                    model: {
                                      value: _vm.repeatItem,
                                      callback: function ($$v) {
                                        _vm.repeatItem = $$v
                                      },
                                      expression: "repeatItem",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.repeatItem == "weekly" || _vm.repeatItem == "monthly"
                        ? _c(
                            "div",
                            { staticClass: "repeat mb-5" },
                            [
                              _c("strong", { staticClass: "mb-2" }, [
                                _vm._v(" Repetir: "),
                              ]),
                              _vm.repeatItem == "weekly"
                                ? _c(
                                    "v-btn-toggle",
                                    {
                                      attrs: {
                                        multiple: "",
                                        dense: "",
                                        color: "primary",
                                        rules: [
                                          (v) => !!v || "Campo obrigatório",
                                        ],
                                      },
                                      model: {
                                        value: _vm.toggle_week,
                                        callback: function ($$v) {
                                          _vm.toggle_week = $$v
                                        },
                                        expression: "toggle_week",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-1",
                                          attrs: {
                                            fab: "",
                                            rounded: "",
                                            depressed: "",
                                            "x-small": "",
                                          },
                                        },
                                        [_vm._v(" D ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-1",
                                          attrs: {
                                            fab: "",
                                            rounded: "",
                                            depressed: "",
                                            "x-small": "",
                                          },
                                        },
                                        [_vm._v(" S ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-1",
                                          attrs: {
                                            fab: "",
                                            rounded: "",
                                            depressed: "",
                                            "x-small": "",
                                          },
                                        },
                                        [_vm._v(" T ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-1",
                                          attrs: {
                                            fab: "",
                                            rounded: "",
                                            depressed: "",
                                            "x-small": "",
                                          },
                                        },
                                        [_vm._v(" Q ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-1",
                                          attrs: {
                                            fab: "",
                                            rounded: "",
                                            depressed: "",
                                            "x-small": "",
                                          },
                                        },
                                        [_vm._v(" Q ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-1",
                                          attrs: {
                                            fab: "",
                                            rounded: "",
                                            depressed: "",
                                            "x-small": "",
                                          },
                                        },
                                        [_vm._v(" S ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-1",
                                          attrs: {
                                            fab: "",
                                            rounded: "",
                                            depressed: "",
                                            "x-small": "",
                                          },
                                        },
                                        [_vm._v(" S ")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.repeatItem == "monthly"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { justify: "space-between" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "5" } },
                                            [
                                              _vm.repeatItem == "monthly"
                                                ? _c("z-input", {
                                                    ref: "inptRef",
                                                    staticClass:
                                                      "custom_inpt mt-4",
                                                    attrs: {
                                                      rules: [
                                                        (v) =>
                                                          !!v ||
                                                          "Campo obrigatório",
                                                      ],
                                                      itemText: "text",
                                                      itemValue: "value",
                                                      label: "Dia",
                                                      max: "31",
                                                      min: "1",
                                                      type: "number",
                                                      "hide-details": true,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.toggle_month_choose,
                                                      callback: function ($$v) {
                                                        _vm.toggle_month_choose =
                                                          $$v
                                                      },
                                                      expression:
                                                        "toggle_month_choose",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.repeatItem !== "minutely" &&
                      _vm.repeatItem !== "hourly"
                        ? _c(
                            "div",
                            { staticClass: "execution mb-1" },
                            [
                              _c("strong", { staticClass: "mb-2" }, [
                                _vm._v(" Horário de execução "),
                              ]),
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "12",
                                        sm: "6",
                                        md: "6",
                                        lg: "3",
                                      },
                                    },
                                    [
                                      _c("z-select", {
                                        attrs: {
                                          items: _vm.hoursArr,
                                          label: "Hora",
                                          hideDetails: "",
                                        },
                                        model: {
                                          value: _vm.hourInfo,
                                          callback: function ($$v) {
                                            _vm.hourInfo = $$v
                                          },
                                          expression: "hourInfo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "12",
                                        sm: "6",
                                        md: "6",
                                        lg: "3",
                                      },
                                    },
                                    [
                                      _c("z-select", {
                                        attrs: {
                                          items: _vm.minutesArr,
                                          label: "Minuto",
                                          hideDetails: "",
                                        },
                                        model: {
                                          value: _vm.minuteInfo,
                                          callback: function ($$v) {
                                            _vm.minuteInfo = $$v
                                          },
                                          expression: "minuteInfo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "content__timer-type mt-5" },
                        [
                          _c("strong", { staticClass: "mb-2" }, [
                            _vm._v(" Quando um usuário já estiver na jornada "),
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              attrs: {
                                inline: "",
                                "hide-details": "",
                                rules: [(v) => !!v || "Campo obrigatório"],
                              },
                              model: {
                                value: _vm.newUserJourneyEntryBehavior,
                                callback: function ($$v) {
                                  _vm.newUserJourneyEntryBehavior = $$v
                                },
                                expression: "newUserJourneyEntryBehavior",
                              },
                            },
                            [
                              _c("v-radio", {
                                staticStyle: { "margin-top": "10px" },
                                attrs: { value: "createNew" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: { color: "#000000" },
                                            },
                                            [_vm._v("Criar novamente")]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  4039041847
                                ),
                              }),
                              _c("v-radio", {
                                staticStyle: { "margin-top": "10px" },
                                attrs: { value: "noChange" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: { color: "#000000" },
                                            },
                                            [
                                              _vm._v(
                                                "Não criar um novo e não alterar o existente"
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  348081320
                                ),
                              }),
                              _c("v-radio", {
                                staticStyle: { "margin-top": "10px" },
                                attrs: { value: "backToBeginning" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: { color: "#000000" },
                                            },
                                            [
                                              _vm._v(
                                                "Não criar um novo e mover o existente para o início"
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  22252182
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "content__timer-type mt-5" },
                  [
                    _c("strong", { staticClass: "mb-2" }, [
                      _vm._v(" Ao aguardar: "),
                    ]),
                    _c(
                      "v-radio-group",
                      {
                        attrs: {
                          inline: "",
                          "hide-details": "",
                          rules: [(v) => !!v || "Campo obrigatório"],
                        },
                        model: {
                          value: _vm.userWaitBehaviour,
                          callback: function ($$v) {
                            _vm.userWaitBehaviour = $$v
                          },
                          expression: "userWaitBehaviour",
                        },
                      },
                      [
                        _c("v-radio", {
                          staticStyle: { "margin-top": "10px" },
                          attrs: { value: "noChange" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#000000" } },
                                      [_vm._v("Manter dados do inicio")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2212825569
                          ),
                        }),
                        _c("v-radio", {
                          staticStyle: { "margin-top": "10px" },
                          attrs: { value: "updateData" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#000000" } },
                                      [_vm._v("Atualizar dados")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            4233115277
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "content__timer-type mt-5" }, [
                  _c("strong", { staticClass: "mb-2" }, [
                    _vm._v(" Configuração de envio em duplicidade "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "repeat-each mb-3" },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                sm: "12",
                                md: "12",
                                lg: "12",
                              },
                            },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "", "max-width": "700" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "span",
                                              _vm._g(
                                                _vm._b(
                                                  {},
                                                  "span",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "z-checkbox",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          label:
                                                            "Não permitir o envio para o mesmo número e e-mail na mesma jornada",
                                                          "hide-details": "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.shouldSentMensageriaToExistentContact,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.shouldSentMensageriaToExistentContact =
                                                              $$v
                                                          },
                                                          expression:
                                                            "shouldSentMensageriaToExistentContact",
                                                        },
                                                      },
                                                      "z-checkbox",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  )
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1299914593
                                  ),
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        padding: "1rem",
                                        "font-weight": "500",
                                        display: "block",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " Essa opcao impede que sejam enviados dois emails/sms para usuarios que por acaso tenham o mesmo email ou telefone cadastrado na base, caso a sua base nao tenha emails/sms duplicados, isso nao e necessario e afetaria a velocidade desnecessariamente "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm.shouldSentMensageriaToExistentContact
                                ? _c(
                                    "small",
                                    { staticStyle: { color: "red" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          "A opção de envio em duplicidade pode afetar a velocidade no envio das mensagens"
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "content__timer-type mt-5" }, [
                  _c("strong", { staticClass: "mb-2" }, [
                    _vm._v(" Configuração de optout "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "repeat-each mb-3" },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                sm: "12",
                                md: "12",
                                lg: "12",
                              },
                            },
                            [
                              _c("z-checkbox", {
                                attrs: {
                                  label: "Ignorar optout",
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.ignoreOptout,
                                  callback: function ($$v) {
                                    _vm.ignoreOptout = $$v
                                  },
                                  expression: "ignoreOptout",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "content__timer-type mt-5" }, [
                  _c("strong", { staticClass: "mb-2" }, [
                    _vm._v(" Ignorar usuarios nas seguintes jornadas:"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "repeat-each mb-3" },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                sm: "12",
                                md: "12",
                                lg: "12",
                              },
                            },
                            [
                              _c("z-autocomplete", {
                                attrs: {
                                  items: _vm.journeys,
                                  itemText: "name",
                                  itemValue: "id",
                                  multiple: "",
                                  placeholder: "",
                                  type: "text",
                                  hideDetails: true,
                                },
                                model: {
                                  value: _vm.ignoreJourneys,
                                  callback: function ($$v) {
                                    _vm.ignoreJourneys = $$v
                                  },
                                  expression: "ignoreJourneys",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "content__timer-type mt-5" }, [
                  _c("strong", { staticClass: "mb-2" }, [
                    _vm._v(" Emails para notificar na finalização:"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "repeat-each mb-3" },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                sm: "12",
                                md: "12",
                                lg: "12",
                              },
                            },
                            [
                              _c("z-input", {
                                attrs: { "hide-details": true },
                                model: {
                                  value: _vm.finishNotificationEmails,
                                  callback: function ($$v) {
                                    _vm.finishNotificationEmails = $$v
                                  },
                                  expression: "finishNotificationEmails",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      !_vm.loading
        ? _c(
            "div",
            { staticClass: "modal--actions mt-3" },
            [
              _c("z-btn", {
                attrs: { small: "", text: "Cancelar" },
                on: { click: _vm.close },
              }),
              _c("z-btn", {
                attrs: { small: "", primary: "", text: "Concluir" },
                on: { click: _vm.confirm },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.loading
        ? _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [_c("z-loading")],
            1
          )
        : _vm._e(),
      _c("z-card-modal", {
        attrs: { title: "Importante ⚠", persistent: "" },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("div", { staticClass: "mt-3" }, [
                  _vm._v(
                    " O Agendamento é muito distante, você tem certeza que deseja prosseguir? "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "modal--actions mt-4 mb-3" },
                  [
                    _c("z-btn", {
                      attrs: { small: "", text: "Cancelar" },
                      on: {
                        click: function ($event) {
                          _vm.confirmationModal = false
                        },
                      },
                    }),
                    _c("z-btn", {
                      attrs: { small: "", primary: "", text: "Confirmar" },
                      on: {
                        click: function ($event) {
                          _vm.confirmationModal = false
                          _vm.isSchedulingOk = false
                          _vm.confirm()
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.confirmationModal,
          callback: function ($$v) {
            _vm.confirmationModal = $$v
          },
          expression: "confirmationModal",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal__header__title" }, [
      _c("span", [_vm._v("Configuração de jornada ")]),
      _c("br"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }