import { findNodeById, generateUUID, buildCronStringV2 } from "./utils";
export default {
  computed: {
    messageriaStepsItems() {
      return this.data.nodes.filter(
        (x) => x.type.includes("sendEmail") || x.type.includes("sendSMS")
      );
    },
    isDisabledUndo() {
      const HAS_VALUE =
        this.journeyStepsCount.length > 0 && this.currentJourneyStepIndex > 0;
      return HAS_VALUE;
    },
    isDisabledRedo() {
      const HAS_VALUE =
        this.journeyStepsCount.length > 0 &&
        this.currentJourneyStepIndex < this.journeyStepsCount.length - 1;

      return HAS_VALUE;
    },
  },
  created() {
    window.addEventListener("beforeunload", this.beforeWindowUnload);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
  methods: {
    // --------------- MANIPULAÇÃO DE INFORMAÇÃO PARA REDO E UNDO -----------------------------

    insertCurrentStepOnChange() {
      const _ = require("lodash");

      let EL = {};
      EL.data = _.cloneDeep(this.treeTest);
      EL.nodes = _.cloneDeep(this.data.nodes);

      // preciso verificar se ao inserir, qual meu index atual
      // para assim, saber se estou fazer uma nova alteração, a partir de um voltar
      if (this.currentJourneyStepIndex < this.journeyStepsCount.length - 1) {
        // preciso apagar o array de informações, a partir do index atual e assim, inserir a nova informação
        this.journeyStepsCount.splice(
          this.currentJourneyStepIndex + 1,
          this.journeyStepsCount.length
        );
      }

      this.journeyStepsCount.push(EL);
      this.currentJourneyStepIndex = this.journeyStepsCount.length - 1;
    },

    moveStep(shouldGo) {
      const _ = require("lodash");

      if (!shouldGo) {
        if (
          this.journeyStepsCount.length > 0 &&
          this.currentJourneyStepIndex > 0
        ) {
          this.currentJourneyStepIndex -= 1;

          this.treeTest = _.cloneDeep(
            this.journeyStepsCount[this.currentJourneyStepIndex]?.data
          );
          this.tree = _.cloneDeep(
            this.journeyStepsCount[this.currentJourneyStepIndex]?.data
          );
          this.data.nodes = _.cloneDeep(
            this.journeyStepsCount[this.currentJourneyStepIndex]?.nodes
          );
          this.keyComponent++;
        }
      }

      if (shouldGo) {
        if (
          this.journeyStepsCount.length &&
          this.currentJourneyStepIndex < this.journeyStepsCount.length - 1
        ) {
          this.currentJourneyStepIndex += 1;
          this.treeTest = _.cloneDeep(
            this.journeyStepsCount[this.currentJourneyStepIndex]?.data
          );
          this.tree = _.cloneDeep(
            this.journeyStepsCount[this.currentJourneyStepIndex]?.data
          );
          this.data.nodes = _.cloneDeep(
            this.journeyStepsCount[this.currentJourneyStepIndex]?.nodes
          );
          this.keyComponent++;
        }
      }
    },

    // --------------- FIM MANIPULAÇÃO DE INFORMAÇÃO PARA REDO E UNDO -----------------------------
    beforeWindowUnload(e) {
      if (this.isSaved) {
        e.preventDefault();
        e.returnValue = "";
      }
    },

    async startJourneyClickItem(data) {
      // eslint-disable-next-line no-unused-vars
      let node = {};

      this.startJourneyDialog = false;

      const nodeFound = this.data.nodes.find(
        (x) => x.id === this.insertIndexPosition
      );

      if (data.name === "Audiência") {
        node = await this.createAudienceNode();
      } else if (data.name === "Enviar e-mail") {
        node = await this.createSendEmailNode(nodeFound, data);
      } else if (data.name == "Enviar SMS") {
        node = await this.createSendSmsNode(nodeFound, data);
      } else if (data.name === "Aguardar") {
        node = await this.createAwaitNode(nodeFound, data);
      } else if (data.name === "Audiência filtrada") {
        node = await this.createFilteredAudienceNode(nodeFound);
      } else if (data.name === "Teste A/B") {
        node = this.createTestABFilterNode(nodeFound);
      } else if (data.name === "Divisão por interação") {
        node = this.createDivisionByInteractionNode(nodeFound);
      } else if (data.name === "Unir step") {
        if (data.create) {
          let newInfo = { name: data.nameToTjoin, create: true };
          this.startJourneyClickItem(newInfo);
        } else {
          this.chooseStepToCreateDialog = true;
        }
      } else if (data.name === "Disparo via push") {
        node = this.createSendByPushNode(nodeFound, data);
      } else if (data.name === "Facebook") {
        node = this.createFacebookNode(nodeFound, data);
      } else if (data.name === "Google") {
        node = this.createGoogleNode(nodeFound, data);
      } else if (data.name === "Google") {
        node = this.createGoogleNode(nodeFound, data);
      } else if (data.name == "Tags")
        node = this.createTagNode(nodeFound, data);

      this.organizeTree();

      return;
    },

    newAudience(filterType = "audience") {
      const _ = require("lodash");
      let AUDIENCE_INFORMATION = {};
      AUDIENCE_INFORMATION = {
        journey: { ...this.journey },
        steps: { ...this.tree },
        route: { ...this.$route },
        nodes: this.data.nodes,
        nodeClickId: this.clickedNodeId,
        stepsCount: _.cloneDeep(this.journeyStepsCount),
        stepsIndex: this.currentJourneyStepIndex,
        currentDatabaseConfigId: this.databaseConfigId,
        filterType,
      };
      this.$store.dispatch(
        "app/SET_CURRENT_AUDIENCE_INFO",
        AUDIENCE_INFORMATION
      );

      this.$router.push({ name: "addAudiencePage" });
    },

    editSelectedAudience(audience) {
      const _ = require("lodash");

      // ALTERA PARA TRUE, PARA PODER PASSAR PELO MIDDLEWARE DO BEFOREROUTELEAVING
      this.isEditingSomeAudience = true;

      let AUDIENCE_INFORMATION = {};
      AUDIENCE_INFORMATION = {
        journey: { ...this.journey },
        steps: { ...this.tree },
        route: { ...this.$route },
        nodes: this.data.nodes,
        audience,
        nodeClickId: this.clickedNodeId,
        stepsCount: _.cloneDeep(this.journeyStepsCount),
        stepsIndex: this.currentJourneyStepIndex,
      };

      this.$store.dispatch(
        "app/SET_CURRENT_AUDIENCE_INFO",
        AUDIENCE_INFORMATION
      );

      this.$router.push({
        name: "editAudiencePage",
        params: { type: "audience", id: audience.id },
      });
    },

    invalidateJourneySteps(objeto, id) {
      if (objeto.id === id) {
        return (objeto.shouldValidate = false);
      }

      if (objeto.nextNode) {
        this.invalidateJourneySteps(objeto.nextNode, id);
      }

      if (objeto.YesNext) {
        this.invalidateJourneySteps(objeto.YesNext, id);
      }

      const sub = { ...this.treeTest };
      this.treeTest = null;
      this.treeTest = { ...sub };
      this.render = false;
      this.render = true;
      this.$forceUpdate();
      return "invalidated";
    },

    showMessageForValidation() {
      if (!this.journeyValidation.steps) {
        if (this.treeTest == {}) {
          this.$toast.error("Adicione pelo menos 3 steps a sua jornada.");
        } else if (!this.journey.audienceId) {
          this.$toast.error("Selecione a audiência da sua jornada.");
        } else if (this.arrValidationSteps.length > 0) {
          this.$toast.error(
            `Há ${this.arrValidationSteps.length} ${
              this.arrValidationSteps.length > 1
                ? "steps que necessitam"
                : "step que necessita"
            }  de atenção`
          );
        } else {
          this.journeyValidation.steps = true;
          this.showMessageForValidation();
        }
      } else if (!this.journeyValidation.recurrency) {
        this.$toast.error(
          "Preencha os campos de periodicidade da sua jornada."
        );
      } else if (!this.journey.name) {
        this.$toast.error("Preencha o nome da jornada.");
      } else if (!this.journeyValidation.templateSteps) {
        this.$toast.error("Adicione pelo menos um template de mensageria.");
      }
    },

    validateJourneySteps(objeto, showMessage = true) {
      this.doValidateJourneySteps(objeto);

      const passed_all_criteria =
        this.arrValidationSteps.length === 0 &&
        this.journey.name &&
        this.journey.audienceId &&
        this.tree &&
        this.recurrencyConfiguration &&
        this.journeyValidation.templateSteps &&
        JSON.stringify(this.recurrencyConfiguration) !== "{}";

      if (!passed_all_criteria && showMessage) this.showMessageForValidation();

      if (passed_all_criteria && showMessage)
        this.$toast.success("Jornada validada com sucesso");
    },
    // VALIDAÇÃO DE STEP
    insertStepInValidationArr(objeto) {
      const not_founded =
        this.arrValidationSteps.findIndex((x) => x.id === objeto.id) === -1;

      if (not_founded) {
        this.arrValidationSteps.push(objeto);
        objeto.shouldValidate = true;
      }

      return;
    },

    removeStepFromValidationArr(id) {
      const index = this.arrValidationSteps.findIndex((x) => x.id === id);
      this.arrValidationSteps.splice(index, 1);
    },

    async doValidateJourneySteps(objeto) {
      if (!objeto.nextNode && !objeto.YesNext) {
        return;
      }

      switch (objeto.type) {
        case "sendWhatsApp":
          if (!objeto.templateId) await this.insertStepInValidationArr(objeto);
          this.journeyValidation.templateSteps = true;
          break;
        case "sendFacebook":
          if (!objeto.collectionId)
            await this.insertStepInValidationArr(objeto);
          this.journeyValidation.templateSteps = true;
          break;
        case "sendGoogle":
          if (!objeto.collectionId)
            await this.insertStepInValidationArr(objeto);
          this.journeyValidation.templateSteps = true;
          break;
        case "sendSMS":
          if (!objeto.templateId) await this.insertStepInValidationArr(objeto);
          this.journeyValidation.templateSteps = true;
          break;
        case "sendPush":
          if (!objeto.templateId) await this.insertStepInValidationArr(objeto);
          this.journeyValidation.templateSteps = true;
          break;
        case "tagUser":
          if (!objeto.tags) await this.insertStepInValidationArr(objeto);
          this.journeyValidation.templateSteps = true;
          break;
        case "sendEmail":
          if (!objeto.templateId) await this.insertStepInValidationArr(objeto);
          this.journeyValidation.templateSteps = true;
          break;
        case "await":
          if (!objeto.wait || !objeto.waitValue)
            await this.insertStepInValidationArr(objeto);
          break;
        case "selectAudienceFilter":
          if (!objeto.filterId) await this.insertStepInValidationArr(objeto);
          break;
        case "selectTestABFilter":
          if (!objeto.percentageA || !objeto.percentageB)
            await this.insertStepInValidationArr(objeto);
          break;
        case "mensageriaCheck":
          if (!objeto.mensageriaStepCheckId)
            await this.insertStepInValidationArr(objeto);
          break;
        default:
          break;
      }

      if (objeto.nextNode) {
        this.doValidateJourneySteps(objeto.nextNode);
      }

      if (objeto.YesNext) {
        this.doValidateJourneySteps(objeto.YesNext);
      }
      const sub = { ...this.treeTest };
      this.treeTest = null;
      this.treeTest = { ...sub };
      this.render = false;
      this.render = true;
      this.$forceUpdate();

      return "validated";
    },
    // FIM VALIDAÇÃO DE STEP

    changeSizeModal(size) {
      this.templateModalWidth = size;
    },

    justInsertSomeInfo(objeto, id, info, camp) {
      if (objeto.id === id) {
        objeto[camp] = info;
        return objeto;
      }
      if (objeto.nextNode) {
        // Chama recursivamente a função para o próximo nó
        const indiceProximoNodo = this.justInsertSomeInfo(
          objeto.nextNode,
          id,
          info,
          camp
        );
        if (indiceProximoNodo !== -1) {
          return indiceProximoNodo; // Retorna o índice encontrado
        }
      }

      // Verifica se o objeto tem uma propriedade "YesNext"
      if (objeto.YesNext) {
        // Chama recursivamente a função para o nó "YesNext"
        const indiceYesNext = this.justInsertSomeInfo(
          objeto.YesNext,
          id,
          info,
          camp
        );
        if (indiceYesNext !== -1) {
          return indiceYesNext; // Retorna o índice encontrado
        }
      }

      return -1;
    },

    organizeTree() {
      this.treeTest = null;
      this.treeGrid = [];
      this.treeTest = this.tree;

      this.isToUpdateDiamond = true;
      this.keyComponent++;
      setTimeout(() => {
        this.isToUpdateDiamond = false;
      }, 300);

      this.insertCurrentStepOnChange();
    },

    async insertIntoArray(index, obj) {
      // Verificar se o índice existe no array
      if (index < this.treeGrid.length) {
        // atualizar o objeto, para mandar a posição em que ele vai ficar no array

        const i = this.treeGrid[index].length + 1;

        const o = await this.justInsertSomeInfo(
          this.tree,
          obj.id,
          i,
          "nodeListIndexSelf"
        );

        // Inserir o objeto no array na posição do índice
        this.treeGrid[index].push(o);
      } else {
        // Preencher os índices intermediários, se necessário
        while (index > this.treeGrid.length) {
          this.treeGrid.push([]);
        }

        // atualizar o objeto, para mandar a posição em que ele vai ficar no array (sempre vai ser 0)
        const o = await this.justInsertSomeInfo(
          this.tree,
          obj.id,
          0,
          "nodeListIndexSelf"
        );

        // Criar o índice como um array com apenas o novo objeto dentro
        this.treeGrid.push([o]);
      }
    },

    // --------------- MANIPULAÇÃO DE INFORMAÇÃO NO NÓ -----------------------------
    createDefaultNode() {
      const node = {
        id: generateUUID(),
        label: "",
        stepLabel: "",
        type: "",
        wasRenamed: false,
        selectedContentId: "",
        isDataSelected: false,
        formatType: "circle",
        hasNextNode: true,
        color: "#9747ff",
        icon: "mdi-calendar",
        isNew: true,
        lastNode: null,
        nextNode: null,
        lastJoinNode: null,
        nextJoinNode: null,
        nodeListIndex: null,
        nodeListIndexSelf: null,
        nodeListIndexLength: null,
      };

      return node;
    },

    generateFinalNodes() {
      return {
        id: generateUUID(),
        label: "Final",
        stepLabel: "",
        type: "final",
        formatType: "circle",
        hasNextNode: false,
        wasRenamed: false,
        color: "#2bae5c",
        icon: "$exit_to_app_rounded",
        isDataSelected: false,
        nextNode: null,
        lastNode: null,
        isNew: true,
        lastJoinNode: null,
        nextJoinNode: null,
        fromClick: "next",
      };
    },

    async createAudienceNode() {
      let node = this.createDefaultNode();
      node.label = "Selecionar Audiência";
      node.type = "selectAudience";

      this.pushNewNode(0, node, false);
      this.insertNode(this.tree, node, null, null);

      let final_node = this.generateFinalNodes();
      final_node.lastNode = node.id;

      this.insertNode(this.tree, final_node, node.id, "next");
      this.pushNewNode(this.data.nodes.length - 1, final_node, false);

      return node;
    },

    async createSendEmailNode(nodeFound, data) {
      let node = this.createDefaultNode();
      node.label = "Selecionar\ne-mail";
      node.type = "sendEmail";
      node.formatType = "square";
      node.icon = "mdi-email";
      node.color = "#0b99ff";
      node.lastNode = this.insertIndexPosition;
      node.fromClick = this.newNodeFromNextOrYesClick;

      if (
        nodeFound.formatType !== "diamond" &&
        nodeFound.fromClick &&
        nodeFound.fromClick === "yes" &&
        nodeFound.fromClick !== this.newNodeFromNextOrYesClick
      ) {
        this.newNodeFromNextOrYesClick = nodeFound.fromClick;
        node.fromClick = this.newNodeFromNextOrYesClick;
      }

      this.insertNode(
        this.tree,
        node,
        this.insertIndexPosition,
        this.newNodeFromNextOrYesClick
      );
      this.pushNewNode(0, node, true);
      if (data.create) this.setNodeToBeMerged(node);

      return node;
    },

    async createSendSmsNode(nodeFound, data) {
      let node = this.createDefaultNode();
      node.label = "Enviar SMS";
      node.type = "sendSMS";
      node.formatType = "square";
      node.icon = "mdi-message-processing";
      node.color = "#0b99ff";
      node.lastNode = this.insertIndexPosition;
      node.fromClick = this.newNodeFromNextOrYesClick;

      if (
        nodeFound.formatType !== "diamond" &&
        nodeFound.fromClick &&
        nodeFound.fromClick === "yes" &&
        nodeFound.fromClick !== this.newNodeFromNextOrYesClick &&
        this.newNodeFromNextOrYesClick === "next"
      ) {
        this.newNodeFromNextOrYesClick = nodeFound.fromClick;
        node.fromClick = this.newNodeFromNextOrYesClick;
      }

      this.insertNode(
        this.tree,
        node,
        this.insertIndexPosition,
        this.newNodeFromNextOrYesClick
      );
      this.pushNewNode(0, node, true);

      if (data.create) this.setNodeToBeMerged(node);

      return node;
    },

    async createAwaitNode(nodeFound, data) {
      let node = this.createDefaultNode();
      node.label = "Selecionar período";
      node.type = "await";
      node.icon = "$timer_rounded";
      node.formatType = "circle";
      node.lastNode = this.insertIndexPosition;
      node.fromClick = this.newNodeFromNextOrYesClick;

      if (
        nodeFound.formatType !== "diamond" &&
        nodeFound.fromClick &&
        nodeFound.fromClick === "yes" &&
        nodeFound.fromClick !== this.newNodeFromNextOrYesClick &&
        this.newNodeFromNextOrYesClick === "next"
      ) {
        this.newNodeFromNextOrYesClick = nodeFound.fromClick;
        node.fromClick = this.newNodeFromNextOrYesClick;
      }

      this.insertNode(
        this.tree,
        node,
        this.insertIndexPosition,
        this.newNodeFromNextOrYesClick
      );
      this.pushNewNode(0, node, true);

      if (data.create) this.setNodeToBeMerged(node);

      return node;
    },

    async createFilteredAudienceNode(nodeFound) {
      let changeYesNode = false;

      let node = this.createDefaultNode();
      node.label = "Selecionar audiência filtrada";
      node.type = "selectAudienceFilter";
      node.formatType = "diamond";
      node.color = "#F24822";
      node.lastNode = this.insertIndexPosition;
      node.fromClick = this.newNodeFromNextOrYesClick;

      if (
        nodeFound.formatType !== "diamond" &&
        nodeFound.fromClick &&
        nodeFound.fromClick === "yes" &&
        nodeFound.fromClick !== this.newNodeFromNextOrYesClick &&
        this.newNodeFromNextOrYesClick === "next"
      ) {
        this.newNodeFromNextOrYesClick = nodeFound.fromClick;
        node.fromClick = this.newNodeFromNextOrYesClick;
        changeYesNode = true;
      }

      // check do T depois do T no fluxo do SIM
      if (
        nodeFound.formatType === "diamond" &&
        this.newNodeFromNextOrYesClick === "yes"
      ) {
        // if(nodeYes) {
        let indexNodeYes = this.data.nodes.findIndex(
          (x) => x.id == nodeFound.id
        );
        this.data.nodes[indexNodeYes].YesNext.fromClick = "next";
        // }
      }

      this.insertNode(
        this.tree,
        node,
        this.insertIndexPosition,
        this.newNodeFromNextOrYesClick
      );

      let new_final_node = this.generateFinalNodes();
      new_final_node.lastNode = node.id;
      new_final_node.fromClick = changeYesNode ? "next" : "yes";

      let { positionToInsertNewNode } = this.findNextNode(
        this.insertIndexPosition
      );

      this.pushNewNode(positionToInsertNewNode, node, true);
      this.pushNewNode(this.data.nodes.length - 1, new_final_node, true);

      this.insertNode(
        this.tree,
        new_final_node,
        node.id,
        changeYesNode ? "next" : "yes"
      );

      return node;
    },

    async createTestABFilterNode(nodeFound) {
      let changeYesNode = false;

      let node = this.createDefaultNode();
      node.label = "Teste A/B";
      node.type = "selectTestABFilter";
      node.formatType = "diamond";
      node.color = "#0b99ff";
      node.lastNode = this.insertIndexPosition;
      node.fromClick = this.newNodeFromNextOrYesClick;

      if (
        nodeFound.formatType !== "diamond" &&
        nodeFound.fromClick &&
        nodeFound.fromClick === "yes" &&
        nodeFound.fromClick !== this.newNodeFromNextOrYesClick &&
        this.newNodeFromNextOrYesClick === "next"
      ) {
        this.newNodeFromNextOrYesClick = nodeFound.fromClick;
        node.fromClick = this.newNodeFromNextOrYesClick;
        changeYesNode = true;
      }

      // check do T depois do T no fluxo do SIM
      // let nodeYes = this.data.nodes.find((x) => x.id === nodeFound.id && x.fromClick == 'yes');
      if (
        nodeFound.formatType === "diamond" &&
        this.newNodeFromNextOrYesClick === "yes"
      ) {
        let indexNodeYes = this.data.nodes.findIndex(
          (x) => x.id == nodeFound.id
        );
        this.data.nodes[indexNodeYes].YesNext.fromClick = "next";
      }

      this.insertNode(
        this.tree,
        node,
        this.insertIndexPosition,
        this.newNodeFromNextOrYesClick
      );

      let new_final_node = this.generateFinalNodes();
      new_final_node.lastNode = node.id;
      new_final_node.fromClick = changeYesNode ? "next" : "yes";

      let { positionToInsertNewNode } = this.findNextNode(
        this.insertIndexPosition
      );

      this.pushNewNode(positionToInsertNewNode, node, true);
      this.pushNewNode(this.data.nodes.length - 1, new_final_node, true);

      this.insertNode(
        this.tree,
        new_final_node,
        node.id,
        changeYesNode ? "next" : "yes"
      );
    },

    async createDivisionByInteractionNode(nodeFound) {
      let changeYesNode = false;

      let node = this.createDefaultNode();
      node.label = "Div. por interação";
      node.type = "mensageriaCheck";
      node.formatType = "diamond";
      node.icon = "mdi-email";
      node.lastNode = this.insertIndexPosition;
      node.fromClick = this.newNodeFromNextOrYesClick;

      if (
        nodeFound.formatType !== "diamond" &&
        nodeFound.fromClick &&
        nodeFound.fromClick === "yes" &&
        nodeFound.fromClick !== this.newNodeFromNextOrYesClick &&
        this.newNodeFromNextOrYesClick === "next"
      ) {
        this.newNodeFromNextOrYesClick = nodeFound.fromClick;
        node.fromClick = this.newNodeFromNextOrYesClick;
        changeYesNode = true;
      }

      if (
        nodeFound.formatType === "diamond" &&
        this.newNodeFromNextOrYesClick === "yes"
      ) {
        let indexNodeYes = this.data.nodes.findIndex(
          (x) => x.id == nodeFound.id
        );
        this.data.nodes[indexNodeYes].YesNext.fromClick = "next";
      }

      this.insertNode(
        this.tree,
        node,
        this.insertIndexPosition,
        this.newNodeFromNextOrYesClick
      );

      let new_final_node = this.generateFinalNodes();
      new_final_node.lastNode = node.id;
      new_final_node.fromClick = changeYesNode ? "next" : "yes";

      let { positionToInsertNewNode } = this.findNextNode(
        this.insertIndexPosition
      );

      this.pushNewNode(positionToInsertNewNode, node, true);
      this.pushNewNode(this.data.nodes.length - 1, new_final_node, true);

      this.insertNode(
        this.tree,
        new_final_node,
        node.id,
        changeYesNode ? "next" : "yes"
      );

      // caso meu nodeFound (nó de pai) não seja do tipo await, tenho que criar um nó com esse tipo, antes do meu nó de interação
      if (!nodeFound.type.includes("await")) {
        this.insertIndexPosition = nodeFound.id;
        let AWAIT_INFO = {
          name: "Aguardar",
          imgPath: "/icons/wait-icon.svg",
          isToShow: true,
        };

        this.startJourneyClickItem(AWAIT_INFO);
      }

      return node;
    },

    async createSendByPushNode(nodeFound, data) {
      let node = this.createDefaultNode();
      node.label = "Disparo via Push";
      node.type = "sendPush";
      node.formatType = "square";
      node.icon = "mdi-message-processing";
      node.lastNode = this.insertIndexPosition;
      node.fromClick = this.newNodeFromNextOrYesClick;

      if (
        nodeFound.formatType !== "diamond" &&
        nodeFound.fromClick &&
        nodeFound.fromClick === "yes" &&
        nodeFound.fromClick !== this.newNodeFromNextOrYesClick &&
        this.newNodeFromNextOrYesClick === "next"
      ) {
        this.newNodeFromNextOrYesClick = nodeFound.fromClick;
        node.fromClick = this.newNodeFromNextOrYesClick;
      }

      this.insertNode(
        this.tree,
        node,
        this.insertIndexPosition,
        this.newNodeFromNextOrYesClick
      );

      this.pushNewNode(0, node, true);

      if (data.create) this.setNodeToBeMerged(node);

      return node;
    },

    async createFacebookNode(nodeFound, data) {
      let node = this.createDefaultNode();
      node.label = "Facebook";
      node.type = "sendFacebook";
      node.formatType = "square";
      node.icon = "mdi-facebook";
      node.collectionName = "";
      node.collectionId = "";
      node.color = "#0b99ff";
      node.lastNode = this.insertIndexPosition;
      node.fromClick = this.newNodeFromNextOrYesClick;

      if (
        nodeFound.formatType !== "diamond" &&
        nodeFound.fromClick &&
        nodeFound.fromClick === "yes" &&
        nodeFound.fromClick !== this.newNodeFromNextOrYesClick &&
        this.newNodeFromNextOrYesClick === "next"
      ) {
        this.newNodeFromNextOrYesClick = nodeFound.fromClick;
        node.fromClick = this.newNodeFromNextOrYesClick;
      }

      this.insertNode(
        this.tree,
        node,
        this.insertIndexPosition,
        this.newNodeFromNextOrYesClick
      );
      this.pushNewNode(0, node, true);

      if (data.create) this.setNodeToBeMerged(node);

      return node;
    },

    async createTagNode(nodeFound, data) {
      let node = this.createDefaultNode();
      node.label = "Tag";
      node.type = "tagUser";
      node.tagName = "";
      node.formatType = "circle";
      node.icon = "mdi-tag";
      node.color = "#0b99ff";
      node.lastNode = this.insertIndexPosition;
      node.fromClick = this.newNodeFromNextOrYesClick;

      if (
        nodeFound.formatType !== "diamond" &&
        nodeFound.fromClick &&
        nodeFound.fromClick === "yes" &&
        nodeFound.fromClick !== this.newNodeFromNextOrYesClick &&
        this.newNodeFromNextOrYesClick === "next"
      ) {
        this.newNodeFromNextOrYesClick = nodeFound.fromClick;
        node.fromClick = this.newNodeFromNextOrYesClick;
      }

      this.insertNode(
        this.tree,
        node,
        this.insertIndexPosition,
        this.newNodeFromNextOrYesClick
      );
      this.pushNewNode(0, node, true);

      if (data.create) this.setNodeToBeMerged(node);

      return node;
    },

    async createGoogleNode(nodeFound, data) {
      let node = this.createDefaultNode();
      node.label = "Google";
      node.type = "sendGoogle";
      node.collectionName = "";
      node.formatType = "circle";
      node.icon = "mdi-google";
      node.color = "#0b99ff";
      node.lastNode = this.insertIndexPosition;
      node.fromClick = this.newNodeFromNextOrYesClick;

      if (
        nodeFound.formatType !== "diamond" &&
        nodeFound.fromClick &&
        nodeFound.fromClick === "yes" &&
        nodeFound.fromClick !== this.newNodeFromNextOrYesClick &&
        this.newNodeFromNextOrYesClick === "next"
      ) {
        this.newNodeFromNextOrYesClick = nodeFound.fromClick;
        node.fromClick = this.newNodeFromNextOrYesClick;
      }

      this.insertNode(
        this.tree,
        node,
        this.insertIndexPosition,
        this.newNodeFromNextOrYesClick
      );
      this.pushNewNode(0, node, true);

      if (data.create) this.setNodeToBeMerged(node);

      return node;
    },

    async createWppNode(nodeFound, data) {
      let node = this.createDefaultNode();
      node.label = "WhatsApp";
      node.type = "sendWhatsApp";
      node.collectionName = "";
      node.formatType = "circle";
      node.icon = "mdi-whatsapp";
      node.color = "#14AE5C";
      node.lastNode = this.insertIndexPosition;
      node.fromClick = this.newNodeFromNextOrYesClick;

      if (
        nodeFound.formatType !== "diamond" &&
        nodeFound.fromClick &&
        nodeFound.fromClick === "yes" &&
        nodeFound.fromClick !== this.newNodeFromNextOrYesClick &&
        this.newNodeFromNextOrYesClick === "next"
      ) {
        this.newNodeFromNextOrYesClick = nodeFound.fromClick;
        node.fromClick = this.newNodeFromNextOrYesClick;
      }

      this.insertNode(
        this.tree,
        node,
        this.insertIndexPosition,
        this.newNodeFromNextOrYesClick
      );
      this.pushNewNode(0, node, true);

      if (data.create) this.setNodeToBeMerged(node);

      return node;
    },

    async setNodeToBeMerged(node) {
      this.nodeToBeMerged = node;
      await this.joinInformations(node);
    },

    selectStepToJoin(nameToTjoin) {
      let d = { name: "Unir step", nameToTjoin, create: true };
      this.startJourneyClickItem(d);
      this.chooseStepToCreateDialog = false;
    },

    async joinInformations(node) {
      node.type = `union-${node.type}`;
      const PARENT_DIAMOND = await this.findFirstDiamondParent(this.tree, node);

      if (PARENT_DIAMOND) {
        // chamar método para alterar árvore, fazendo apontar para o nó em questão
        this.alterTreeToJoin(this.tree, PARENT_DIAMOND, node);
      }
    },

    async alterTreeToJoin(tree, parentDiamond, clickedNode) {
      // verificar de qual lado o componente foi clicado e ir para o lado oposto
      const ultimo_yes = await this.findLastFinalToRemove(
        parentDiamond,
        "YesNext"
      );

      const ultimo_next = await this.findLastFinalToRemove(
        parentDiamond,
        "nextNode"
      );

      if (clickedNode.fromClick === "yes") {
        // fazer a busca no next afim de buscar o ultimo elemento
        // se for do tipo final, ele tem que ser removido
        if (ultimo_next) {
          // chamar o nodeClickRight  passando o id, para poder remover o último cara oposto
          // aguardar a remoção e chamar o método para encontrar o ultimo filho novamente
          // porém, tenho que analisar se o penultimo nó, não é o nó 'diamante'
          // pois preciso ter no mínimo um elemento para conseguir fazer o join
          // verificar melhor, pois do diamante ele pode ir para o final proximo
          // console.log(ultimo_next);

          // o node clickright da erro, pois ele chama outro metodo que faz a deleção automatica
          // mas para isso, ele insere um novo nó
          // é necessário criar um novo metodo semelhando ao findAndRemoveNodeById
          // esse método precisa receber a arvore, o no pra apagar e o nó que vai receber a nova info

          // this.removeAndJoinNode(tree, ultimo_next.id, ultimo_yes.id);
          await this.removeAndJoinNode(tree, ultimo_next.id, clickedNode.id);

          // vai ser preciso criar um novo método especificamente para isso
          // await this.nodeClickRight(ultimo_next.id)

          // este, vai receber agora o nextJoinNode com o id do clickedNode e o clickedNode precisa receber
          // lastJoinNode com o id do item que foi encontrado na busca
        }
      } else {
        // fazer a busca no yes

        if (ultimo_yes) {
          // chamar o nodeClickRight  passando o id, para poder remover o último cara oposto
          // aguardar a remoção e chamar o método para encontrar o ultimo filho novamente
          // porém, tenho que analisar se o penultimo nó, não é o nó 'diamante'
          // pois preciso ter no mínimo um elemento para conseguir fazer o join
          // verificar melhor, pois do diamante ele pode ir para o final proximo
          // este, vai receber agora o nextJoinNode com o id do clickedNode e o clickedNode precisa receber
          // lastJoinNode com o id do item que foi encontrado na busca

          await this.removeAndJoinNode(tree, ultimo_yes.id, clickedNode.id);
        }
      }

      this.organizeTree();
    },

    async removeAndJoinNode(node, toDeleteId, toUpdateId) {
      // preciso receber: a arvore, o nó que vai ser removido e o nó onde vai receber uma nova informação

      const IS_TO_ENTER_NEXTNODE =
        node.nextNode &&
        node.nextNode.id === toDeleteId &&
        node.nextNode.formatType !== "diamond";

      const IS_TO_ENTER_YESNEXT =
        node.YesNext &&
        node.YesNext.id === toDeleteId &&
        node.YesNext.formatType !== "diamond";

      // const IS_TO_ENTER_NEXTNODE_DIAMOND =
      //   node.nextNode &&
      //   node.nextNode.id === toDeleteId &&
      //   node.nextNode.formatType === "diamond";

      // const IS_TO_ENTER_YESNEXT_DIAMOND =
      //   node.YesNext &&
      //   node.YesNext.id === toDeleteId &&
      //   node.YesNext.formatType === "diamond";

      if (IS_TO_ENTER_NEXTNODE) {
        // o node.nextNode desse cara vai ser null ou vai ser o cara que será atualizado?
        // vou deixar null e só apontar para o proximo no na propriedade especial
        // node.nextNode = null;
        const NEW_YES_NEXT = await findNodeById(this.tree, toUpdateId);
        if (NEW_YES_NEXT) node.nextNode = NEW_YES_NEXT;
        else node.nextNode = null;
        // é necessário passar a ultima informação, no yesNext do cara
        node.nextJoinNode = toUpdateId;
        const IDX = this.data.nodes.find((el) => el.id == node.id);
        if (IDX >= 0) this.data.nodes[IDX].nextJoinNode = toUpdateId;
        //  tenho que encontra o meu toUpdateId e atualizar ele com o lastJoinNode que vai receber o meu node.id
        await this.removeAndJoinNodeDeep(this.tree, toUpdateId, node.id);
      }

      // COLOCAR O FILHO DO NÓ ENCONTRADO, APONTANDO PARA SER O NOVO NÓ
      if (IS_TO_ENTER_YESNEXT) {
        // como nao pode ser mais null, vou precisar encontrar o nó e botar ele nessa posição
        // node.YesNext = null;
        const NEW_YES_NEXT = await findNodeById(this.tree, toUpdateId);
        if (NEW_YES_NEXT) node.YesNext = NEW_YES_NEXT;
        else node.YesNext = null;

        node.nextJoinNode = toUpdateId;
        const IDX = this.data.nodes.find((el) => el.id == node.id);
        if (IDX >= 0) this.data.nodes[IDX].nextJoinNode = toUpdateId;
        //  tenho que encontra o meu toUpdateId e atualizar ele com o lastJoinNode que vai receber o meu node.id
        await this.removeAndJoinNodeDeep(this.tree, toUpdateId, node.id);
      }

      if (node.nextNode && node.nextNode != node.lastNode) {
        await this.removeAndJoinNode(node.nextNode, toDeleteId, toUpdateId);
      }

      if (node.YesNext && node.YesNext != node.lastNode) {
        await this.removeAndJoinNode(node.YesNext, toDeleteId, toUpdateId);
      }

      return node;
    },

    async removeAndJoinNodeDeep(node, searchId, toUpdateId) {
      const IS_TO_ENTER_NEXTNODE =
        node.nextNode &&
        node.nextNode.id === searchId &&
        node.nextNode.formatType !== "diamond";

      const IS_TO_ENTER_NEXTNODE_DIAMOND =
        node.nextNode &&
        node.nextNode.id === searchId &&
        node.nextNode.formatType === "diamond";

      const IS_TO_ENTER_YESNEXT =
        node.YesNext &&
        node.YesNext.id === searchId &&
        node.YesNext.formatType !== "diamond";

      const IS_TO_ENTER_YESNEXT_DIAMOND =
        node.YesNext &&
        node.YesNext.id === searchId &&
        node.YesNext.formatType === "diamond";

      if (IS_TO_ENTER_NEXTNODE) {
        node.nextNode.lastJoinNode = toUpdateId;

        return node;
      } else if (IS_TO_ENTER_NEXTNODE_DIAMOND) {
        node.nextNode.lastJoinNode = toUpdateId;

        return node;
      }

      // COLOCAR O FILHO DO NÓ ENCONTRADO, APONTANDO PARA SER O NOVO NÓ
      if (IS_TO_ENTER_YESNEXT) {
        node.YesNext.lastJoinNode = toUpdateId;

        return node;
      } else if (IS_TO_ENTER_YESNEXT_DIAMOND) {
        node.YesNext.lastJoinNode = toUpdateId;

        return node;
      }

      // this.keyComponent++;

      if (node.nextNode && node.nextNode != node.lastNode) {
        const updateNextNode = await this.removeAndJoinNodeDeep(
          node.nextNode,
          searchId,
          toUpdateId
        );

        if (updateNextNode === null) {
          node.nextNode = node.nextNode.nextNode;
        } else {
          node.nextNode = updateNextNode;
        }
      }

      if (node.YesNext && node.YesNext != node.lastNode) {
        const updateNextNode = await this.removeAndJoinNodeDeep(
          node.YesNext,
          searchId,
          toUpdateId
        );

        if (updateNextNode === null) {
          node.YesNext = node.YesNext.YesNext;
        } else {
          node.YesNext = updateNextNode;
        }
      }

      //   this.keyComponent++;

      return node;
    },

    async findLastFinalToRemove(node, source) {
      if (node?.type.includes("final") || node[source] == null) return node;

      if (node[source])
        return await this.findLastFinalToRemove(node[source], source);

      return null;
    },

    async findFirstDiamondParent(tree, nodeInfo) {
      if (nodeInfo.formatType == "diamond") return nodeInfo;

      if (nodeInfo.lastNode || nodeInfo?.lastStepId) {
        //  entontrar o nó que tem o lastId correspondente
        const LAST_NODE = await findNodeById(
          tree,
          nodeInfo.lastNode || nodeInfo.lastStepId
        );

        // se encontrar eu passo esse nó no método recursivo
        if (LAST_NODE)
          return await this.findFirstDiamondParent(tree, LAST_NODE);
      }

      return null;
    },

    async findTemplatesSteps(tree, nodeInfo, infoArr) {
      let arr = infoArr;

      if (
        nodeInfo?.type.includes("sendSMS") ||
        nodeInfo?.type.includes("sendEmail")
      ) {
        arr.push(nodeInfo.id);
      }

      if (nodeInfo.lastNode || nodeInfo?.lastStepId) {
        //  entontrar o nó que tem o lastId correspondente
        const LAST_NODE = await findNodeById(
          tree,
          nodeInfo.lastNode || nodeInfo.lastStepId
        );

        // se encontrar eu passo esse nó no método recursivo
        if (LAST_NODE) await this.findTemplatesSteps(tree, LAST_NODE, infoArr);
      } else {
        return arr;
      }

      return arr;
    },

    async countChildrenOnDiamondUntilJoin(tree, nodeInfo) {
      let count = 0;

      if (nodeInfo?.formatType == "diamond") {
        return count;
      }

      if (nodeInfo.lastNode) {
        count++;
        //  entontrar o nó que tem o lastId correspondente
        const LAST_NODE = await findNodeById(tree, nodeInfo.lastNode);
        // se encontrar eu passo esse nó no método recursivo
        if (LAST_NODE) {
          count += await this.countChildrenOnDiamondUntilJoin(tree, LAST_NODE);
        }
      }

      return count;
    },

    countChildrenOnDiamond(node, source) {
      // melhorar lógica futuramente, par lidar com componente diamante? pois ele estando na linha do sim, altera o valor para nextNode ao invés de YesNext?
      let count = 0;

      // Verifica se o nó atual veio da fonte correta
      if (
        (source === "nextNode" && node.nextNode) ||
        (source === "YesNext" && node.YesNext)
      ) {
        count++;
      }

      // Verifica a quantidade de filhos em YesNext
      if (node.YesNext && source == "YesNext") {
        count += this.countChildrenOnDiamond(node.YesNext, source);
      }

      // Verifica a quantidade de filhos em nextNode
      if (node.nextNode && source == "nextNode") {
        count += this.countChildrenOnDiamond(node.nextNode, source);
      }

      return count;
    },

    setDivisionTypeInNode(divisionType) {
      if (divisionType) {
        const IDX = this.data.nodes.findIndex(
          (d) => d.id === this.clickedNode.id
        );

        if (IDX >= 0) {
          this.data.nodes[IDX].mensageriaStepCheckType = divisionType;

          this.InsereInfoIntoNode(
            this.tree,
            this.data.nodes[IDX].id,
            "",
            "updateSomeInformation",
            false
          );
        }

        this.removeStepFromValidationArr(this.data.nodes[IDX].id);
        this.invalidateJourneySteps(this.treeTest, this.data.nodes[IDX].id);
      }

      this.divisionChooseDialog = false;
    },

    async findNodeAndReplaceWithNewInfos(
      nodeId,
      audience,
      filterType,
      currentDatabaseConfigId
    ) {
      const IDX = this.data.nodes.findIndex((d) => d.id == nodeId);

      if (filterType == "audience") {
        if (IDX >= 0) {
          // ALTEREÇÃO DA AUDIENCIA ATUAL
          this.journey.audienceId = audience?.id;

          // ALTERAÇÃO DO LABEL, STEPLABEL DO NÓ ESCOLHIDO
          this.data.nodes[IDX].label = audience.name;
          this.data.nodes[IDX].stepLabel = audience.name;
          this.databaseConfigId = audience.id;

          // ATUALIZAÇÃO DO NÓ NA ÁRVORE
          await this.InsereInfoIntoNode(
            this.tree,
            this.data.nodes[IDX].id,
            audience.name,
            "label",
            true
          );

          this.treeTest = { ...this.tree };
        }
      }

      if (filterType == "filter") {
        if (IDX >= 0) {
          this.databaseConfigId = currentDatabaseConfigId;
          // chamo o método do modal, pois ele faz toda a lógica referente a audiencia filtrada
          this.closeFilteredAudienceModal(audience);
          this.treeTest = { ...this.tree };
        }
      }

      this.keyComponent++;
    },

    async nodeClick(event) {
      this.clickedNodeId = event;
      this.justClickedLink = true;
      const node = this.data.nodes.find((x) => x.id === event);

      if (node?.type != "selectAudience") this.isAudienceClicked = false;
      else this.isAudienceClicked = true;

      this.clickedNode = node;
      if (node?.type == "selectAudience") {
        this.selectAudienceDialog = true;
      } else if (node?.type.includes("sendEmail")) {
        this.typeTemplate = "email";
        this.selectTemplateDialog = true;
      } else if (node?.type.includes("sendSMS")) {
        this.typeTemplate = "sms";
        this.selectTemplateDialog = true;
      } else if (node?.type.includes("await")) {
        this.configureAwaitDialog = true;
      } else if (node?.type.includes("selectAudienceFilter")) {
        this.filteredAudienceDialog = true;
      } else if (node?.type.includes("selectTestABFilter")) {
        this.abtestDialog = true;
      } else if (node?.type.includes("mensageriaCheck")) {
        // preciso de uma nova variavel que vai receber [] de steps de mensageria único
        // essa variavel vai ser resetada e alimentada, sempre que clicado no step de mensageria
        // ou quando fecha o modal
        // para tal, preciso verificar qual step vem atras do meu atual step clicado e
        // buscar todos os steps que tem o type sendEmail ou sendSMS
        const node_found = await findNodeById(this.tree, event);

        if (node_found) {
          const test = await this.findTemplatesSteps(this.tree, node_found, []);
          for (let id of test) {
            const el = await this.data.nodes.find((d) => d.id == id);
            if (el) this.currentMessageriaStepsItems.push(el);
          }
        }
        this.mensageriaCheckDialog = true;
      } else if (node?.type.includes("sendPush")) {
        this.typeTemplate = "push";
        this.selectPushDialog = true;
      } else if (node?.type.includes("sendFacebook")) {
        this.colectionNameDialog = true;
      } else if (node?.type === "tagUser") {
        if (node?.tags) {
          var arr = node?.tags.split(";");
          var objTag = {};
          this.stepTags = arr.map((el, i) => (objTag[i + 1] = el));
        }
        this.tagUserDialog = true;
      } else if (node?.type.includes("sendGoogle")) {
        this.colectionNameDialogGoogle = true;
      } else if (node?.type.includes("sendWhatsApp")) {
        this.typeTemplate = "whatsapp";
        this.selectWppDialog = true;
      }
    },

    setNodeToRename(nodeToRename) {
      this.nodeToRename = nodeToRename;
      this.renameNodeDialog = true;
    },

    setNewNodeName(newName) {
      if (this.nodeToRename) {
        const IDX = this.data.nodes.findIndex(
          (d) => d.id === this.nodeToRename.id
        );

        if (IDX >= 0) {
          this.data.nodes[IDX].label = newName;
          this.data.nodes[IDX].stepLabel = newName;
          this.data.nodes[IDX].wasRenamed = true;

          this.InsereInfoIntoNode(
            this.tree,
            this.data.nodes[IDX].id,
            newName,
            "label",
            false
          );

          this.treeTest = { ...this.tree };

          // this.removeStepFromValidationArr(this.data.nodes[IDX].id);
          // this.invalidateJourneySteps(this.treeTest, this.data.nodes[IDX].id);
        }
      }
      this.renameNodeDialog = false;
    },

    setNodeToRemove(nodeToRemove) {
      this.nodeToRemove = nodeToRemove;
      this.deleteNodeDialog = true;
    },

    InsereInfoIntoNode(
      objeto,
      id,
      info,
      infoType,
      objectMustBeUpdated = false
    ) {
      // Verifica se o objeto atual tem o ID desejado
      if (objeto.id === id) {
        if (infoType.includes("filterAudienceId")) {
          objeto.filterId = info;
        } else if (infoType.includes("mensageriaStepCheckId")) {
          objeto.mensageriaStepCheckId = info;
        } else if (infoType.includes("templateId")) {
          objeto.templateId = info;

          // if (objeto.type === "sendEmail") {
          //   if (objeto.mailSender && objeto.mailSubject) {
          objeto.isDataSelected = true;
          //   }
          // }
        } else if (infoType.includes("label")) {
          objeto.label = info;
          objeto.stepLabel = info;
          // if (objeto.type !== "sendEmail" && objeto.selectedContentId) objeto.isDataSelected = true;
          if (objeto.type !== "sendEmail") objeto.isDataSelected = true;
        } else if (infoType.includes("lastAudience")) {
          objeto.lastAudience = info;
        } else if (infoType.includes("await")) {
          objeto.wait = info.split(" ")[1];
          objeto.waitValue = info.split(" ")[0];
        } else if (infoType === "percentageA") {
          objeto.percentageA = info;
        } else if (infoType === "percentageB") {
          objeto.percentageB = info;
        } else if (infoType === "mailSubject") {
          objeto.mailSubject = info;
          if (objeto.mailSender && objeto.templateId) {
            objeto.isDataSelected = true;
          }
        } else if (infoType === "mailSender") {
          objeto.mailSender = info;
          if (objeto.templateId && objeto.mailSubject) {
            objeto.isDataSelected = true;
          }
        } else if (infoType === "collectionName") {
          objeto.collectionName = info;
          objeto.isDataSelected = true;
        } else if (infoType === "collectionId") {
          objeto.collectionId = info;
          objeto.isDataSelected = true;
        } else if (infoType === "tags") {
          objeto.tags = info;
          objeto.isDataSelected = true;
        } else if (infoType === "fieldMap") {
          objeto.fieldMap = info;
          objeto.isDataSelected = true;
        }

        if (objectMustBeUpdated) {
          objeto.isDataSelected = true;
        }

        if (infoType === "updateSomeInformation") return id;

        return id;
      }

      // Verifica se o objeto tem uma próxima propriedade "nextNode"
      if (objeto.nextNode) {
        // Chama recursivamente a função para o próximo nó
        const indiceProximoNodo = this.InsereInfoIntoNode(
          objeto.nextNode,
          id,
          info,
          infoType
        );
        if (indiceProximoNodo !== -1) {
          return indiceProximoNodo; // Retorna o índice encontrado
        }
      }

      // Verifica se o objeto tem uma propriedade "YesNext"
      if (objeto.YesNext) {
        // Chama recursivamente a função para o nó "YesNext"
        const indiceYesNext = this.InsereInfoIntoNode(
          objeto.YesNext,
          id,
          info,
          infoType
        );
        if (indiceYesNext !== -1) {
          return indiceYesNext; // Retorna o índice encontrado
        }
      }

      return -1; // Retorna -1 caso o ID não seja encontrado
    },

    InsereLabelNode(objeto, id, text) {
      // Verifica se o objeto atual tem o ID desejado
      if (objeto.id === id) {
        objeto.label = text; // Retorna o índice de profundidade
        objeto.stepLabel = text;
        return id;
      }

      // Verifica se o objeto tem uma próxima propriedade "nextNode"
      if (objeto.nextNode) {
        // Chama recursivamente a função para o próximo nó
        const indiceProximoNodo = this.InsereLabelNode(
          objeto.nextNode,
          id,
          text
        );
        if (indiceProximoNodo !== -1) {
          return indiceProximoNodo; // Retorna o índice encontrado
        }
      }

      // Verifica se o objeto tem uma propriedade "YesNext"
      if (objeto.YesNext) {
        // Chama recursivamente a função para o nó "YesNext"
        const indiceYesNext = this.InsereLabelNode(objeto.YesNext, id, text);
        if (indiceYesNext !== -1) {
          return indiceYesNext; // Retorna o índice encontrado
        }
      }

      return -1; // Retorna -1 caso o ID não seja encontrado
    },

    findAndReturnSomeNode(node, nodeType) {
      if (node.type == nodeType) {
        return node;
      } else {
        if (node.nextNode) {
          const foundNode = this.findAndReturnSomeNode(node.nextNode, nodeType);
          if (foundNode) return foundNode;
        }

        if (node.YesNext) {
          const foundNode = this.findAndReturnSomeNode(node.YesNext, nodeType);
          if (foundNode) return foundNode;
        }
      }

      return null;
    },

    findTemplatesStepsAndReset(node) {
      if (node.type == "sendEmail" || node.type == "sendSMS") {
        // caso o nó seja um template, devo resetar as informações dele
        const IDX = this.data.nodes.findIndex((d) => d.id == node.id);

        if (IDX >= 0) {
          this.data.nodes[IDX].isDataSelected = false;
          this.data.nodes[IDX].templateId = null;
          this.data.nodes[IDX].label =
            node.type == "sendEmail" ? "Selecionar\ne-mail" : "Enviar SMS";
          this.data.nodes[IDX].stepLabel = this.data.nodes[IDX].label;
          node.label = this.data.nodes[IDX].label;
          node.stepLabel = this.data.nodes[IDX].label;
          node.isDataSelected = false;
          node.templateId = null;
        }
      }

      if (node.nextNode && node.nextNode != node.lastNode) {
        const UPDATE_NEXT_NODE = this.findTemplatesStepsAndReset(node.nextNode);

        if (UPDATE_NEXT_NODE === null)
          this.findTemplatesStepsAndReset(node.nextNode.nextNode);
      }

      if (node.YesNext && node.YesNext != node.lastNode) {
        const UPDATE_NEXT_NODE = this.findTemplatesStepsAndReset(node.YesNext);

        if (UPDATE_NEXT_NODE === null)
          this.findTemplatesStepsAndReset(node.YesNext.YesNext);
      }

      return;
    },

    findMessageriaNodesAndValidate(node) {
      if (node.type.includes("mensageriaCheck")) {
        if (node.mensageriaStepCheckId) {
          const IDX = this.messageriaStepsItems.findIndex(
            (el) => el.id === node.mensageriaStepCheckId
          );
          // NECESSÁRIO ALTERAR O mensageriaStepCheckId DO NÓ PARA UMA STRING VAZIA, CASO NÃO SEJA ENCONTRADO CORRESPONDENTE
          // E ALTERAR O STATUS DO shouldValidate
          if (IDX < 0) {
            node.shouldValidate = true;
            node.mensageriaStepCheckId = null;
            //   this.InsereInfoIntoNode()
          } else this.removeStepFromValidationArr(node.id);
        }
        return null;
      }

      if (node.nextNode && node.nextNode != node.lastNode) {
        const UPDATE_NEXT_NODE = this.findMessageriaNodesAndValidate(
          node.nextNode
        );

        if (UPDATE_NEXT_NODE === null)
          this.findMessageriaNodesAndValidate(node.nextNode.nextNode);
      }

      if (node.YesNext && node.YesNext != node.lastNode) {
        const UPDATE_NEXT_NODE = this.findMessageriaNodesAndValidate(
          node.YesNext
        );

        if (UPDATE_NEXT_NODE === null)
          this.findMessageriaNodesAndValidate(node.YesNext.YesNext);
      }
    },

    findAndRemoveNodeById(node, id) {
      // CASO O ID DO PRIMEIO NÓ SEJA IGUAL, ELE É O RAIZ
      // LOGO, RESETAR AS INFORMAÇÕES DA ÁRVORE
      if (node.id === id) {
        node = {};
        this.tree = node;
        this.treeTest = node;
        this.data.nodes = [];
        this.keyComponent++;
        return null;
      }

      const IS_TO_ENTER_NEXTNODE =
        node.nextNode &&
        node.nextNode.id === id &&
        node.nextNode.formatType !== "diamond";

      const IS_TO_ENTER_NEXTNODE_DIAMOND =
        node.nextNode &&
        node.nextNode.id === id &&
        node.nextNode.formatType === "diamond";

      const IS_TO_ENTER_YESNEXT =
        node.YesNext &&
        node.YesNext.id === id &&
        node.YesNext.formatType !== "diamond";

      const IS_TO_ENTER_YESNEXT_DIAMOND =
        node.YesNext &&
        node.YesNext.id === id &&
        node.YesNext.formatType === "diamond";

      if (IS_TO_ENTER_NEXTNODE) {
        let newNodeToEnd = {};
        newNodeToEnd = node.nextNode.nextNode;
        newNodeToEnd.lastNode = node.id;
        node.nextNode = newNodeToEnd;
        return node;
      } else if (IS_TO_ENTER_NEXTNODE_DIAMOND) {
        let newFinalNode = this.generateFinalNodes();
        newFinalNode.lastNode = node.id;
        node.nextNode = newFinalNode;
        return node;
      }

      // COLOCAR O FILHO DO NÓ ENCONTRADO, APONTANDO PARA SER O NOVO NÓ
      if (IS_TO_ENTER_YESNEXT) {
        let newNodeToEnd = {};
        newNodeToEnd = node.YesNext.YesNext;
        // newNodeToEnd = node.YesNext.nextNode || node.YesNext.YesNext;
        newNodeToEnd.lastNode = node.id;
        newNodeToEnd.fromClick = "yes";
        node.YesNext = newNodeToEnd;
        return node;
      } else if (IS_TO_ENTER_YESNEXT_DIAMOND) {
        let newFinalNode = this.generateFinalNodes();
        newFinalNode.lastNode = node.id;
        newFinalNode.fromClick = "yes";
        node.YesNext = newFinalNode;
        return node;
      }

      if (node.nextNode && node.nextNode != node.lastNode) {
        const updateNextNode = this.findAndRemoveNodeById(node.nextNode, id);

        if (updateNextNode === null) {
          node.nextNode = node.nextNode.nextNode;
        } else {
          node.nextNode = updateNextNode;
        }
      }

      if (node.YesNext && node.YesNext != node.lastNode) {
        const updateNextNode = this.findAndRemoveNodeById(node.YesNext, id);

        if (updateNextNode === null) {
          node.YesNext = node.YesNext.YesNext;
        } else {
          node.YesNext = updateNextNode;
        }
      }

      this.keyComponent++;

      return node;
    },

    findAndRemoveUnionNodeById(node, id) {
      // CASO O ID DO PRIMEIO NÓ SEJA IGUAL, ELE É O RAIZ
      // LOGO, RESETAR AS INFORMAÇÕES DA ÁRVORE

      const IS_TO_ENTER_NEXTNODE =
        node.nextNode &&
        node.nextNode.id === id &&
        node.nextNode.formatType !== "diamond";

      const IS_TO_ENTER_NEXTNODE_DIAMOND =
        node.nextNode &&
        node.nextNode.id === id &&
        node.nextNode.formatType === "diamond";

      const IS_TO_ENTER_YESNEXT =
        node.YesNext &&
        node.YesNext.id === id &&
        node.YesNext.formatType !== "diamond";

      const IS_TO_ENTER_YESNEXT_DIAMOND =
        node.YesNext &&
        node.YesNext.id === id &&
        node.YesNext.formatType === "diamond";

      if (IS_TO_ENTER_NEXTNODE || IS_TO_ENTER_NEXTNODE_DIAMOND) {
        // inserir novo nó final, no fim do nó
        let newNodeToEnd = this.generateFinalNodes();
        newNodeToEnd.lastNode = node.id;
        node.nextNode = newNodeToEnd;
        return node;
      }

      // COLOCAR O FILHO DO NÓ ENCONTRADO, APONTANDO PARA SER O NOVO NÓ
      if (IS_TO_ENTER_YESNEXT || IS_TO_ENTER_YESNEXT_DIAMOND) {
        let newNodeToEnd = this.generateFinalNodes();
        newNodeToEnd.lastNode = node.id;
        newNodeToEnd.fromClick = "yes";
        node.YesNext = newNodeToEnd;
        node.nextJoinNode = null;
        node.yesNextStepId = null;
        return node;
      }

      if (node.nextNode && node.nextNode != node.lastNode) {
        const updateNextNode = this.findAndRemoveUnionNodeById(
          node.nextNode,
          id
        );

        if (updateNextNode === null) {
          node.nextNode = node.nextNode.nextNode;
        } else {
          node.nextNode = updateNextNode;
        }
      }

      if (node.YesNext && node.YesNext != node.lastNode) {
        const updateNextNode = this.findAndRemoveUnionNodeById(
          node.YesNext,
          id
        );

        if (updateNextNode === null) {
          node.YesNext = node.YesNext.YesNext;
        } else {
          node.YesNext = updateNextNode;
        }
      }

      this.keyComponent++;

      return node;
    },

    changeObjectKeys(node, target, newKey, newFromClickType) {
      let is_diamond_or_final =
        node.formatType == "diamond" || node.type == "final";

      if (node[target] && is_diamond_or_final) return node;

      if (node[target] && node[target].formatType !== "diamond") {
        let bkp = node[target];
        delete node[target];
        node[newKey] = bkp;
        node[newKey].fromClick = newFromClickType;
      }

      if (node[newKey]) this.changeObjectKeys(node[newKey], target, newKey);

      return node;
    },

    insertNode(tree, newNode, parentNodeId, fromClick) {
      if (!tree.id) {
        Object.assign(tree, newNode);
        return true;
      }

      if (tree.id === parentNodeId) {
        let existingNode = fromClick === "yes" ? tree.YesNext : tree.nextNode;

        const FROM_CLICK_IS_NEXT_OR_DONT_HAVE =
          existingNode &&
          (!existingNode.fromClick || existingNode.fromClick === "next");

        if (existingNode) {
          const IS_DIAMOND_AND_EXISTING_IS_NOT_FINAL =
            newNode.formatType == "diamond" && existingNode.type != "final";

          if (FROM_CLICK_IS_NEXT_OR_DONT_HAVE) {
            if (IS_DIAMOND_AND_EXISTING_IS_NOT_FINAL) {
              // o metodo test inverte as chaves do objeto, o que for YesNext, vira nextNode e vice-versa
              // além de alterar o from click, visto que ao alterar as keys, é preciso alterar o destino do click
              existingNode = this.changeObjectKeys(
                existingNode,
                "YesNext",
                "nextNode",
                "next"
              );
              existingNode.lastNode = newNode.id;
              newNode.nextNode = existingNode;
            } else {
              existingNode.lastNode = newNode.id;
              newNode.nextNode = existingNode;
            }
          } else {
            existingNode.lastNode = newNode.id;
            newNode.YesNext = existingNode;
          }
        }

        if (fromClick === "yes") {
          tree.YesNext = newNode;
        } else {
          tree.nextNode = newNode;
        }

        // newNode.lastNode = tree.id;
        if (this.$route.params.id) newNode.isNew = true;

        return true;
      }

      if (
        tree.nextNode &&
        this.insertNode(tree.nextNode, newNode, parentNodeId, fromClick)
      ) {
        return true;
      }

      if (
        tree.YesNext &&
        this.insertNode(tree.YesNext, newNode, parentNodeId, fromClick)
      ) {
        return true;
      }

      return false;
    },

    async nodeClickRight(event) {
      //  console.log("evento de apagar", event);
      this.rightclickedNodeId = event;

      const index = this.arrValidationSteps.findIndex(
        (x) => x.id === this.rightclickedNodeId
      );

      const CURRENT_NODE_DELETED = this.data.nodes.find((d) => d.id === event);
      const CURRENT_NODE_TO_DELETE_ON_TREE = await findNodeById(
        this.tree,
        event
      );

      index !== -1 ? this.arrValidationSteps.splice(index, 1) : "";

      // caso seja para apagar um nó normal, seguir fluxo pre determinado
      if (
        !CURRENT_NODE_TO_DELETE_ON_TREE?.type.includes("union") &&
        !CURRENT_NODE_TO_DELETE_ON_TREE?.nextJoinNode
      ) {
        await this.findAndRemoveNodeById(this.tree, event);
        await this.removeNodeFromDataArr(event);

        // CASO APAGUE UM TEMPLATE, É NECESSÁRIO VALIDAR TODOS OS NÓS DE MENSAGERIA
        const IS_TEMPLATE =
          CURRENT_NODE_DELETED.type.includes("sendEmail") ||
          CURRENT_NODE_DELETED.type.includes("sendSMS");

        if (IS_TEMPLATE) this.findMessageriaNodesAndValidate(this.tree);
      }

      // aqui eu preciso verificar se meu dado é do tipo union
      // ou ele é um nó que relaiza o merge, tal nó tem o lastJoinNode ou  yesLastStepId
      if (CURRENT_NODE_TO_DELETE_ON_TREE?.type.includes("union")) {
        // encontrar o nó comun entre o sim e não (o nó de merge que está nos dois lados)
        const last_information = await findNodeById(this.tree, event);
        if (last_information) {
          await this.findAndRemoveUnionNodeById(this.tree, event);
          await this.removeNodeFromDataArr(event);
        }
      }

      // veririficar se o nó não é do tipo union, mas ele faz merge
      if (
        CURRENT_NODE_TO_DELETE_ON_TREE.nextJoinNode &&
        !CURRENT_NODE_TO_DELETE_ON_TREE?.type.includes("union")
      ) {
        // tenho que encontrar o nó para qual ele faz o merge
        const NODE_THAT_HAS_UNION = await findNodeById(
          this.tree,
          CURRENT_NODE_TO_DELETE_ON_TREE.nextJoinNode
        );

        // então, eu deleto o nó comum entre as linhas
        if (NODE_THAT_HAS_UNION) {
          await this.findAndRemoveUnionNodeById(
            this.tree,
            NODE_THAT_HAS_UNION.id
          );
          await this.removeNodeFromDataArr(NODE_THAT_HAS_UNION.id);
        }

        // e também, preciso remover o nó que foi solicitado
        await this.findAndRemoveUnionNodeById(
          this.tree,
          CURRENT_NODE_TO_DELETE_ON_TREE.id
        );
        await this.removeNodeFromDataArr(CURRENT_NODE_TO_DELETE_ON_TREE.id);
      }

      this.organizeTree();
    },

    async yes_click(event) {
      this.newNodeFromNextOrYesClick = "yes";
      this.insertIndexPosition = event;
      this.startJourneyDialog = true;
      const node = await findNodeById(this.tree, event);
      if (node?.type != "selectAudience") this.isAudienceClicked = false;
      else this.isAudienceClicked = true;
    },

    async no_click(event) {
      // criar lógica para pegar o nó diamante parente se for clicado
      // isso, para mostrar ou não a opção de join no modal
      const NODE_TO_SEARCH = await findNodeById(this.tree, event);
      this.currentDiamondOrChildDiamondClicked = await this.canShowMergeOption(
        this.tree,
        NODE_TO_SEARCH
      );

      this.newNodeFromNextOrYesClick = "next";
      this.insertIndexPosition = event;
      this.startJourneyDialog = true;
      const node = await findNodeById(this.tree, event);
      if (node?.type != "selectAudience") this.isAudienceClicked = false;
      else this.isAudienceClicked = true;
    },

    async canShowMergeOption(tree, node) {
      let can_show = false;

      const PARENT_DIAMOND = await this.findFirstDiamondParent(tree, node);

      if (PARENT_DIAMOND) {
        const HAS_ALREADY_MERGE = await this.verifyIfNodeHaveMerge(
          PARENT_DIAMOND
        );

        // caso seja maior que um, quer dizer que há mais elementos além do step final
        const NUMBERS_OF_CHILDS_ON_YES_LINE = await this.countChildrenOnDiamond(
          PARENT_DIAMOND,
          "YesNext"
        );

        // caso a linah do não, não tenha nenhum dado
        const NUMBERS_OF_CHILDS_ON_NO_LINE = await this.countChildrenOnDiamond(
          PARENT_DIAMOND,
          "nextNode"
        );

        // verificação para identificar se na linha do sim, já tem um componente do tipo diamond
        const ALREADY_HAVE_DIAMOND_BROTHER =
          await this.verifyLineHaveSomeDiamond(PARENT_DIAMOND, "YesNext");

        can_show =
          !HAS_ALREADY_MERGE &&
          NUMBERS_OF_CHILDS_ON_YES_LINE > 1 &&
          NUMBERS_OF_CHILDS_ON_NO_LINE > 1 &&
          node.fromClick == "next" &&
          !ALREADY_HAVE_DIAMOND_BROTHER;
      }

      return can_show;
    },

    async clickLink(event) {
      // criar lógica para pegar o nó diamante parente se for clicado
      // isso, para mostrar ou não a opção de join no modal
      const NODE_TO_SEARCH = await findNodeById(this.tree, event);

      this.currentDiamondOrChildDiamondClicked = await this.canShowMergeOption(
        this.tree,
        NODE_TO_SEARCH
      );

      this.newNodeFromNextOrYesClick = "next";
      this.insertIndexPosition = event;
      this.startJourneyDialog = true;
      //  const node = await findNodeById(this.tree, event);
      this.isAudienceClicked = false;
    },

    findNextNode(id) {
      const fid = id;
      const node = this.data.nodes.find((x) => x.id === fid);
      return {
        id: node.id,
        positionToInsertNewNode:
          this.data.nodes.findIndex((x) => x.id === fid) + 1,
      };
    },

    pushNewNode(index, obj, replace) {
      // eslint-disable-next-line no-debugger
      // debugger
      if (replace) this.data.nodes.splice(index, 0, obj);
      else this.data.nodes.push(obj);
    },

    removeNodeFromDataArr(id) {
      const IDX = this.data.nodes.findIndex((d) => d.id === id);
      IDX >= 0 ? this.data.nodes.splice(IDX, 1) : "";

      return;
    },

    async verifyIfNodeHaveMerge(node) {
      if (node.lastJoinNode !== null && node.lastJoinNode !== undefined) {
        return true;
      } else {
        // busco apenas no next, visto que o merge é feito pela linha do nextNode apenas
        if (node.nextNode) {
          return await this.verifyIfNodeHaveMerge(node.nextNode);
        }
      }
      return false;
    },

    async verifyLineHaveSomeDiamond(node, target) {
      if (node[target] && node[target].formatType == "diamond") return true;
      else {
        if (node[target])
          return await this.verifyLineHaveSomeDiamond(node[target], target);
      }

      return false;
    },

    // --------------- FECHAMENDO DE MODAIS -----------------------------
    async closeTagModal(event) {
      this.tagUserDialog = false;
      console.log(event);
      if (event.tags) {
        const index = this.data.nodes.findIndex(
          (x) => x.id === this.clickedNodeId
        );
        await this.InsereInfoIntoNode(
          this.tree,
          this.data.nodes[index].id,
          event.tags,
          "tags",
          true
        );

        this.removeStepFromValidationArr(this.data.nodes[index].id);
        this.invalidateJourneySteps(this.treeTest, this.data.nodes[index].id);
        this.insertCurrentStepOnChange();
        this.$forceUpdate();
      }
    },

    async closeColectionModal(event) {
      this.colectionNameDialog = false;

      console.log(event);
      if (event.collectionId) {
        const index = this.data.nodes.findIndex(
          (x) => x.id === this.clickedNodeId
        );

        await this.InsereInfoIntoNode(
          this.tree,
          this.data.nodes[index].id,
          event.collectionId,
          "collectionId",
          true
        );

        await this.InsereInfoIntoNode(
          this.tree,
          this.data.nodes[index].id,
          event.fieldMap,
          "fieldMap",
          true
        );

        await this.InsereInfoIntoNode(
          this.tree,
          this.data.nodes[index].id,
          event.name,
          "collectionName",
          true
        );

        this.removeStepFromValidationArr(this.data.nodes[index].id);
        this.invalidateJourneySteps(this.treeTest, this.data.nodes[index].id);
        this.insertCurrentStepOnChange();
      }
    },

    async closeConfigurationModal(event) {
      if (event) {
        this.recurrencyConfiguration = {};
        this.recurrencyConfiguration = { ...event };
        this.journeyConfiguration = { ...event };
        console.log("this.journeyConfiguration: ", this.journeyConfiguration);
        this.objFastJourney = this.journeyConfiguration;
        console.log("objFastJourney: ", this.objFastJourney);
        this.journey.cron = await buildCronStringV2(this.journeyConfiguration);
        this.journey.ignoreOptout = this.journeyConfiguration.ignoreOptout;
        this.journey.ignoreJourneys = this.journeyConfiguration.ignoreJourneys;
        this.journey.finishNotificationEmails =
          this.journeyConfiguration.finishNotificationEmails ||
          event.finishNotificationEmails;
        this.journey.ignoreAudiences =
          this.journeyConfiguration.ignoreAudiences;
        this.journey.newUserJourneyEntryBehavior =
          event.newUserJourneyEntryBehavior;
        this.journey.userWaitBehaviour = event.userWaitBehaviour;
        if (event.timerType === "punctual" && event.init !== "immediate") {
          this.journey.nextUpdate = new Date(this.journey.cron);
          this.journey.cron = null;
        }
        this.journey.endsAt = this.journeyConfiguration.end;
        this.journey.startsAt =
          this.journeyConfiguration.init !== "immediate" &&
          this.journeyConfiguration.init !== ""
            ? this.journeyConfiguration.init
            : null;
        this.journey.duplicateSendInterval = parseInt(
          this.journeyConfiguration.duplicateSendInterval
        );
        this.journey.duplicateSendIntervalType =
          this.journeyConfiguration.duplicateSendIntervalType;
        this.journeyRecorrenceConfigured = true;
        this.journeyValidation.recurrency = true;
        this.journey.shouldSentMensageriaToExistentContact =
          event.shouldSentMensageriaToExistentContact;
      }
      this.configurationDialog = false;

      this.recurrencyConfiguration = event;
      delete this.recurrencyConfiguration.ignoreJourneys;
      delete this.recurrencyConfiguration.finishNotificationEmails;
      delete this.recurrencyConfiguration.ignoreAudiences;
    },

    closeMensageriaCheckModal(event) {
      if (event) {
        const index = this.data.nodes.findIndex(
          (x) => x.id === this.clickedNodeId
        );

        if (
          this.data.nodes[index]?.mensageriaStepCheckId ||
          this.data.nodes[index].mensageriaStepCheckId != event.id
        )
          this.divisionChooseDialog = true;

        if (this.data.nodes[index].wasRenamed) {
          this.data.nodes[index].label = event.label;
          this.data.nodes[index].stepLabel = event.label;

          this.InsereInfoIntoNode(
            this.tree,
            this.data.nodes[index].id,
            event.label,
            "label"
          );
        } else {
          this.InsereInfoIntoNode(
            this.tree,
            this.data.nodes[index].id,
            this.data.nodes[index].label,
            "label"
          );
        }

        this.InsereInfoIntoNode(
          this.tree,
          this.data.nodes[index].id,
          event.id,
          "mensageriaStepCheckId"
        );
        // EXPERIMENTAL OS CHAMADOS ABAIXO
        this.InsereInfoIntoNode(
          this.tree,
          this.data.nodes[index].id,
          this.lastAudience,
          "lastAudience"
        );
        this.InsereInfoIntoNode(
          this.tree,
          this.data.nodes[index].id,
          this.lastAudienceType,
          "typeAudience"
        );
        this.removeStepFromValidationArr(this.data.nodes[index].id);
        this.invalidateJourneySteps(this.treeTest, this.data.nodes[index].id);

        //this.alterContent();
        this.insertCurrentStepOnChange();
      }
      this.mensageriaCheckDialog = false;
      // esvazia array local, sempre que os dados forem apagados e já inseridos no nó
      this.currentMessageriaStepsItems = [];
    },

    async closeSelectAudienceModal(event) {
      this.selectAudienceDialog = false;
      this.journey.audienceId = event?.id;

      if (event) {
        this.databaseConfigId = event.databaseConfigId;

        const index = this.data.nodes.findIndex(
          (x) => x.id === this.clickedNodeId
        );

        // this.data.nodes[index].selectedContentId = event?.id;

        if (!this.data.nodes[index].wasRenamed) {
          this.data.nodes[index].label = event.name;
          this.data.nodes[index].stepLabel = event.name;

          this.InsereInfoIntoNode(
            this.tree,
            this.data.nodes[index].id,
            event.name,
            "label",
            true
          );
        } else {
          this.InsereInfoIntoNode(
            this.tree,
            this.data.nodes[index].id,
            this.data.nodes[index].label,
            "label",
            true
          );
        }
        this.treeTest = { ...this.tree };
        this.lastAudience = event.id;
        this.lastAudienceType = "audience";
        // this.alterContent();
        this.insertCurrentStepOnChange();
      }
      await this.findTemplatesStepsAndReset(this.tree);
      this.render = false;
      this.render = true;
      this.organizeTree();
      this.$forceUpdate();
    },

    closeAbTestModal(event) {
      this.abtestDialog = false;
      if (event) {
        const index = this.data.nodes.findIndex(
          (x) => x.id === this.clickedNodeId
        );

        if (!this.data.nodes[index].wasRenamed) {
          this.data.nodes[index].label = event.name;
          this.data.nodes[index].stepLabel = event.name;
          this.InsereInfoIntoNode(
            this.tree,
            this.data.nodes[index].id,
            event.name,
            "label"
          );
        } else {
          this.InsereInfoIntoNode(
            this.tree,
            this.data.nodes[index].id,
            this.data.nodes[index].label,
            "label"
          );
        }

        this.InsereInfoIntoNode(
          this.tree,
          this.data.nodes[index].id,
          this.lastAudience,
          "lastAudience"
        );
        this.InsereInfoIntoNode(
          this.tree,
          this.data.nodes[index].id,
          this.lastAudienceType,
          "typeAudience"
        );
        this.InsereInfoIntoNode(
          this.tree,
          this.data.nodes[index].id,
          event.percentageA,
          "percentageA"
        );
        this.InsereInfoIntoNode(
          this.tree,
          this.data.nodes[index].id,
          event.percentageB,
          "percentageB"
        );

        this.removeStepFromValidationArr(this.data.nodes[index].id);
        this.invalidateJourneySteps(this.treeTest, this.data.nodes[index].id);
        // this.alterContent();
        this.insertCurrentStepOnChange();
      }
    },

    closeFilteredAudienceModal(event) {
      this.filteredAudienceDialog = false;
      if (event) {
        const index = this.data.nodes.findIndex(
          (x) => x.id === this.clickedNodeId
        );

        if (!this.data.nodes[index].wasRenamed) {
          this.data.nodes[index].label = event.name;
          this.data.nodes[index].stepLabel = event.name;
          this.InsereInfoIntoNode(
            this.tree,
            this.data.nodes[index].id,
            event.name,
            "label"
          );
        } else {
          this.InsereInfoIntoNode(
            this.tree,
            this.data.nodes[index].id,
            this.data.nodes[index].label,
            "label"
          );
        }

        this.InsereInfoIntoNode(
          this.tree,
          this.data.nodes[index].id,
          event.id,
          "filterAudienceId"
        );
        this.InsereInfoIntoNode(
          this.tree,
          this.data.nodes[index].id,
          this.lastAudience,
          "lastAudience"
        );
        this.InsereInfoIntoNode(
          this.tree,
          this.data.nodes[index].id,
          this.lastAudienceType,
          "typeAudience"
        );
        this.lastAudience = event.id;
        this.lastAudienceType = "filterAudience";

        this.removeStepFromValidationArr(this.data.nodes[index].id);
        this.invalidateJourneySteps(this.treeTest, this.data.nodes[index].id);
        // this.alterContent();
        this.insertCurrentStepOnChange();
      }
    },

    async closeSelectTemplateModal(event) {
      this.selectTemplateDialog = false;
      if (event) {
        const index = this.data.nodes.findIndex(
          (x) => x.id === this.clickedNodeId
        );

        // this.data.nodes[index].label = event.templateName;
        //  this.data.nodes[index].selectedContentId = event?.templateId;

        if (!this.data.nodes[index].wasRenamed) {
          this.data.nodes[index].label = event.templateName;
          this.data.nodes[index].stepLabel = event.templateName;

          await this.InsereInfoIntoNode(
            this.tree,
            this.data.nodes[index].id,
            event.templateName,
            "label",
            true
          );
        } else {
          this.InsereInfoIntoNode(
            this.tree,
            this.data.nodes[index].id,
            this.data.nodes[index].label,
            "label",
            true
          );
        }

        this.InsereInfoIntoNode(
          this.tree,
          this.data.nodes[index].id,
          event.templateId,
          "templateId",
          true
        );

        this.removeStepFromValidationArr(this.data.nodes[index].id);
        this.invalidateJourneySteps(this.treeTest, this.data.nodes[index].id);

        if (this.data.nodes[index].type.includes("sendEmail")) {
          if (event.mailSender !== "")
            this.InsereInfoIntoNode(
              this.tree,
              this.data.nodes[index].id,
              event.mailSender,
              "mailSender",
              true
            );

          if (event.mailSubject !== "")
            this.InsereInfoIntoNode(
              this.tree,
              this.data.nodes[index].id,
              event.mailSubject,
              "mailSubject",
              true
            );

          if (
            event.mailSender &&
            event.mailSender !== "" &&
            event.mailSubject &&
            event.mailSubject !== ""
          ) {
            this.removeStepFromValidationArr(this.data.nodes[index].id);
            this.invalidateJourneySteps(
              this.treeTest,
              this.data.nodes[index].id
            );
          }
        } else {
          this.removeStepFromValidationArr(this.data.nodes[index].id);
          this.invalidateJourneySteps(this.treeTest, this.data.nodes[index].id);
        }
        // this.alterContent();
        this.insertCurrentStepOnChange();
      }
      this.$forceUpdate();
    },

    closePushModal(event) {
      this.selectPushDialog = false;
      if (event) {
        const index = this.data.nodes.findIndex(
          (x) => x.id === this.clickedNodeId
        );

        if (!this.data.nodes[index].wasRenamed) {
          this.data.nodes[index].label = event.templateName;
          this.data.nodes[index].stepLabel = event.templateName;

          this.InsereInfoIntoNode(
            this.tree,
            this.data.nodes[index].id,
            event.templateName,
            "label",
            true
          );
        } else {
          this.InsereInfoIntoNode(
            this.tree,
            this.data.nodes[index].id,
            this.data.nodes[index].label,
            "label",
            true
          );
        }

        this.InsereInfoIntoNode(
          this.tree,
          this.data.nodes[index].id,
          event.templateId,
          "templateId",
          true
        );

        this.removeStepFromValidationArr(this.data.nodes[index].id);
        this.removeStepFromValidationArr(this.data.nodes[index].id);
        this.invalidateJourneySteps(this.treeTest, this.data.nodes[index].id);
        this.insertCurrentStepOnChange();
      }
      this.$forceUpdate();
    },

    closeWppModal(event) {
      this.selectWppDialog = false;
      if (event) {
        const index = this.data.nodes.findIndex(
          (x) => x.id === this.clickedNodeId
        );

        if (!this.data.nodes[index].wasRenamed) {
          this.data.nodes[index].label = event.templateName;
          this.data.nodes[index].stepLabel = event.templateName;

          this.InsereInfoIntoNode(
            this.tree,
            this.data.nodes[index].id,
            event.templateName,
            "label",
            true
          );
        } else {
          this.InsereInfoIntoNode(
            this.tree,
            this.data.nodes[index].id,
            this.data.nodes[index].label,
            "label",
            true
          );
        }

        this.InsereInfoIntoNode(
          this.tree,
          this.data.nodes[index].id,
          event.templateId,
          "templateId",
          true
        );

        this.removeStepFromValidationArr(this.data.nodes[index].id);
        this.removeStepFromValidationArr(this.data.nodes[index].id);
        this.invalidateJourneySteps(this.treeTest, this.data.nodes[index].id);
        this.insertCurrentStepOnChange();
      }
      this.$forceUpdate();
    },

    inserirIntervalo(event) {
      this.configureAwaitDialog = false;
      if (event) {
        const index = this.data.nodes.findIndex(
          (x) => x.id === this.clickedNodeId
        );
        const LABEL_INFORMATION = `${event.awaitValue} ${event.period.text}`;

        if (!this.data.nodes[index].wasRenamed) {
          this.data.nodes[index].label = LABEL_INFORMATION;
          this.data.nodes[index].stepLabel = LABEL_INFORMATION;
        }

        this.InsereInfoIntoNode(
          this.tree,
          this.data.nodes[index].id,
          this.data.nodes[index].label,
          "label",
          true
        );

        this.InsereInfoIntoNode(
          this.tree,
          this.data.nodes[index].id,
          LABEL_INFORMATION,
          "await",
          true
        );

        this.removeStepFromValidationArr(this.data.nodes[index].id);
        this.invalidateJourneySteps(this.treeTest, this.data.nodes[index].id);
        //  this.alterContent();
        this.insertCurrentStepOnChange();
      }
    },

    openConfirmationLeavePageModal(goToRoute) {
      this.navigateToRoute = goToRoute;
      this.cofirmationModalDialog = true;
    },

    closeConfirmationLeavePage() {
      // PRECISA VER QUAIS VAO SER OS COMPARATIVOS PARA SALVAR CORRETAMENTE
      this.isSaved = true;
      // VERIFICAÇÃO PARA VOLTAR PARA O HOME
      // POIS CASO A ROTA ANTERIOR FOR A ROTA DE EDIÇÃO
      // O ROUTER VAI SER DIRECIONADO PARA ELA
      const name =
        this.navigateToRoute.name == "editAudiencePage"
          ? "homePageIndex"
          : this.navigateToRoute.name;
      this.$router.push({ name });
    },

    closeStartJourneyModal() {
      this.currentDiamondOrChildDiamondClicked = false;
      this.startJourneyDialog = false;
    },

    // --------------- FIM FECHAMENDO DE MODAIS -----------------------------
  },
  beforeRouteLeave(to, from, next) {
    if (
      this.isEditingJourney &&
      this.hasSomeUpdate &&
      !this.isSaved &&
      !this.isEditingSomeAudience
    ) {
      next(false);
      this.openConfirmationLeavePageModal(to);
    } else {
      next();
    }
  },
};
