var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal" }, [
    _c("div", { staticClass: "modal__header" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "modal__header__right" },
        [
          _c("z-btn", {
            attrs: {
              icon: "mdi-plus",
              primary: "",
              text: "Novo filtro",
              small: "",
            },
            on: { click: _vm.newFilter },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "modal__body" }, [
      _c(
        "div",
        { staticClass: "modal__body__select mb-3" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "12", md: "6", lg: "6" } },
                [
                  _c("z-select", {
                    attrs: {
                      label: "Selecione uma Audiência Filtrada",
                      items: _vm.audiences,
                      hideDetails: true,
                      "item-text": "name",
                      "item-value": "id",
                      "return-object": "",
                    },
                    model: {
                      value: _vm.selectedFilteredAudience,
                      callback: function ($$v) {
                        _vm.selectedFilteredAudience = $$v
                      },
                      expression: "selectedFilteredAudience",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.selectedFilteredAudience
        ? _c("div", { staticClass: "modal__body__data" })
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "modal--actions" },
      [
        _c("z-btn", {
          attrs: { text: "Cancelar" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        }),
        _c("z-btn", {
          attrs: { primary: "", text: "confirmar" },
          on: {
            click: function ($event) {
              return _vm.$emit("close", _vm.selectedFilteredAudience)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal__header__left" }, [
      _c("span", [_vm._v("Audiência filtrada")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }