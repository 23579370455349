var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal" }, [
    _vm._m(0),
    _c("div", { staticClass: "modal__body" }, [
      _c(
        "div",
        { staticClass: "modal__body__select mb-3" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  staticClass: "mx-2",
                  staticStyle: { width: "100%" },
                  attrs: { "lazy-validation": "" },
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "6", lg: "6" } },
                    [
                      _c("z-input", {
                        ref: "inputA",
                        staticStyle: { "margin-bottom": "10px" },
                        attrs: {
                          rules: [
                            (v) => !!v || "Campo obrigatório",
                            (v) =>
                              +v + +_vm.percentageB === 100 ||
                              "A soma dos campos tem que ser igual a 100%",
                          ],
                          type: "number",
                          min: 0,
                          max: 101,
                          label: "Selecione a % A",
                        },
                        model: {
                          value: _vm.percentageA,
                          callback: function ($$v) {
                            _vm.percentageA = _vm._n($$v)
                          },
                          expression: "percentageA",
                        },
                      }),
                      _c("z-input", {
                        ref: "inputB",
                        attrs: {
                          rules: [
                            (v) => !!v || "Campo obrigatório",
                            (v) =>
                              +v + +_vm.percentageA === 100 ||
                              "A soma dos campos tem que ser igual a 100%",
                          ],
                          type: "number",
                          min: 0,
                          max: 100,
                          label: "Selecione a % B",
                        },
                        model: {
                          value: _vm.percentageB,
                          callback: function ($$v) {
                            _vm.percentageB = _vm._n($$v)
                          },
                          expression: "percentageB",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "modal--actions" },
      [
        _c("z-btn", {
          attrs: { text: "Cancelar" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        }),
        _c("z-btn", {
          attrs: { primary: "", text: "confirmar" },
          on: { click: _vm.closeModal },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal__header" }, [
      _c("div", { staticClass: "modal__header__left" }, [
        _c("span", [_vm._v("Filtro de Teste A/B")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }