var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal" }, [
    _c("div", { staticClass: "modal__right" }, [
      _c("div", { staticClass: "modal__right__header" }, [
        _c("div", { staticClass: "modal__right__header__title" }, [
          _c("span", [_vm._v(_vm._s(_vm.pageTitles[_vm.currentPage]) + " ")]),
        ]),
        _c(
          "div",
          { staticClass: "modal__right__header--actions" },
          [
            _c("v-icon", { staticClass: "mr-1" }, [
              _vm._v(" $info_question_mark "),
            ]),
            _c(
              "v-icon",
              {
                on: {
                  click: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              },
              [_vm._v("mdi-close")]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "modal__right__body" }, [
        _c(
          "div",
          { staticClass: "audiences" },
          _vm._l(
            _vm.havePermissions(_vm.audiences, _vm.isAudienceProp),
            function (item, i) {
              return _c("ActionItemButton", {
                key: i,
                staticClass: "audiences__item",
                attrs: { "data-prop": item },
                on: { clicked: _vm.emitClick },
              })
            }
          ),
          1
        ),
        _c(
          "div",
          { staticClass: "messages" },
          _vm._l(_vm.messages, function (item, i) {
            return _c("ActionItemButton", {
              key: i,
              staticClass: "messages__item",
              attrs: { "data-prop": item },
              on: { clicked: _vm.emitClick },
            })
          }),
          1
        ),
        _c(
          "div",
          { staticClass: "tests" },
          _vm._l(
            _vm.havePermissions(_vm.tests, _vm.fatherDiamondClicked),
            function (item, i) {
              return _c("ActionItemButton", {
                key: i,
                staticClass: "tests__item",
                attrs: { "data-prop": item },
                on: { clicked: _vm.emitClick },
              })
            }
          ),
          1
        ),
        _c(
          "div",
          { staticClass: "medias" },
          _vm._l(_vm.medias, function (item, i) {
            return _c("ActionItemButton", {
              key: i,
              staticClass: "medias__item",
              attrs: { "data-prop": item },
              on: { clicked: _vm.emitClick },
            })
          }),
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }