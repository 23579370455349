<template>
  <div class="modal">
    <div class="modal__header">
      <div class="modal__header__left">
        <span>Selecionar template de WhatsApp </span>
      </div>

      <div class="modal__header__right">
        <z-input
          v-model="searchTemplate"
          label="Pesquisar"
          placeholder="Pesquisar"
          prependIcon="$search_rounded"
          type="text"
          :hide-details="true"
        />
      </div>
    </div>

    <div class="modal__body">
      <template v-if="isLoaded">
        <div v-if="hasSomeInformation" class="modal__body__favorites">
          <div class="title">
            <figure>
              <img src="/icons/favorite-icon.svg" alt="Favoritos" />
            </figure>
            <span>Favoritos</span>
          </div>

          <div class="list">
            <div
              v-for="(item, i) in favoritesTemplates"
              :key="i"
              class="list__item"
              :class="{ active: item?.active }"
              @click="selectTemplate(item, i)"
            >
              <div class="list__item__header">
                <div class="title">
                  <span>{{ item?.templateName }}</span>
                </div>
                <div class="action">
                  <figure>
                    <img :style="iconStyle" src="/icons/favorite-icon.svg" />
                  </figure>
                </div>
              </div>
              <div class="list__item__body">
                <div class="left">
                  <span>{{ item.users }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="hasSomeInformation" class="modal__body__standart">
          <div class="title">
            <figure><img src="/icons/pin-icon.svg" alt="Favoritos" /></figure>
            <span>Todos</span>
          </div>

          <div class="list">
            <div
              v-for="(item, i) in searchTemplate ? filtered : templatesList"
              :key="i"
              class="list__item"
              :class="{ active: item?.active }"
              @click="selectTemplate(item, i)"
            >
              <div class="list__item__header">
                <div class="title">
                  <span>{{ item?.templateName }}</span>
                </div>
                <div class="action">
                  <figure>
                    <img
                      :style="iconStyle"
                      :src="
                        item.favorite
                          ? '/icons/favorite-icon.svg'
                          : '/icons/favorite-not-filled-icon.svg'
                      "
                    />
                  </figure>
                </div>
              </div>
              <div class="list__item__body">
                <div class="left">
                  <span>{{ item.users }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!hasSomeInformation" class="modal__body__no-data">
          <strong>Não foi encontrado nenhum template correspondente a busca.</strong>
        </div>
      </template>
      <template v-else>
        <div class="modal__loading">
          <z-loading />
        </div>
      </template>
    </div>

    <div class="modal--actions">
      <z-btn text="Cancelar" @click="$emit('close')"></z-btn>

      <z-btn
        primary
        :disabled="!this.selectedTemplate"
        @click="insertPushTemplate"
        text="Selecionar template"
      ></z-btn>
    </div>
  </div>
</template>

<script>
import ApiClient from "@/services/ApiClient";
export default {
  props: {
    typeTemplate: { type: String, default: "whatsapp" },
    databaseConfigId: { type: String, default: "" },
    nodeProp: { type: Object, default: null },
  },
  data() {
    return {
      isLoaded: true,
      filtered: [],
      searchTemplate: "",
      iconStyle: {
        width: "20px",
        height: "20px",
        cursor: "pointer",
      },
      selectedTemplate: null,
      http: new ApiClient(),
      templatesList: [],
      favoriteTemplates: [],
      step: 0,
      mailSender: null,
      mailSubject: null,
      canShow: false,
    };
  },
  async created() {
    await this.getTemplates();
    this.selectTemplateProp(this.nodeProp);
  },
  watch: {
    searchTemplate(nv) {
      this.filtered = this.templatesList.filter((d) =>
        d.templateName.toLowerCase().includes(nv.toLowerCase())
      );
    },
  },
  computed: {
    favoritesTemplates() {
      let ITEMS = [];

      if (this.searchTemplate) {
        ITEMS = this.templatesList
          .filter((d) => d.favorite == true)
          .filter((h) =>
            h.templateName.toLowerCase().includes(this.searchTemplate.toLocaleLowerCase())
          );
      } else {
        ITEMS = this.templatesList.filter((d) => d.favorite == true);
      }
      return ITEMS;
    },
    hasSomeInformation() {
      let INFORMATION = false;

      if (this.searchTemplate) {
        INFORMATION = this.filtered.length > 0 || this.favoritesTemplates.length > 0;
      } else {
        INFORMATION = this.templatesList.length > 0 || this.favoritesTemplates.length > 0;
      }

      return INFORMATION;
    },
  },
  methods: {
    async getTemplates() {
      try {
        this.isLoaded = false;
        const req = await this.http.get(
          `templatesConfiguration/list?databaseConfigId=${this.databaseConfigId}`
        );

        this.templatesList = req
          .map((x) => {
            x.active = false;
            return x;
          })
          .filter((d) => d.templateType === this.typeTemplate);

        // this.templatesList = this.templatesList.filter(
        //   (x) => x.templateType === this.typeTemplate
        // );

        this.isLoaded = true;
      } catch (error) {
        this.$toast.error("Erro ao buscar templates");
        this.isLoaded = true;
      }
    },

    insertPushTemplate() {
      this.$emit("close", {
        templateId: this.selectedTemplate.id,
        templateName: this.selectedTemplate.templateName,
        // mailSender: this.mailSender,
        // mailSubject: this.mailSubject,
      });
    },

    selectTemplate(item) {
      this.templatesList.forEach((item) => (item.active = false));
      item.active = !item.active;
      this.selectedTemplate = item;
      this.$forceUpdate();
    },

    selectTemplateProp(data) {
      this.templatesList.map((item) => (item.active = false));
      const FOUND = this.templatesList.findIndex(
        (d) => d.templateName === data.label || d.id === data.templateId
      );

      if (FOUND >= 0) {
        this.templatesList[FOUND].active = !this.templatesList[FOUND].active;
        this.selectedTemplate = this.templatesList[FOUND];
      }
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.modal {
  background: #ffffff;
  border-radius: 15px;
  color: $z-black-1;
  padding: $z-s-1;
  min-height: 89.5vh;
  max-height: 89.5vh;
  display: flex;
  flex-direction: column;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    &__left {
      span {
        font-weight: 700;
        font-size: 1.1rem;
        line-height: 15px;
      }
    }
    &__right {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &__body {
    overflow: hidden;
    flex: 1 1 100%;
    margin: $z-s-1 0;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: $z-s-1 $z-s-3;

    & > div {
      flex: 1 1 400px;
      .title {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: 0.5rem;
        padding: 0.5rem;

        figure {
          width: 18px;
          height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        span {
          font-weight: 300;
          font-size: 20px;
          line-height: 23px;
        }
      }

      .list {
        padding: 0.5rem;
        max-height: 62vh;
        height: 100%;
        overflow-y: auto;
        @include trackScrollBar;
        &__item {
          cursor: pointer;
          padding: 0.8rem;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
          border-radius: 10px;
          &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .title {
              span {
                font-weight: 700;
                font-size: 0.8rem;
                line-height: 15px;
                letter-spacing: 0.15px;
                text-transform: uppercase;
              }
            }
            .action {
              figure {
                width: 20px;
                height: 20px;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }
            }
          }
          &__body {
            display: flex;
            align-items: flex-start;
            gap: $z-s-1 $z-s-2;
          }
          &:not(:last-child) {
            margin-bottom: $z-s-1;
          }
        }
        .active {
          background: #0273c3;
          color: white;
        }
      }
    }

    &__no-data {
      height: 60vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .email-template {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    @include trackScrollBar;
  }

  &__loading {
    flex: 1 1 100%;
    height: 100% impora !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &--actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
  }
}
</style>
