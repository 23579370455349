<template>
  <div class="modal">
    <div class="modal__header">
      <div class="modal__header__left">
        <span>Escolha de step: </span>
        <br />
        <small>Escolha abaixo, qual step deseja criar para fazer a união</small>
      </div>
      <div class="modal__header__right"></div>
    </div>
    <div class="modal__body">
      <div class="modal__body__list">
        <template v-for="(item, i) in stepsType">
          <ActionItemButton
            class="modal__body__list__item"
            :class="{ active: currentSelect == item.name }"
            :data-prop="item"
            :key="i"
            @clicked="setSelectItem"
          />
        </template>
      </div>
    </div>

    <div class="modal--actions">
      <z-btn small text="Cancelar" @click="$emit('close')"></z-btn>
      <z-btn small primary text="Selecionar" @click="select"></z-btn>
    </div>
  </div>
</template>

<script>
import ActionItemButton from "./actionItemButton.vue";
export default {
  data() {
    return {
      currentSelect: "",
      choosen: "",
      name: "",
      stepsType: [
        {
          name: "Enviar e-mail",
          imgPath: "/icons/send-email-icon.svg",
        },
        {
          name: "Enviar SMS",
          imgPath: "/icons/send-sms-icon.svg",
        },
        {
          name: "Aguardar",
          imgPath: "/icons/wait-icon.svg",
          isToShow: true,
        },
      ],
    };
  },
  props: {
    nodeProp: { type: Object, default: null },
  },
  components: {
    ActionItemButton,
  },
  methods: {
    select() {
      this.$emit("select", this.currentSelect);
    },
    setSelectItem(e) {
      this.currentSelect = e.name;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.modal {
  background: #ffffff;
  border-radius: 15px;
  color: $z-black-1;
  padding: $z-s-1;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    &__left {
      span {
        font-weight: 700;
        font-size: 1.1rem;
        line-height: 15px;
      }
    }
    &__right {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &__body {
    flex: 1 1 100%;
    height: 100%;
    margin: $z-s-1 0;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: $z-s-1 $z-s-3;

    &__list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: $z-s-1 0;

      &__item {
        border: 2px solid transparent;
      }
      .active {
        border: 2px dashed #0273c3;
      }
    }

    & > div {
      flex: 1 1 400px;
      .title {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: 0.5rem;
        padding: 0.5rem;

        span {
          font-weight: 300;
          font-size: 20px;
          line-height: 23px;
        }
      }

      .list {
        padding: 0.5rem;
        max-height: 62vh;
        height: 100%;
        overflow-y: auto;
        @include trackScrollBar;
        &__item {
          cursor: pointer;
          padding: 0.8rem;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
          border-radius: 10px;
          // border: 5px solid;
          &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .title {
              span {
                font-weight: 700;
                font-size: 0.8rem;
                line-height: 15px;
                letter-spacing: 0.15px;
                text-transform: uppercase;
              }
            }
            .action {
              figure {
                width: 20px;
                height: 20px;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }
            }
          }
          &__body {
            display: flex;
            align-items: flex-start;
            gap: $z-s-1 $z-s-2;
          }
          &:not(:last-child) {
            margin-bottom: $z-s-1;
          }
        }
        .active {
          background: #0273c3;
          color: white;
        }
      }
    }

    &__no-data {
      height: 60vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__loading {
    flex: 1 1 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
  }
}
</style>
