<template>
  <div class="modal">
    <div class="modal__header">
      <div class="modal__header__left">
        <span>Renomear step </span>
        <br />
        <small>Insira o novo nome no campo abaixo</small>
      </div>
      <div class="modal__header__right"></div>
    </div>
    <div class="modal__body">
      <z-input
        v-model="name"
        :hideDetails="true"
        placeholder="Nome"
        type="text"
        style="width: 100%"
      />
    </div>

    <div class="modal--actions">
      <z-btn text="Cancelar" @click="$emit('close')"></z-btn>
      <z-btn primary text="Renomear" @click="rename"></z-btn>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
    };
  },
  props: {
    nodeProp: { type: Object, default: null },
  },
  methods: {
    rename() {
      this.$emit("rename", this.name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.modal {
  background: #ffffff;
  border-radius: 15px;
  color: $z-black-1;
  padding: $z-s-1;
  //   min-height: 89.5vh;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    &__left {
      span {
        font-weight: 700;
        font-size: 1.1rem;
        line-height: 15px;
      }
    }
    &__right {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &__body {
    flex: 1 1 100%;
    height: 100%;
    margin: $z-s-1 0;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: $z-s-1 $z-s-3;

    & > div {
      flex: 1 1 400px;
      .title {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: 0.5rem;
        padding: 0.5rem;

        span {
          font-weight: 300;
          font-size: 20px;
          line-height: 23px;
        }
      }

      .list {
        padding: 0.5rem;
        max-height: 62vh;
        height: 100%;
        overflow-y: auto;
        @include trackScrollBar;
        &__item {
          cursor: pointer;
          padding: 0.8rem;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
          border-radius: 10px;
          // border: 5px solid;
          &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .title {
              span {
                font-weight: 700;
                font-size: 0.8rem;
                line-height: 15px;
                letter-spacing: 0.15px;
                text-transform: uppercase;
              }
            }
            .action {
              figure {
                width: 20px;
                height: 20px;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }
            }
          }
          &__body {
            display: flex;
            align-items: flex-start;
            gap: $z-s-1 $z-s-2;
          }
          &:not(:last-child) {
            margin-bottom: $z-s-1;
          }
        }
        .active {
          background: #0273c3;
          color: white;
        }
      }
    }

    &__no-data {
      height: 60vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__loading {
    flex: 1 1 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
  }
}
</style>
