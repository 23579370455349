var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal" }, [
    _vm._m(0),
    _c("div", { staticClass: "body" }, [
      _c(
        "div",
        { staticClass: "body__top" },
        [
          _c("z-select", {
            attrs: { label: "Dias", items: [], hideDetails: true },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "body__bottom" }, [
        _c(
          "div",
          [_c("z-input", { attrs: { label: "0 dias", hideDetails: true } })],
          1
        ),
        _vm._m(1),
        _c(
          "div",
          [_c("z-input", { attrs: { label: "0 dias", hideDetails: true } })],
          1
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "modal--actions" },
      [
        _c("z-btn", { attrs: { large: "", text: "Cancelar" } }),
        _c("z-btn", { attrs: { large: "", primary: "", text: "Incluir" } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "header__title" }, [
        _c("span", [_vm._v("Aguardar até uma data")]),
      ]),
      _c("div", { staticClass: "header__description" }, [
        _c("span", [
          _vm._v(
            " Selecione uma período específico para aguardar o período terminar: "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("span", [_vm._v(" a ")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }