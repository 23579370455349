var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal" }, [
    _c("div", { staticClass: "modal__header" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "modal__header__right" },
        [
          _c("z-input", {
            attrs: {
              label: "Pesquisar",
              placeholder: "Pesquisar",
              prependIcon: "$search_rounded",
              type: "text",
              "hide-details": true,
            },
            model: {
              value: _vm.searchTemplate,
              callback: function ($$v) {
                _vm.searchTemplate = $$v
              },
              expression: "searchTemplate",
            },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "modal__body" },
      [
        _vm.isLoaded
          ? [
              _vm.hasSomeInformation
                ? _c("div", { staticClass: "modal__body__favorites" }, [
                    _vm._m(1),
                    _c(
                      "div",
                      { staticClass: "list" },
                      _vm._l(_vm.favoritesTemplates, function (item, i) {
                        return _c(
                          "div",
                          {
                            key: i,
                            staticClass: "list__item",
                            class: { active: item?.active },
                            on: {
                              click: function ($event) {
                                return _vm.selectTemplate(item, i)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "list__item__header" }, [
                              _c("div", { staticClass: "title" }, [
                                _c("span", [
                                  _vm._v(_vm._s(item?.templateName)),
                                ]),
                              ]),
                              _c("div", { staticClass: "action" }, [
                                _c("figure", [
                                  _c("img", {
                                    style: _vm.iconStyle,
                                    attrs: { src: "/icons/favorite-icon.svg" },
                                  }),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "list__item__body" }, [
                              _c("div", { staticClass: "left" }, [
                                _c("span", [_vm._v(_vm._s(item.users))]),
                              ]),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
              _vm.hasSomeInformation
                ? _c("div", { staticClass: "modal__body__standart" }, [
                    _vm._m(2),
                    _c(
                      "div",
                      { staticClass: "list" },
                      _vm._l(
                        _vm.searchTemplate ? _vm.filtered : _vm.templatesList,
                        function (item, i) {
                          return _c(
                            "div",
                            {
                              key: i,
                              staticClass: "list__item",
                              class: { active: item?.active },
                              on: {
                                click: function ($event) {
                                  return _vm.selectTemplate(item, i)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "list__item__header" }, [
                                _c("div", { staticClass: "title" }, [
                                  _c("span", [
                                    _vm._v(_vm._s(item?.templateName)),
                                  ]),
                                ]),
                                _c("div", { staticClass: "action" }, [
                                  _c("figure", [
                                    _c("img", {
                                      style: _vm.iconStyle,
                                      attrs: {
                                        src: item.favorite
                                          ? "/icons/favorite-icon.svg"
                                          : "/icons/favorite-not-filled-icon.svg",
                                      },
                                    }),
                                  ]),
                                ]),
                              ]),
                              _c("div", { staticClass: "list__item__body" }, [
                                _c("div", { staticClass: "left" }, [
                                  _c("span", [_vm._v(_vm._s(item.users))]),
                                ]),
                              ]),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ])
                : _vm._e(),
              !_vm.hasSomeInformation
                ? _c("div", { staticClass: "modal__body__no-data" }, [
                    _c("strong", [
                      _vm._v(
                        "Não foi encontrado nenhum template correspondente a busca."
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]
          : [
              _c(
                "div",
                { staticClass: "modal__loading" },
                [_c("z-loading")],
                1
              ),
            ],
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "modal--actions" },
      [
        _c("z-btn", {
          attrs: { text: "Cancelar" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        }),
        _c("z-btn", {
          attrs: {
            primary: "",
            disabled: !this.selectedTemplate,
            text: "Selecionar template",
          },
          on: { click: _vm.insertPushTemplate },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal__header__left" }, [
      _c("span", [_vm._v("Selecionar template de WhatsApp ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("figure", [
        _c("img", {
          attrs: { src: "/icons/favorite-icon.svg", alt: "Favoritos" },
        }),
      ]),
      _c("span", [_vm._v("Favoritos")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("figure", [
        _c("img", { attrs: { src: "/icons/pin-icon.svg", alt: "Favoritos" } }),
      ]),
      _c("span", [_vm._v("Todos")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }