var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "action-item",
      on: {
        click: function ($event) {
          return _vm.$emit("clicked", _vm.dataProp)
        },
      },
    },
    [
      _c("figure", [
        _c("img", {
          attrs: { src: _vm.dataProp.imgPath, alt: _vm.dataProp.name },
        }),
      ]),
      _c("article", [
        _c("strong", [_vm._v(" " + _vm._s(_vm.dataProp.name) + " ")]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }