<template>
  <div class="modal">
    <div class="modal__header">
      <div class="modal__header__title">
        <span>Configuração de jornada </span>
        <br />
      </div>
      <div class="action">
        <v-icon @click="$emit('close')">mdi-close</v-icon>
      </div>
    </div>

    <v-form ref="form" v-if="!loading">
      <div class="modal__body">
        <div class="content">
          <div class="content__timer-type mb-5">
            <div class="repeat-each mb-0">
              <strong class="mb-2"> Tipo: </strong>
            </div>
            <!-- ESCOLHA DE PONTUAL OU RECORRENTE -->
            <v-radio-group
              :rules="[(v) => !!v || 'Campo obrigatório']"
              v-model="timerType"
              row
              hide-details
              small
            >
              <v-radio style="width: 150px" value="punctual" small>
                <template #label>
                  <span style="color: #000000">Pontual</span>
                </template>
              </v-radio>
              <v-radio style="width: 150px" value="recurring">
                <template #label>
                  <span style="color: #000000">Recorrente</span>
                </template>
              </v-radio>
            </v-radio-group>
          </div>

          <!-- SEÇÃO PONTUAL -->

          <section
            v-if="timerType == 'punctual'"
            class="content__punctual-info mt-3"
          >
            <v-radio-group
              :rules="[(v) => !!v || 'Campo obrigatório']"
              v-model="startType"
              hide-details
              @change="executeTime = ''"
            >
              <v-radio style="width: 150px" value="immediate">
                <template #label>
                  <span style="color: #000000">Imediato</span>
                </template>
              </v-radio>
              <v-radio value="date">
                <template #label>
                  <span style="color: #000000" class="mr-5">Agendamento </span>
                </template>
              </v-radio>
            </v-radio-group>

            <div v-if="startType == 'date'">
              <v-row dense>
                <v-col cols="12" sm="6" md="6" lg="6">
                  <z-input-date
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    v-model="punctualTimerType"
                    class="mr-1"
                    label=""
                    small
                    format="pt-BR"
                    type="date"
                    :min="minimumStartDate"
                    :hideDetails="true"
                    :disabled="startType != 'date'"
                    @input="isSchedulingOk = true"
                  />
                </v-col>

                <v-col cols="12" sm="3" md="3" lg="3">
                  <z-select
                    small
                    v-model="hourInfo"
                    :items="
                      actualSelectedDateIsToday ? avaliableHours : hoursArr
                    "
                    label="Hora"
                    :hideDetails="true"
                    hint="asdfasdf"
                    :readonly="!punctualTimerType"
                /></v-col>

                <v-col cols="12" sm="3" md="3" lg="3">
                  <z-select
                    small
                    v-model="minuteInfo"
                    :items="
                      actualSelectedDateIsToday ? avaliableMinutes : minutesArr
                    "
                    label="Minuto"
                    :hideDetails="true"
                    :readonly="!punctualTimerType"
                /></v-col>

                <v-col
                  v-if="actualSelectedDateIsToday && hourInfo && minuteInfo"
                  cols="12"
                  class="mt-3"
                >
                  <small class="d-flex"
                    >*Agendado previamente para:
                    <strong class="ml-2">{{
                      formatDate(punctualTimerType)
                    }}</strong>
                    <strong class="ml-2"
                      >{{ hourInfo }}:{{ minuteInfo }}</strong
                    >
                  </small>
                </v-col>
                <!-- <v-col cols="12" sm="6" md="6" lg="6">
                  <z-input
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    v-model="executeTime"
                    class="ml-2"
                    small
                    type="time"
                    clearable
                    clear-icon="mdi-close-thick"
                    :hideDetails="true"
                  />
                </v-col> -->
              </v-row>
            </div>
          </section>

          <!-- SEÇÃO RECORRENTE -->
          <section
            v-if="timerType == 'recurring'"
            class="content__recurring-info"
          >
            <div class="start mb-5">
              <strong class="mb-0"> Início</strong>

              <v-radio-group
                inline
                :rules="[(v) => !!v || 'Campo obrigatório']"
                v-model="startType"
                hide-details
                @change="startType != 'date' ? (punctualTimerType = '') : ''"
              >
                <v-row>
                  <v-radio
                    style="width: 150px; margin-top: 10px"
                    value="immediate"
                  >
                    <template #label>
                      <span style="color: #000000">Imediato</span>
                    </template>
                  </v-radio>
                  <v-radio value="date">
                    <template #label>
                      <v-col cols="9">
                        <z-input-date
                          :rules="
                            startType === 'date'
                              ? [(v) => !!v || 'Campo obrigatório']
                              : []
                          "
                          v-model="punctualTimerType"
                          class="mr-1"
                          label=""
                          format="pt-BR"
                          type="date"
                          :hideDetails="true"
                          :disabled="startType != 'date'"
                        />
                      </v-col>
                    </template>
                  </v-radio>
                </v-row>
              </v-radio-group>

              <div v-if="startType == 'date'">
                <v-row dense> </v-row>
              </div>
            </div>

            <div class="end mb-5">
              <strong class="mb-0"> Fim </strong>

              <v-radio-group
                inline
                v-model="endType"
                hide-details
                :rules="[(v) => !!v || 'Campo obrigatório']"
                @change="punctualTimerTypeEnd = ''"
              >
                <v-row>
                  <v-radio
                    style="width: 150px; margin-top: 10px"
                    value="undefined"
                  >
                    <template #label>
                      <span style="color: #000000">Indefinido </span>
                    </template>
                  </v-radio>
                  <v-radio value="date">
                    <template #label>
                      <v-col cols="9">
                        <z-input-date
                          :rules="
                            endType === 'date'
                              ? [(v) => !!v || 'Campo obrigatório']
                              : []
                          "
                          :min="minimumDate"
                          v-model="punctualTimerTypeEnd"
                          label=""
                          format="pt-BR"
                          :hideDetails="true"
                          :disabled="endType != 'date'"
                        />
                      </v-col>
                    </template>
                  </v-radio>
                </v-row>
              </v-radio-group>
            </div>

            <div class="repeat-each mb-0">
              <strong class="mb-2"> Repetir a cada: </strong>
            </div>

            <div class="repeat-each mb-5">
              <v-row dense>
                <v-col cols="12" sm="12" md="6" lg="3">
                  <z-input
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    v-model="every"
                    itemText="text"
                    itemValue="value"
                    type="number"
                    :hide-details="true"
                    class="custom_inpt"
                    ref="inptRef"
                  ></z-input>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="4">
                  <z-select
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    v-model="repeatItem"
                    :items="repeatItems"
                    itemText="text"
                    itemValue="value"
                    :hide-details="true"
                    value-position="center"
                    ref="selectRef"
                  ></z-select>
                </v-col>
              </v-row>
            </div>

            <div
              v-if="repeatItem == 'weekly' || repeatItem == 'monthly'"
              class="repeat mb-5"
            >
              <strong class="mb-2"> Repetir: </strong>

              <v-btn-toggle
                v-if="repeatItem == 'weekly'"
                v-model="toggle_week"
                multiple
                dense
                color="primary"
                :rules="[(v) => !!v || 'Campo obrigatório']"
              >
                <v-btn fab rounded depressed x-small class="mr-1"> D </v-btn>
                <v-btn fab rounded depressed x-small class="mr-1"> S </v-btn>
                <v-btn fab rounded depressed x-small class="mr-1"> T </v-btn>
                <v-btn fab rounded depressed x-small class="mr-1"> Q </v-btn>
                <v-btn fab rounded depressed x-small class="mr-1"> Q </v-btn>
                <v-btn fab rounded depressed x-small class="mr-1"> S </v-btn>
                <v-btn fab rounded depressed x-small class="mr-1"> S </v-btn>
              </v-btn-toggle>

              <div v-if="repeatItem == 'monthly'">
                <v-row justify="space-between">
                  <v-col cols="5">
                    <z-input
                      v-if="
                        repeatItem == 'monthly'
                      "
                      :rules="[(v) => !!v || 'Campo obrigatório']"
                      v-model="toggle_month_choose"
                      itemText="text"
                      itemValue="value"
                      label="Dia"
                      max="31"
                      min="1"
                      type="number"
                      :hide-details="true"
                      class="custom_inpt mt-4"
                      ref="inptRef"
                    ></z-input>
                  </v-col>
                </v-row>
              </div>
            </div>

            <div
              class="execution mb-1"
              v-if="repeatItem !== 'minutely' && repeatItem !== 'hourly'"
            >
              <strong class="mb-2"> Horário de execução </strong>

              <!-- <z-input
                :rules="[(v) => !!v || 'Campo obrigatório']"
                v-model="executeTime"
                class="ml-2"
                type="time"
                clearable
                clear-icon="mdi-close-thick"
                :hideDetails="true"
              /> -->
              <v-row dense>
                <v-col cols="12" sm="6" md="6" lg="3">
                  <z-select
                    v-model="hourInfo"
                    :items="hoursArr"
                    label="Hora"
                    hideDetails
                /></v-col>
                <v-col cols="12" sm="6" md="6" lg="3">
                  <z-select
                    v-model="minuteInfo"
                    :items="minutesArr"
                    label="Minuto"
                    hideDetails
                /></v-col>
              </v-row>
            </div>

            <div class="content__timer-type mt-5">
              <strong class="mb-2">
                Quando um usuário já estiver na jornada
              </strong>

              <v-radio-group
                inline
                v-model="newUserJourneyEntryBehavior"
                hide-details
                :rules="[(v) => !!v || 'Campo obrigatório']"
              >
                <v-radio style="margin-top: 10px" value="createNew">
                  <template #label>
                    <span style="color: #000000">Criar novamente</span>
                  </template>
                </v-radio>
                <v-radio style="margin-top: 10px" value="noChange">
                  <template #label>
                    <span style="color: #000000"
                      >Não criar um novo e não alterar o existente</span
                    >
                  </template>
                </v-radio>
                <v-radio style="margin-top: 10px" value="backToBeginning">
                  <template #label>
                    <span style="color: #000000"
                      >Não criar um novo e mover o existente para o início</span
                    >
                  </template>
                </v-radio>
              </v-radio-group>
            </div>
          </section>

          <div class="content__timer-type mt-5">
            <strong class="mb-2"> Ao aguardar: </strong>

            <v-radio-group
              inline
              v-model="userWaitBehaviour"
              hide-details
              :rules="[(v) => !!v || 'Campo obrigatório']"
            >
              <v-radio style="margin-top: 10px" value="noChange">
                <template #label>
                  <span style="color: #000000">Manter dados do inicio</span>
                </template>
              </v-radio>
              <v-radio style="margin-top: 10px" value="updateData">
                <template #label>
                  <span style="color: #000000">Atualizar dados</span>
                </template>
              </v-radio>
            </v-radio-group>
          </div>

          <!-- SEÇÃO DUPLICIDADE -->
          <div class="content__timer-type mt-5">
            <strong class="mb-2"> Configuração de envio em duplicidade </strong>

            <div class="repeat-each mb-3">
              <v-row dense>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-tooltip top max-width="700">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on"
                        ><z-checkbox
                          v-bind="attrs"
                          v-on="on"
                          v-model="shouldSentMensageriaToExistentContact"
                          label="Não permitir o envio para o mesmo número e e-mail na mesma jornada"
                          hide-details
                      /></span>
                    </template>
                    <span
                      style="padding: 1rem; font-weight: 500; display: block"
                    >
                      Essa opcao impede que sejam enviados dois emails/sms para
                      usuarios que por acaso tenham o mesmo email ou telefone
                      cadastrado na base, caso a sua base nao tenha emails/sms
                      duplicados, isso nao e necessario e afetaria a velocidade
                      desnecessariamente
                    </span>
                  </v-tooltip>

                  <small
                    v-if="shouldSentMensageriaToExistentContact"
                    style="color: red"
                    ><strong
                      >A opção de envio em duplicidade pode afetar a velocidade
                      no envio das mensagens</strong
                    ></small
                  >
                </v-col>
              </v-row>
            </div>
          </div>

          <!-- IGNORAR OPTOUT -->
          <div class="content__timer-type mt-5">
            <strong class="mb-2"> Configuração de optout </strong>

            <div class="repeat-each mb-3">
              <v-row dense>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <z-checkbox
                    v-model="ignoreOptout"
                    label="Ignorar optout"
                    hide-details
                  />
                </v-col>
              </v-row>
            </div>
          </div>

          <div class="content__timer-type mt-5">
            <strong class="mb-2">
              Ignorar usuarios nas seguintes jornadas:</strong
            >
            <div class="repeat-each mb-3">
              <v-row dense>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <z-autocomplete
                    v-model="ignoreJourneys"
                    :items="journeys"
                    itemText="name"
                    itemValue="id"
                    multiple
                    placeholder=""
                    type="text"
                    :hideDetails="true"
                  />
                </v-col>
              </v-row>
            </div>
          </div>

          <div class="content__timer-type mt-5">
            <strong class="mb-2"> Emails para notificar na finalização:</strong>
            <div class="repeat-each mb-3">
              <v-row dense>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <z-input
                    v-model="finishNotificationEmails"
                    :hide-details="true"
                  ></z-input>
                </v-col>
              </v-row>
            </div>
          </div>

          <!-- <div class="content__timer-type mt-5">
            <strong class="mb-2">
              Ignorar usuarios nas seguintes audiências:</strong
            >
            <div class="repeat-each mb-3">
              <v-row dense>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <z-autocomplete
                    v-model="ignoreAudiences"
                    :items="audiences"
                    itemText="name"
                    itemValue="id"
                    multiple
                    placeholder=""
                    type="text"
                    :hideDetails="true"
                  />
                </v-col>
              </v-row>
            </div>
          </div> -->
        </div>
      </div>

      <!-- <div class="modal__body" style="margin-top: 15px;">
        <div class="content">
          <div class="content__timer-type mb-5">
            <strong class="mb-2"> Configuração do intervalo de envio da mensageria em duplicidade </strong>

            <div class="repeat-each mb-5">
              <strong class="mb-2"> Esperar por: </strong>

              <v-row dense>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <z-input
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                    v-model="duplicateSendInterval"
                    small
                    itemText="text"
                    itemValue="value"
                    type="number"
                    :hide-details="true"
                  ></z-input>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <z-select
                    small
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    v-model="duplicateSendIntervalType"
                    :items="repeatItems2"
                    itemText="text"
                    itemValue="value"
                    :hide-details="true"
                  ></z-select>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div> -->
    </v-form>
    <div class="modal--actions mt-3" v-if="!loading">
      <z-btn small text="Cancelar" @click="close" />
      <z-btn small primary text="Concluir" @click="confirm" />
    </div>

    <div v-if="loading" style="text-align: center">
      <z-loading />
    </div>

    <z-card-modal title="Importante ⚠" persistent v-model="confirmationModal">
      <template #content>
        <div class="mt-3">
          O Agendamento é muito distante, você tem certeza que deseja
          prosseguir?
        </div>

        <div class="modal--actions mt-4 mb-3">
          <z-btn small text="Cancelar" @click="confirmationModal = false" />
          <z-btn
            small
            primary
            text="Confirmar"
            @click="
              confirmationModal = false;
              isSchedulingOk = false;
              confirm();
            "
          />
        </div>
      </template>
    </z-card-modal>
  </div>
</template>

<script>
import moment from "moment";
import ApiClient from "@/services/ApiClient";
export default {
  data() {
    return {
      confirmationModal: false,
      interval: null,
      currentTime: 0,
      loading: false,
      isEditingMode: false,
      isSchedulingOk: false,
      hourInfo: "",
      minuteInfo: "",
      hoursArr: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ],
      ignoreOptout: false,
      journeys: [],
      ignoreJourneys: [],
      audiences: [],
      ignoreAudiences: [],
      minutesArr: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
      ],
      toggle_week: "",
      startType: "",
      punctualTimerTypeEnd: "",
      endType: null,
      every: 1,
      punctualTimerType: "",
      recurringStartType: "",
      recurringEndType: "",
      timerType: "",
      repeatType: "",
      repeatItem: "weekly",
      executeTime: "",
      toggle_month: "",
      toggle_month_choose: null,
      finishNotificationEmails: "",
      duplicateSendInterval: null,
      duplicateSendIntervalType: null,
      shouldSentMensageriaToExistentContact: false,
      newUserJourneyEntryBehavior: null,
      userWaitBehaviour: "noChange",
      repeatItems: [
        {
          text: "Minuto",
          value: "minutely",
        },
        {
          text: "Hora",
          value: "hourly",
        },
        {
          text: "Dia",
          value: "daily",
        },
        {
          text: "Semana",
          value: "weekly",
        },
        {
          text: "Mês",
          value: "monthly",
        },
      ],

      repeatItems2: [
        {
          text: "Horas",
          value: "hour",
        },
        {
          text: "Dias",
          value: "daily",
        },
        {
          text: "Semanas",
          value: "weekly",
        },
      ],
      monthlyItens: [
        "Escolher um dia",
        "Dia 1",
        "Dia 5",
        "Dia 10",
        "Dia 15",
        "Dia 20",
        "Dia 25",
        "Dia 30",
      ],
      timeZones: [
        {
          text: "(GMT-3:00) - Horário de Brasília",
          value: "gmt-3",
        },
      ],
      http: new ApiClient(),
    };
  },
  async created() {
    this.loading = true;
    const reqAudiences = await this.http.get("audience/list");

    this.audiences = reqAudiences;

    const reqJourneys = await this.http.get("journey/list");

    this.journeys = reqJourneys.filter((x) => x.id !== this.$route.params.id);

    if (this.timerInfosProp != null) {
      this.shouldSentMensageriaToExistentContact = false;
      this.isEditingMode = true;
      await this.mountInformation();
      this.isEditingMode = false;
    }
    this.loading = false;
  },
  destroyed() {
    clearInterval(this.interval);
  },
  props: {
    timerInfosProp: { type: [Object, String], default: null },
  },
  watch: {
    // shouldSentMensageriaToExistentContact(nv) {
    //   if (nv)
    //     this.$toast.warning(
    //       "A opção de envio em duplicidade pode afetar a velocidade no envio das mensagens",
    //       {
    //         toastClassName: "my-custom-toast-class",
    //         bodyClassName: ["custom-class-1"],
    //         zIndex: 9999,
    //       }
    //     );
    // },
    timerType(nv) {
      if (nv) {
        if (!this.isEditingMode) {
          this.resetInfos();
        }
      }
    },
    punctualTimerType(nv) {
      const today = moment().format("YYYY-MM-DD");

      if (moment(today).isSame(nv) && this.timerType == "punctual") {
        this.startIntervalValue();
      } else {
        clearInterval(this.interval);
      }

      // lógica para validar se data de agentamento é muito distante
      // if (this.timerType == "punctual" && this.isSchedulingOk) {
      //   const today_moment = moment(today, "YYYY-MM-DD");
      //   const actual_date_moment = moment(nv, "YYYY-MM-DD");
      //   const difference = actual_date_moment.diff(moment(today_moment), "days");

      //   if (difference > 30) this.confirmationModal = true;

      //   this.isSchedulingOk = false;
      // }
    },
  },
  computed: {
    avaliableHours() {
      const currentHour = moment(this.currentTime).hour();
      const hours = [];
      for (let i = currentHour; i <= 23; i++) {
        hours.push(i.toString().padStart(2, "0"));
      }
      return hours;
    },

    avaliableMinutes() {
      const currentHour = moment(this.currentTime).hour();
      const currentMinutes = moment(this.currentTime).minute();
      const minutes = [];

      if (+this.hourInfo === +currentHour) {
        for (let i = currentMinutes; i <= 59; i++) {
          minutes.push(i.toString().padStart(2, "0"));
        }
      } else {
        for (let i = 0; i <= 59; i++) {
          minutes.push(i.toString().padStart(2, "0"));
        }
      }

      return minutes;
    },

    minimumStartDate() {
      return moment(moment.now()).format("YYYY-MM-DD");
    },

    minimumDate() {
      let MIN = "";
      if (this.punctualTimerType) MIN = this.punctualTimerType;
      else MIN = moment(moment.now()).format("YYYY-MM-DD");

      return MIN;
    },

    actualSelectedDateIsToday() {
      let info = false;
      const today = moment().format("YYYY-MM-DD");

      if (
        moment(today).isSame(this.punctualTimerType) &&
        this.timerType == "punctual"
      )
        info = true;

      return info;
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY") || "";
    },
    startIntervalValue() {
      this.interval = setInterval(() => {
        this.currentTime = moment().format("YYYY-MM-DD HH:mm");
      }, 1000);
    },
    async mountInformation() {
      const INFORMATION = { ...this.timerInfosProp };
      this.timerType = INFORMATION.timerType;

      this.shouldSentMensageriaToExistentContact =
        INFORMATION.shouldSentMensageriaToExistentContact;

      if (
        INFORMATION.timerType === "punctual" &&
        INFORMATION.startType === "immediate"
      ) {
        this.startType = INFORMATION.startType;
      } else {
        this.startType = INFORMATION.startType;
        this.punctualTimerType = INFORMATION.punctualTimerType;
      }

      if (
        INFORMATION.timerType === "recurring" &&
        INFORMATION.endType === "date"
      ) {
        this.punctualTimerTypeEnd = INFORMATION.punctualTimerTypeEnd;
        this.endType = INFORMATION.endType;
      }

      if (
        INFORMATION.timerType === "recurring" &&
        INFORMATION.endType === "undefined"
      )
        this.endType = INFORMATION.endType;

      const HOUR_MINUTE = INFORMATION.executeTime.split(":");
      this.hourInfo = HOUR_MINUTE[0];
      this.minuteInfo = HOUR_MINUTE[1];

      this.repeat = INFORMATION.repeat;
      this.repeatItem = INFORMATION.interval;
      this.every = INFORMATION.every;
      this.finishNotificationEmails = INFORMATION.finishNotificationEmails;
      this.newUserJourneyEntryBehavior =
        INFORMATION.newUserJourneyEntryBehavior;
      this.duplicateSendIntervalType = INFORMATION.duplicateSendIntervalType;
      this.ignoreOptout = INFORMATION.ignoreOptout;
      this.ignoreJourneys = INFORMATION.ignoreJourneys;
      this.ignoreAudiences = INFORMATION.ignoreAudiences;
      if (this.repeatItem == "weekly") {
        this.toggle_week = INFORMATION.intervalValues;
        this.toggle_month = INFORMATION.intervalValues;
      } else if (this.repeatItem === "monthly") {
        this.toggle_month = INFORMATION.intervalValues;
      }

      return;
    },
    close() {
      this.$emit("close");
    },

    resetInfos() {
      this.startType = "";
      this.endType = "";
      this.repeatType = "";
      this.repeatItem = "weekly";
      this.toggle_week = "";
      this.executeTime = "";
      this.punctualTimerType = "";
      this.every = 1;
      this.punctualTimerTypeEnd = "";
      this.hourInfo = "";
      this.minuteInfo = "";
      this.duplicateSendIntervalType = "";
      this.duplicateSendInterval = "";
      this.shouldSentMensageriaToExistentContact = false;
      this.newUserJourneyEntryBehavior = null;
      this.userWaitBehaviour = "noChange";
    },

    confirm() {
      if (this.$refs.form.validate()) {
        const today = moment().format("YYYY-MM-DD");

        if (this.timerType == "punctual" && this.isSchedulingOk) {
          const today_moment = moment(today, "YYYY-MM-DD");
          const actual_date_moment = moment(
            this.punctualTimerType,
            "YYYY-MM-DD"
          );
          const difference = actual_date_moment.diff(
            moment(today_moment),
            "days"
          );

          if (difference > 30) {
            this.confirmationModal = true;
            return;
          }
        }

        let INIT_VALUE = "";
        let END_VALUE = this.endType;

        if (this.timerType === "punctual" && this.startType === "immediate") {
          INIT_VALUE = this.startType;
        } else {
          INIT_VALUE = this.punctualTimerType;
        }

        if (this.timerType === "recurring" && this.endType === "date") {
          END_VALUE = this.punctualTimerTypeEnd;
        }

        this.executeTime = `${this.hourInfo}:${this.minuteInfo}`;

        const mountObj = {
          init: INIT_VALUE,
          end: END_VALUE,
          startType: this.startType,
          endType: this.endType,
          timerType: this.timerType,
          repeat: this.repeatType,
          interval: this.repeatItem,
          intervalValues: "",
          executeTime: this.executeTime,
          every: this.every,
          timezone: "gmt-3",
          punctualTimerType: this.punctualTimerType,
          punctualTimerTypeEnd: this.punctualTimerTypeEnd,
          duplicateSendInterval: this.duplicateSendInterval,
          duplicateSendIntervalType: this.duplicateSendIntervalType,
          newUserJourneyEntryBehavior: this.newUserJourneyEntryBehavior,
          userWaitBehaviour: this.userWaitBehaviour,
          shouldSentMensageriaToExistentContact:
            this.shouldSentMensageriaToExistentContact,
          ignoreOptout: this.ignoreOptout,
          ignoreJourneys: this.ignoreJourneys,
          finishNotificationEmails: this.finishNotificationEmails,
          ignoreAudiences: this.ignoreAudiences,
        };

        if (this.repeatItem == "weekly") {
          mountObj.intervalValues = this.toggle_week;
        } else if (this.repeatItem === "monthly") {
          mountObj.intervalValues =
            this.toggle_month !== "Escolher um dia"
              ? this.toggle_month
              : `Dia ${this.toggle_month_choose}`;
        }

        this.$emit("close", mountObj);
      }
    },

    test() {
      const style = window.getComputedStyle(this.$refs.selectRef.$el);
      const fontFamily = style.getPropertyValue("font-family");
      const style2 = window.getComputedStyle(this.$refs.inptRef.$el);
      const fontFamily2 = style2.getPropertyValue("font-family");
      console.log(fontFamily, "<----- FONTE DO SELECT");
      console.log(fontFamily2, "<----- FONTE DO INPUT RANGE");

      console.log(style2.getPropertyValue("font-size"), "FONT-SIZE DO INPT");
      console.log(style.getPropertyValue("font-size"), "FONT-SIZE DO SELE");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.v-select__selections {
  padding-top: 0px !important;
}

.modal {
  background: #ffffff;
  padding: $z-s-1;
  border-radius: 15px;
  color: $z-black-1;
  display: flex;
  flex-direction: column;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $z-s-1;

    &__title {
      span {
        font-weight: 600;
        text-transform: uppercase;
        line-height: 15px;
        font-size: 1rem;
      }
    }
  }

  &__body {
    color: #4e4e4e;
    flex: 1 1 100%;
    height: 100%;
    max-height: 70vh;
    overflow-y: auto;
    @include trackScrollBar;
    .content {
      strong {
        display: block;
        font-size: 0.85rem;
      }
      &__timer-type {
      }

      &__recurring-info {
        .custom_inpt {
          ::v-deep .v-text-field__slot input {
            text-align: center !important;
          }

          ::v-deep .v-select__selections input {
            text-align: center !important;
            // border: 1px solid red !important;
            padding: 0 !important;
          }

          ::v-deep .v-select__selections .v-select__selection {
            // border: 2px solid green !important;
            text-align: center !important;
            width: 100% !important;
          }
        }
        .repeat-each {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          // flex-wrap: wrap;
          @media (max-width: 650px) {
            flex-wrap: wrap;
          }
        }
      }
    }
  }

  &--actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
  }
}
</style>
