var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal" }, [
    _c("div", { staticClass: "modal__header" }, [
      _c("div", { staticClass: "modal__header__left" }, [
        _c(
          "span",
          [
            _vm._v("ATENÇÃO "),
            _c("v-icon", { attrs: { color: "red" } }, [
              _vm._v(" $warning_rounded"),
            ]),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "modal__body" }, [
      _vm._v(
        " Existe uma jornada não salva, se sair da página todas as alterações não serão salvas. "
      ),
    ]),
    _c(
      "div",
      { staticClass: "modal--actions" },
      [
        _c("z-btn", {
          attrs: { text: "Cancelar" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        }),
        _c("z-btn", {
          attrs: { primary: "", text: "Sair" },
          on: {
            click: function ($event) {
              return _vm.$emit("confirmLeave")
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }