var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal" }, [
    _vm._m(0),
    !_vm.showCredentialForm
      ? _c("div", { staticClass: "modal__body" }, [
          _c("div", [
            _c("span", [
              _vm._v(
                " Insira a audiência a ser criada no " +
                  _vm._s(_vm.isGoogleModal ? "Google" : "Facebook") +
                  ", ou selecione uma existente: "
              ),
            ]),
          ]),
          _c(
            "div",
            [
              _c("z-select", {
                staticClass: "select",
                attrs: {
                  placeholder: "Clique aqui para iniciar",
                  rules: [(v) => !!v || "Campo obrigatório"],
                  items: _vm.audiences,
                  itemText: "name",
                  itemValue: "value",
                  label: "Audiência",
                },
                model: {
                  value: _vm.collectionId,
                  callback: function ($$v) {
                    _vm.collectionId = $$v
                  },
                  expression: "collectionId",
                },
              }),
              _vm.collectionId === "create-new"
                ? _c("z-input", {
                    staticClass: "item",
                    attrs: {
                      label: "Nome",
                      rules: [(v) => !!v || "Campo obrigatório"],
                      hideDetails: true,
                    },
                    model: {
                      value: _vm.name,
                      callback: function ($$v) {
                        _vm.name = $$v
                      },
                      expression: "name",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" Mapeamento de campos adicionais: "),
          _c("div", [
            _c("table", { staticStyle: { width: "100%" } }, [
              _c("thead", [
                _c("th", { staticStyle: { "text-align": "left" } }, [
                  _vm._v(
                    " Campo " +
                      _vm._s(_vm.isGoogleModal ? "Google" : "Facebook") +
                      " "
                  ),
                ]),
                _c("th", [_vm._v("Campo Audiencia")]),
              ]),
              !_vm.isGoogleModal
                ? _c(
                    "tbody",
                    _vm._l(_vm.facebookFields, function (item, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [_vm._v(_vm._s(item))]),
                        item === "Genero"
                          ? _c(
                              "td",
                              { staticStyle: { "padding-top": "20px" } },
                              [
                                _c("z-select", {
                                  staticClass: "select mb-1",
                                  attrs: {
                                    items: _vm.databaseFields,
                                    "hide-details": "",
                                  },
                                  model: {
                                    value: _vm.fieldMap[item],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.fieldMap, item, $$v)
                                    },
                                    expression: "fieldMap[item]",
                                  },
                                }),
                                _vm.genOptions.length
                                  ? _c("z-select", {
                                      staticClass: "select mb-1",
                                      attrs: {
                                        items: _vm.genOptions,
                                        "hide-details": "",
                                      },
                                      model: {
                                        value: _vm.maleIdentifier,
                                        callback: function ($$v) {
                                          _vm.maleIdentifier = $$v
                                        },
                                        expression: "maleIdentifier",
                                      },
                                    })
                                  : _vm._e(),
                                _vm.genOptions.length
                                  ? _c("z-select", {
                                      staticClass: "select mb-1",
                                      attrs: {
                                        items: _vm.genOptions,
                                        "hide-details": "",
                                      },
                                      model: {
                                        value: _vm.femaleIdentifier,
                                        callback: function ($$v) {
                                          _vm.femaleIdentifier = $$v
                                        },
                                        expression: "femaleIdentifier",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _c(
                              "td",
                              [
                                _c("z-select", {
                                  staticClass: "select",
                                  attrs: {
                                    items: _vm.databaseFields,
                                    "hide-details": "",
                                  },
                                  model: {
                                    value: _vm.fieldMap[item],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.fieldMap, item, $$v)
                                    },
                                    expression: "fieldMap[item]",
                                  },
                                }),
                              ],
                              1
                            ),
                      ])
                    }),
                    0
                  )
                : _c(
                    "tbody",
                    _vm._l(_vm.googleFields, function (item, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [_vm._v(_vm._s(item))]),
                        _c(
                          "td",
                          [
                            _c("z-select", {
                              staticClass: "select",
                              attrs: {
                                items: _vm.databaseFields,
                                "hide-details": "",
                              },
                              model: {
                                value: _vm.fieldMap[item],
                                callback: function ($$v) {
                                  _vm.$set(_vm.fieldMap, item, $$v)
                                },
                                expression: "fieldMap[item]",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "modal--actions" },
            [
              _c("z-btn", {
                attrs: { text: "Cancelar" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              }),
              _c("z-btn", {
                attrs: { primary: "", text: "Incluir" },
                on: { click: _vm.submit },
              }),
            ],
            1
          ),
        ])
      : _vm.showCredentialForm
      ? _c("div", { staticClass: "modal__body" }, [
          _c(
            "div",
            [
              _c("span", [
                _vm._v(
                  " Insira as credenciais da sua conta de anuncios do facebook: "
                ),
              ]),
              _c("z-input", {
                staticClass: "item",
                staticStyle: { "margin-top": "15px" },
                attrs: {
                  label: "Ad Account Id",
                  rules: [(v) => !!v || "Campo obrigatório"],
                  hideDetails: true,
                },
                model: {
                  value: _vm.fbData.fb_ad_account_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.fbData, "fb_ad_account_id", $$v)
                  },
                  expression: "fbData.fb_ad_account_id",
                },
              }),
              _c("z-input", {
                staticClass: "item",
                staticStyle: { "margin-top": "15px" },
                attrs: {
                  label: "Auth Token",
                  rules: [(v) => !!v || "Campo obrigatório"],
                  hideDetails: true,
                },
                model: {
                  value: _vm.fbData.fb_auth_token,
                  callback: function ($$v) {
                    _vm.$set(_vm.fbData, "fb_auth_token", $$v)
                  },
                  expression: "fbData.fb_auth_token",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "modal--actions" },
            [
              _c("z-btn", {
                attrs: { text: "Cancelar" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              }),
              _c("z-btn", {
                attrs: { primary: "", text: "Incluir" },
                on: { click: _vm.submit },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal__header" }, [
      _c("div", { staticClass: "modal__header__left" }, [
        _c("span", [_vm._v("Coleção")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }