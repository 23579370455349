<template>
  <div class="modal">
    <div class="modal__header">
      <div class="modal__header__left">
        <span v-if="step !== 2">Selecionar template </span>
        <span v-if="step === 2"
          >Configurar os detalhes de envio do template selecionado</span
        >
      </div>
      <div v-if="step === 0" class="modal__header__right">
        <z-input
          v-model="searchTemplate"
          label="Pesquisar"
          placeholder="Pesquisar"
          prependIcon="$search_rounded"
          type="text"
          :hide-details="true"
        />
        <!-- <z-btn icon="mdi-plus" primary text="nova audiência"></z-btn> -->
      </div>
    </div>

    <div class="modal__body" v-if="step === 0">
      <template v-if="isLoaded">
        <div v-if="hasSomeInformation" class="modal__body__favorites">
          <div class="title">
            <figure>
              <img src="/icons/favorite-icon.svg" alt="Favoritos" />
            </figure>
            <span>Favoritos</span>
          </div>

          <div class="list">
            <div
              v-for="(item, i) in favoritesTemplates"
              :key="i"
              class="list__item"
              :class="{ active: item?.active }"
              @click="selectTemplate(item, i)"
            >
              <div class="list__item__header">
                <div class="title">
                  <span>{{ item?.templateName }}</span>
                </div>
                <div class="action">
                  <figure>
                    <img :style="iconStyle" src="/icons/favorite-icon.svg" />
                  </figure>
                </div>
              </div>
              <div class="list__item__body">
                <div class="left">
                  <span>{{ item.users }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="hasSomeInformation" class="modal__body__standart">
          <div class="title">
            <figure><img src="/icons/pin-icon.svg" alt="Favoritos" /></figure>
            <span>Todos</span>
          </div>

          <div class="list">
            <div
              v-for="(item, i) in searchTemplate ? filtered : templatesList"
              :key="i"
              class="list__item"
              :class="{ active: item?.active }"
              @click="selectTemplate(item, i)"
            >
              <div class="list__item__header">
                <div class="title">
                  <span>{{ item?.templateName }}</span>
                </div>
                <div class="action">
                  <figure>
                    <img
                      :style="iconStyle"
                      :src="
                        item.favorite
                          ? '/icons/favorite-icon.svg'
                          : '/icons/favorite-not-filled-icon.svg'
                      "
                    />
                  </figure>
                </div>
              </div>
              <div class="list__item__body">
                <div class="left">
                  <span>{{ item.users }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!hasSomeInformation" class="modal__body__no-data">
          <strong>Não foi encontrado nenhum template correspondente a busca.</strong>
        </div>
      </template>
      <template v-else>
        <div class="modal__loading">
          <z-loading />
        </div>
      </template>
    </div>

    <div class="modal__body email-template" v-if="step === 1">
      <section
        v-html="this.selectedTemplate.message.html"
        v-if="selectedTemplate.templateType === 'email'"
      ></section>
    </div>

    <div class="modal__body" v-if="step === 2" style="display: block">
      <v-row>
        <span>Configure os detalhes de envio dessa campanha</span>
      </v-row>
      <v-row>
        <v-col cols="6">
          <z-input
            :rules="[(v) => !!v || 'Campo obrigatório']"
            :hideDetails="true"
            v-model="mailSubject"
            placeholder="Assunto do e-mail"
            type="text"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <z-input
            :rules="[(v) => !!v || 'Campo obrigatório']"
            :hideDetails="true"
            placeholder="Remetente"
            v-model="mailSender"
            type="text"
          />
        </v-col>
      </v-row>

      <v-row>
        <z-checkbox :hideDetails="true" />
        <span class="text-header"> Permitir cancelar o recebimento do e-mail </span>
      </v-row>

      <div class="mt-5 mb-3">
        <span> Monitoramento </span>
      </div>
      <v-row>
        <div>
          <v-row>
            <z-checkbox :hideDetails="true" />
            <span class="text-header"> Monitorar cliques </span>
          </v-row>
        </div>
      </v-row>
    </div>

    <div class="modal--actions">
      <z-btn text="Cancelar" @click="$emit('close')"></z-btn>
      <!-- <z-btn text="Editar audiência"></z-btn> -->
      <z-btn
        primary
        :disabled="!this.selectedTemplate"
        v-if="step === 0 && typeTemplate !== 'sms'"
        @click="selecionarTemplate"
        text="Configurar template"
      ></z-btn>
      <z-btn
        primary
        v-if="step === 1 && typeTemplate !== 'sms'"
        @click="inserirTemplate"
        text="Selecionar template"
      ></z-btn>
      <z-btn
        primary
        :disabled="!this.selectedTemplate"
        v-if="step === 2 || typeTemplate === 'sms'"
        @click="inserirTemplate"
        text="Selecionar template"
      ></z-btn>
    </div>
  </div>
</template>

<script>
import ApiClient from "@/services/ApiClient";
// import { EmailEditor } from "vue-email-editor";
export default {
  props: {
    typeTemplate: { type: String, default: "email" },
    databaseConfigId: { type: String, default: "" },
    nodeProp: { type: Object, default: null },
  },
  components: {
    // EmailEditor,
  },
  data() {
    return {
      isLoaded: true,
      filtered: [],
      searchTemplate: "",
      iconStyle: {
        width: "20px",
        height: "20px",
        cursor: "pointer",
      },
      selectedTemplate: null,
      http: new ApiClient(),
      templatesList: [],
      favoriteTemplates: [],
      step: 0,
      mailSender: null,
      mailSubject: null,
      minHeight: "100px",
      locale: "en",
      projectId: 144592, // replace with your project id
      tools: {},
      canShow: false,
      options: {},
      showTagsModal: false,
      appearance: {
        theme: "dark",
        panels: {
          tools: {
            dock: "right",
          },
        },
      },
    };
  },
  async created() {
    await this.getTemplates();
    this.selectTemplateProp(this.nodeProp);
  },
  watch: {
    searchTemplate(nv) {
      this.filtered = this.templatesList.filter((d) =>
        d.templateName.toLowerCase().includes(nv.toLowerCase())
      );
    },
  },
  computed: {
    favoritesTemplates() {
      let ITEMS = [];

      if (this.searchTemplate) {
        ITEMS = this.templatesList
          .filter((d) => d.favorite == true)
          .filter((h) =>
            h.templateName.toLowerCase().includes(this.searchTemplate.toLocaleLowerCase())
          );
      } else {
        ITEMS = this.templatesList.filter((d) => d.favorite == true);
      }
      return ITEMS;
    },
    hasSomeInformation() {
      let INFORMATION = false;

      if (this.searchTemplate) {
        INFORMATION = this.filtered.length > 0 || this.favoritesTemplates.length > 0;
      } else {
        INFORMATION = this.templatesList.length > 0 || this.favoritesTemplates.length > 0;
      }

      return INFORMATION;
    },
  },
  methods: {
    async getTemplates() {
      try {
        this.isLoaded = false;
        const req = await this.http.get(
          `templatesConfiguration/list?databaseConfigId=${this.databaseConfigId}`
        );
        this.templatesList = req;
        this.templatesList = this.templatesList.map((x) => {
          x.active = false;
          return x;
        });
        this.templatesList = this.templatesList.filter(
          (x) => x.templateType === this.typeTemplate
        );
        // this.favoriteTemplates = this.allTemplates.filter((x) => x.favorite);
        this.isLoaded = true;
      } catch (error) {
        console.log(error);
        this.$toas.error("Erro ao buscar templates");
        this.isLoaded = true;
      }
    },
    editorReady() {
      console.log("editorReady");
    },
    configurarTemplate() {
      this.step = 2;
      this.$emit("changeSizeModal", "800");
    },
    editorLoaded() {
      // if (!this.$route.query.id)
      //   this.$refs.emailEditor.editor.loadDesign({});
      // else {
      try {
        this.$refs.emailEditor.editor.loadDesign(
          JSON.parse(this.selectedTemplate.message).design
        );
        const iframe = document.getElementsByTagName("iframe")[0];
        iframe.classList.add("unlayer_frame");
        iframe.style.minWidth = "5px !important";
      } catch (ex) {
        const iframe = document.getElementsByTagName("iframe")[0];
        iframe.classList.add("unlayer_frame");
        iframe.style.minWidth = "5px !important";
      }
      // }
    },
    inserirTemplate() {
      // if (
      //   (this.mailSubject && this.mailSender) ||
      //   this.typeTemplate === "sms"
      // ) {
      this.step = 0;
      this.$emit("close", {
        templateId: this.selectedTemplate.id,
        templateName: this.selectedTemplate.templateName,
        mailSender: this.mailSender,
        mailSubject: this.mailSubject,
      });
      // } else {
      //   this.$toast.error("Preencha os campos de Assunto e Remetente");
      // }
    },
    selectTemplate(item) {
      this.templatesList.forEach((item) => (item.active = false));
      item.active = !item.active;
      this.selectedTemplate = item;
      this.$forceUpdate();
    },
    selectTemplateProp(data) {
      this.templatesList.map((item) => (item.active = false));
      const FOUND = this.templatesList.findIndex(
        (d) => d.templateName === data.label || d.id === data.templateId
      );

      if (FOUND >= 0) {
        this.templatesList[FOUND].active = !this.templatesList[FOUND].active;
        this.selectedTemplate = this.templatesList[FOUND];
      }
      this.$forceUpdate();
      // console.log(this.templatesList, data)
    },
    async selecionarTemplate() {
      // this.$emit('close', this.selectedTemplate);
      await this.http
        .get(`templatesConfiguration/findOne/${this.selectedTemplate.id}`)
        .then((resp) => {
          this.selectedTemplate.message = JSON.parse(resp.message);
          //  console.log(this.selectedTemplate.message.html);
          this.canShow = true;
          this.showInformations = true;
        })
        .catch((err) => {
          throw err;
        });
      this.step = 1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.modal {
  background: #ffffff;
  border-radius: 15px;
  color: $z-black-1;
  padding: $z-s-1;
  min-height: 89.5vh;
  max-height: 89.5vh;
  display: flex;
  flex-direction: column;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    &__left {
      span {
        font-weight: 700;
        font-size: 1.1rem;
        line-height: 15px;
      }
    }
    &__right {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &__body {
    overflow: hidden;
    flex: 1 1 100%;
    margin: $z-s-1 0;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: $z-s-1 $z-s-3;

    & > div {
      flex: 1 1 400px;
      .title {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: 0.5rem;
        padding: 0.5rem;

        figure {
          width: 18px;
          height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        span {
          font-weight: 300;
          font-size: 20px;
          line-height: 23px;
        }
      }

      .list {
        padding: 0.5rem;
        max-height: 62vh;
        height: 100%;
        overflow-y: auto;
        @include trackScrollBar;
        &__item {
          cursor: pointer;
          padding: 0.8rem;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
          border-radius: 10px;
          // border: 5px solid;
          &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .title {
              span {
                font-weight: 700;
                font-size: 0.8rem;
                line-height: 15px;
                letter-spacing: 0.15px;
                text-transform: uppercase;
              }
            }
            .action {
              figure {
                width: 20px;
                height: 20px;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }
            }
          }
          &__body {
            display: flex;
            align-items: flex-start;
            gap: $z-s-1 $z-s-2;
          }
          &:not(:last-child) {
            margin-bottom: $z-s-1;
          }
        }
        .active {
          background: #0273c3;
          color: white;
        }
      }
    }

    &__no-data {
      height: 60vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .email-template {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    @include trackScrollBar;
  }

  &__loading {
    flex: 1 1 100%;
    height: 100% impora !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &--actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
  }
}
</style>
