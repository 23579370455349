// NODES TYPES:
// 0 = AUDIENCE
// 1 = TEMPLATE
// 2 = WAIT
// 3 = FILTERED-AUDIENCE

export const mockJourneyTest1 = {
  id: "31a8d084-9885-48e6-a42a-5b7e3d560c47",
  label: "Selecionar Audiência",
  stepLabel: "",
  type: "selectAudience",
  wasRenamed: false,
  selectedContentId: "",
  isDataSelected: false,
  formatType: "circle",
  hasNextNode: true,
  color: "#9747ff",
  icon: "mdi-calendar",
  lastNode: null,
  nextNode: {
    id: "879437c5-0296-4f8c-b6c6-da08d5360ce8",
    label: "Div. por interação",
    stepLabel: "",
    type: "mensageriaCheck",
    formatType: "diamond",
    wasRenamed: false,
    selectedContentId: "",
    hasNextNode: true,
    icon: "mdi-email",
    color: "#9747ff",
    mensageriaStepCheckType: "",
    isDataSelected: false,
    lastNode: "31a8d084-9885-48e6-a42a-5b7e3d560c47",
    YesNext: {
      id: "b4ddfe9b-1bf5-40a6-9d4a-e596e5acbccc",
      label: "Selecionar período",
      stepLabel: "",
      type: "await",
      icon: " $timer_rounded",
      formatType: "circle",
      wasRenamed: false,
      selectedContentId: "",
      hasNextNode: true,
      isDataSelected: false,
      color: "#9747ff",
      lastNode: "879437c5-0296-4f8c-b6c6-da08d5360ce8",
      nextNode: null,
      fromClick: "yes",
      YesNext: {
        id: "c22b2314-c316-49a9-bff2-56bfc518b635",
        label: "Final",
        stepLabel: "",
        type: "final",
        wasRenamed: false,
        selectedContentId: "",
        formatType: "circle",
        hasNextNode: false,
        color: "#2bae5c",
        isDataSelected: false,
        icon: "$exit_to_app_rounded",
        nextNode: null,
        lastNode: "b4ddfe9b-1bf5-40a6-9d4a-e596e5acbccc",
        fromClick: "yes",
      },
    },
    NoNext: null,
    nextNode: {
      id: "02233ba9-c41f-44d5-aacb-44252398e277",
      label: "Final",
      stepLabel: "",
      wasRenamed: false,
      type: "final",
      isDataSelected: false,
      formatType: "circle",
      hasNextNode: false,
      color: "#2bae5c",
      selectedContentId: "",
      icon: "$exit_to_app_rounded",
      nextNode: null,
      lastNode: "879437c5-0296-4f8c-b6c6-da08d5360ce8",
      fromClick: "next",
    },
    fromClick: "next",
  },
};

export const fluxoGramMock = [
  {
    auxId: "1233412341230489975",
    type: 0,
    choosenId: "0000-0000-0000-0000",
    choosenName: "Teste",
    nextId: "23455432345",
    previusId: null,
  },
  {
    auxId: "23455432345",
    type: 1,
    choosenId: "0000-0000-0000-0000",
    choosenName: "Some template",
    subject: "",
    allowCancel: false,
    monitorClicks: false,
    sender: "",
    nextId: "1234321",
    previusId: "1233412341230489975",
  },
  {
    auxId: "1234321",
    type: 2,
    choosenId: "0000-0000-0000-0000",
    choosenName: "Some timer",
    period: "days",
    from: 20,
    to: 50,
    nextId: "2345gg",
    previusId: "23455432345",
  },
  {
    auxId: "2345gg",
    type: 3,
    choosenId: "0000-0000-0000-0000",
    choosenName: "audiencia filtrada",
    choosenColumn: "alguma coluna",
    nextId: "",
    previusId: "1234321",
  },
];

export const fluxoGramMock2 = [
  {
    auxId: "1",
    type: 0,
    choosenId: "0000-0000-0000-0000",
    choosenName: "AUDIÊNCIA",
    nextId: "2",
    options: [],
    next: {
      auxId: "2",
      type: 1,
      choosenId: "0000-0000-0000-0000",
      choosenName: "TEMPLATE",
      nextId: "3",
      options: [],
      next: {
        auxId: "3",
        type: 3,
        choosenId: "0000-0000-0000-0000",
        choosenName: "AUDIÊNCIA FILTRADA 3",
        nextId: null,
        next: null,
        options: [
          {
            auxId: "3A",
            type: 0,
            choosenId: "0000-0000-0000-0000",
            choosenName: "AUDIÊNCIA (OPTION 1)",
            nextId: null,
            next: null,
          },
          {
            auxId: "3B",
            choosenId: "ASDFASDF",
            choosenName: "AUDIÊNCIA (OPTION 2)",
            type: 0,
            nextId: "3BB",
            next: {
              auxId: "3BB",
              type: 2,
              choosenId: "0000-0000-0000-0000",
              choosenName: "AGUARDAR 3B",
              nextId: null,
              next: null,
              options: [],
            },
          },
        ],
      },
    },
  },
];

export const audiences = [
  {
    name: "Audiência",
    imgPath: "/icons/purple-audience-icon.svg",
    enumName: 'audience',
    isToShow: false,
  },
  {
    name: "Audiência filtrada",
    imgPath: "/icons/orange-audience-filtered-icon.svg",
    enumName: 'audience_filtered',
    isToShow: true,
  },
  {
    name: "Divisão por interação",
    imgPath: "/icons/send-sms-icon.svg",
    enumName: 'division_by_interaction',
    isToShow: true,
  },
  {
    name: "Tags",
    imgPath: "/icons/send-sms-icon.svg",
    enumName: 'tag_user',
    isToShow: true,
  },
];

export const messages = [
  {
    name: "Enviar e-mail",
    imgPath: "/icons/send-email-icon.svg",
    enumName: 'send_email',
  },
  {
    name: "Enviar SMS",
    imgPath: "/icons/send-sms-icon.svg",
    enumName: 'send_sms',
  },
  {
    name: "Disparo via push",
    imgPath: "/icons/send-email-icon.svg",
    enumName: 'send_push',
  },
  // {
  //   name: "Enviar MSG whatsapp",
  //   imgPath: "/icons/send-message-icon.svg",
  // },
];

export const tests = [
  {
    name: "Aguardar",
    imgPath: "/icons/wait-icon.svg",
    isToShow: true,
    enumName: 'await',
  },
  {
    name: "Teste A/B",
    imgPath: "/icons/test-icon.svg",
    isToShow: true,
    enumName: 'test_a_b',
  },
  {
    name: "Unir step",
    imgPath: "/icons/merge-icon.svg",
    isToShow: false,
    enumName: 'join_step',
  },
];

export const medias = [
  {
    name: "Facebook",
    imgPath: "/icons/facebook-icon.svg",
    enumName: 'facebook',
  },
  {
    name: "WhatsApp",
    imgPath: "/icons/wpp-icon.svg",
    enumName: 'whatsapp',
  },
  {
    name: "Google",
    imgPath: "/icons/google-icon.svg",
    enumName: 'google',
  },
  // {
  //   name: "Api",
  //   imgPath: "/icons/api-icon.svg",
  // },
];
