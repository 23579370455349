var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal" }, [
    _vm.currentStepper == 0
      ? _c("div", { staticClass: "modal__step-0" }, [
          _c(
            "div",
            { staticClass: "header" },
            [
              _vm._m(0),
              _c("v-icon", { on: { click: _vm.close } }, [
                _vm._v(" mdi-close "),
              ]),
            ],
            1
          ),
          _vm._m(1),
        ])
      : _vm._e(),
    _vm.currentStepper == 1
      ? _c("div", { staticClass: "modal__step-1" }, [_vm._m(2), _vm._m(3)])
      : _vm._e(),
    _vm.currentStepper == 2
      ? _c("div", { staticClass: "modal__step-2" }, [_vm._m(4), _vm._m(5)])
      : _vm._e(),
    _vm.currentStepper == 3
      ? _c("div", { staticClass: "modal__step-3" }, [_vm._m(6)])
      : _vm._e(),
    _vm.currentStepper == 4
      ? _c("div", { staticClass: "modal__step-4" }, [_vm._m(7), _vm._m(8)])
      : _vm._e(),
    _vm.currentStepper == 5
      ? _c("div", { staticClass: "modal__step-5" }, [_vm._m(9)])
      : _vm._e(),
    _c("div", { staticClass: "modal--actions" }, [
      _c("div", { staticClass: "modal--actions__dots" }, [
        _c("div", {
          staticClass: "dot",
          class: { active: _vm.currentStepper == 0 },
        }),
        _c("div", {
          staticClass: "dot",
          class: { active: _vm.currentStepper == 1 },
        }),
        _c("div", {
          staticClass: "dot",
          class: { active: _vm.currentStepper == 2 },
        }),
        _c("div", {
          staticClass: "dot",
          class: { active: _vm.currentStepper == 3 },
        }),
        _c("div", {
          staticClass: "dot",
          class: { active: _vm.currentStepper == 4 },
        }),
        _c("div", {
          staticClass: "dot",
          class: { active: _vm.currentStepper == 5 },
        }),
      ]),
      _vm.currentStepper < 5
        ? _c(
            "div",
            { staticClass: "modal--actions__btns" },
            [
              _c("z-btn", {
                attrs: { color: "primary", text: "pular tutorial" },
                on: { click: _vm.close },
              }),
              _c("z-btn", {
                attrs: { primary: "", text: "próximo" },
                on: {
                  click: function ($event) {
                    _vm.currentStepper++
                  },
                },
              }),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "modal--actions__btns" },
            [
              _c("z-btn", {
                attrs: { primary: "", text: "ok" },
                on: { click: _vm.close },
              }),
            ],
            1
          ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header__title" }, [
      _c("span", [_vm._v(" Bem vindo a Zoox Eye Data to Act ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "body" }, [
      _c("figure", [_c("img", { attrs: { src: "/images/help_step_1.png" } })]),
      _c("span", [
        _vm._v(" Aqui você tem tudo o que precisa para impactar "),
        _c("strong", [_vm._v("quem")]),
        _vm._v(" é sua audiência, "),
        _c("strong", [_vm._v("quando")]),
        _vm._v(" precisar e "),
        _c("strong", [_vm._v("como")]),
        _vm._v(" quiser! "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("figure", [_c("img", { attrs: { src: "/images/help_step_2.png" } })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "body" }, [
      _c("div", { staticClass: "body__title" }, [
        _c("span", [_vm._v("Comece adicionando uma ação")]),
      ]),
      _c("div", { staticClass: "body__descriptions" }, [
        _c("p", [
          _c("strong", [_vm._v(" Clicar no + ")]),
          _vm._v(" para adicionar ao fluxo uma ação para "),
          _c("strong", [_vm._v("quem")]),
          _vm._v(" você quer impactar "),
          _c("strong", [
            _vm._v(" (Audiência), como (Canal) e quando (Decisão). "),
          ]),
        ]),
        _c("p", [
          _vm._v(" Já incluímos a audiência para você começar! "),
          _c("strong", [
            _vm._v(
              "Clique no card de ação no fluxo para editar as informações."
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("figure", [_c("img", { attrs: { src: "/images/help_step_3.png" } })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "body" }, [
      _c("div", { staticClass: "body__title" }, [
        _c("span", [_vm._v("Configure sua jornada")]),
      ]),
      _c("div", { staticClass: "body__descriptions" }, [
        _c("p", [
          _vm._v(
            "Você pode ter diversos temporizadores para impactar seus clientes."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "body" }, [
      _c("div", { staticClass: "body__title" }, [
        _c("span", [_vm._v("Salve sua jornada")]),
      ]),
      _c("div", { staticClass: "body__descriptions" }, [
        _c("p", [
          _vm._v(
            " Você pode salvar sua jornada como rascunho caso queira voltar e editar ela mais tarde. "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("figure", [_c("img", { attrs: { src: "/images/help_step_4.png" } })]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "body" }, [
      _c("div", { staticClass: "body__title" }, [
        _c("span", [_vm._v("Valide sua jornada e confira seu desempenho!")]),
      ]),
      _c("div", { staticClass: "body__descriptions" }, [
        _c("p", [
          _vm._v(" Ao validar sua jornada, "),
          _c("strong", [
            _vm._v(
              "você pode acessar um relatório completo do impacto da sua campanha em tempo real."
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "body" }, [
      _c("div", { staticClass: "body__title" }, [
        _c("span", [_vm._v("Se tiver dúvida, estamos aqui!")]),
      ]),
      _c("div", { staticClass: "body__descriptions" }, [
        _c("p", [
          _vm._v(
            " Continue usando nossa plataforma, e se tiver alguma dúvida, você pode sempre voltar ao tutorial quando precisar. "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }