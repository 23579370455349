var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal" }, [
    _c("div", { staticClass: "modal__header" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "modal__header__right" },
        [
          _c("z-input", {
            attrs: {
              label: "Pesquisar",
              placeholder: "Pesquisar",
              prependIcon: "$search_rounded",
              type: "text",
              "hide-details": true,
            },
            model: {
              value: _vm.searchAudience,
              callback: function ($$v) {
                _vm.searchAudience = $$v
              },
              expression: "searchAudience",
            },
          }),
          _c("z-btn", {
            attrs: { icon: "mdi-plus", primary: "", text: "nova audiência" },
            on: { click: _vm.newAudience },
          }),
        ],
        1
      ),
    ]),
    _vm.isLoaded
      ? _c("div", { staticClass: "modal__body" }, [
          _vm.hasSomeInformation
            ? _c("div", { staticClass: "modal__body__left" }, [
                _vm._m(1),
                _c(
                  "div",
                  { staticClass: "list" },
                  _vm._l(_vm.favoritesAudiencesList, function (item, i) {
                    return _c(
                      "div",
                      {
                        key: i,
                        staticClass: "list__item",
                        class: { active: item?.active },
                        on: {
                          click: function ($event) {
                            return _vm.selectAudience(item, i)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "list__item__header" }, [
                          _c("div", { staticClass: "title" }, [
                            _c("span", [_vm._v(_vm._s(item?.name))]),
                          ]),
                          _c("div", { staticClass: "action" }, [
                            _c("figure", [
                              _c("img", {
                                style: _vm.iconStyle,
                                attrs: { src: "/icons/favorite-icon.svg" },
                              }),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "list__item__body" }, [
                          _c("div", { staticClass: "left" }, [
                            _c("span", [_vm._v("Usuários únicos")]),
                            _c("br"),
                            _c("span", [_vm._v(_vm._s(item.uniqueCount))]),
                          ]),
                          _c("div", { staticClass: "right" }, [
                            _c("span", [_vm._v("Ultima modificação")]),
                            _c("br"),
                            _c("span", [
                              _vm._v(_vm._s(_vm.formateDate(item.updated))),
                            ]),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _vm.hasSomeInformation
            ? _c("div", { staticClass: "modal__body__right" }, [
                _vm._m(2),
                _c(
                  "div",
                  { staticClass: "list" },
                  _vm._l(
                    _vm.searchAudience ? _vm.filtered : _vm.audiencesList,
                    function (item, i) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          staticClass: "list__item",
                          class: { active: item?.active },
                          on: {
                            click: function ($event) {
                              return _vm.selectAudience(item, i)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "list__item__header" }, [
                            _c("div", { staticClass: "title" }, [
                              _c("span", [_vm._v(_vm._s(item.name))]),
                            ]),
                            _c("div", { staticClass: "action" }, [
                              _c("figure", [
                                _c("img", {
                                  style: _vm.iconStyle,
                                  attrs: {
                                    src: item.favorite
                                      ? "/icons/favorite-icon.svg"
                                      : "/icons/favorite-not-filled-icon.svg",
                                  },
                                }),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "list__item__body" }, [
                            _c("div", { staticClass: "left" }, [
                              _c("span", [_vm._v("Usuários únicos")]),
                              _c("br"),
                              _c("span", [_vm._v(_vm._s(item.uniqueCount))]),
                            ]),
                            _c("div", { staticClass: "right" }, [
                              _c("span", [_vm._v("Ultima modificação")]),
                              _c("br"),
                              _c("span", [
                                _vm._v(_vm._s(_vm.formateDate(item.updated))),
                              ]),
                            ]),
                          ]),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ])
            : _vm._e(),
          !_vm.hasSomeInformation
            ? _c("div", { staticClass: "modal__body__no-data" }, [
                _c("strong", [
                  _vm._v(
                    "Não foi encontrado nenhuma audiência correspondente a busca."
                  ),
                ]),
              ])
            : _vm._e(),
        ])
      : _c("div", { staticClass: "modal__loading" }, [_c("z-loading")], 1),
    _c(
      "div",
      { staticClass: "modal--actions" },
      [
        _c("z-btn", {
          attrs: { text: "Cancelar" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        }),
        _c("z-btn", {
          attrs: { text: "Editar audiência", disabled: !_vm.selectedAudiencia },
          on: { click: _vm.editAudience },
        }),
        _c("z-btn", {
          attrs: {
            disabled: !_vm.selectedAudiencia,
            primary: "",
            text: "Selecionar audiência",
          },
          on: {
            click: function ($event) {
              return _vm.selecionarAudiencia()
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal__header__left" }, [
      _c("span", [_vm._v("Selecionar audiência ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("figure", [
        _c("img", {
          attrs: { src: "/icons/favorite-icon.svg", alt: "Favoritos" },
        }),
      ]),
      _c("span", [_vm._v("Favoritos")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("figure", [
        _c("img", { attrs: { src: "/icons/tree-2-icon.svg", alt: "Todos" } }),
      ]),
      _c("span", [_vm._v("Todos")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }