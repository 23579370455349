<template>
  <div class="modal">
    <div class="modal__header">
      <div class="modal__header__left">
        <span>Audiência filtrada</span>
      </div>
      <div class="modal__header__right">
        <z-btn icon="mdi-plus" primary text="Novo filtro" small @click="newFilter" />
      </div>
    </div>
    <div class="modal__body">
      <div class="modal__body__select mb-3">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <z-select
              label="Selecione uma Audiência Filtrada"
              :items="audiences"
              v-model="selectedFilteredAudience"
              :hideDetails="true"
              item-text="name"
              item-value="id"
              return-object
            />
          </v-col>
        </v-row>
      </div>

      <div v-if="selectedFilteredAudience" class="modal__body__data">
        <!-- <span class="title-data">Seletor de dados</span>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <z-select label="Selecione uma coluna" :items="[]" :hideDetails="true" />
          </v-col>
        </v-row> -->
<!-- 
        <div class="modal__body__data__table mt-3">
          <div class="header">
            <div class="line">
              <div><span>COMPROU</span></div>
              <div><span> AÇÕES</span></div>
            </div>
          </div>
          <div class="body mt-2">
            <div class="line">
              <div class="left">
                <figure><img src="/icons/search_data_icon.svg" /></figure>
                <span>SIM</span>
              </div>
              <div>
                <v-btn-toggle v-model="toggle_week_yes" color="primary">
                  <v-btn rounded depressed small class="mr-1">SMS</v-btn>
                  <v-btn rounded depressed small class="mr-1"> E-MAIL </v-btn>
                  <v-btn rounded depressed small class="mr-1"> PUSH </v-btn>
                  <v-btn rounded depressed small class="mr-1"> WHATSAPP </v-btn>
                  <v-btn rounded depressed small class="mr-1"> SEM AÇÃO </v-btn>
                </v-btn-toggle>
              </div>
            </div>
            <div class="line">
              <div class="left">
                <figure><img src="/icons/search_data_icon.svg" /></figure>
                <span>NÃO</span>
              </div>
              <div>
                <v-btn-toggle v-model="toggle_week_no" color="primary">
                  <v-btn rounded depressed small class="mr-1">SMS</v-btn>
                  <v-btn rounded depressed small class="mr-1"> E-MAIL </v-btn>
                  <v-btn rounded depressed small class="mr-1"> PUSH </v-btn>
                  <v-btn rounded depressed small class="mr-1"> WHATSAPP </v-btn>
                  <v-btn rounded depressed small class="mr-1"> SEM AÇÃO </v-btn>
                </v-btn-toggle>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <div class="modal--actions">
      <z-btn text="Cancelar" @click="$emit('close')"></z-btn>
      <z-btn primary text="confirmar" @click="$emit('close', selectedFilteredAudience)"></z-btn>
    </div>
  </div>
</template>

<script>
import ApiClient from "@/services/ApiClient";
export default {
  props: {
    dbConnectionId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      toggle_week_no: "",
      toggle_week_yes: "",
      iconStyle: {
        width: "20px",
        height: "20px",
        cursor: "pointer",
      },
      http: new ApiClient(),
      audiences: [],
      selectedFilteredAudience: null
    };
  },
  async created() {
    this.listFilteredAudiences();
  },
  methods: {
    newFilter(){
      this.$emit('createNewFilter')
    },
    async listFilteredAudiences() {
      // audienceFilter/list

      await this.http
        .get(`audienceFilter/list?databaseConfigId=${this.dbConnectionId}`)
        .then((resp) => {
          this.audiences = resp
        })
        .catch((err) => {
          throw err;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.modal {
  background: #ffffff;
  border-radius: 15px;
  color: $z-black-1;
  padding: $z-s-1;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    &__left {
      span {
        font-weight: 700;
        font-size: 1.1rem;
        line-height: 15px;
      }
    }
    &__right {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &__body {
    margin: $z-s-1 0;
    // border: 2px solid;
    // display: flex;
    // align-items: flex-start;
    // flex-wrap: wrap;
    // gap: $z-s-1 $z-s-3;

    &__data {
      .title-data {
        font-weight: 700;
        font-size: 1.1rem;
        line-height: 15px;
      }

      &__table {
        margin-bottom: $z-s-4;

        .header,
        .body {
          .line {
            display: flex;
            align-items: center;
            gap: $z-s-1;
            margin-bottom: 0.5rem;
            & > div {
              flex: 1 1 300px;
              width: 100%;

              &:nth-child(2) {
                flex: 3 1 500px;
              }
            }
            // @media (max-width: 750px) {
            //   & > div {
            //     width: auto;
            //     span {
            //       font-size: 0.85rem;
            //       font-weight: 500;
            //     }
            //     &:nth-child(2) {
            //       flex: 1 1 300px !important;
            //     }
            //   }
            //   .hide {
            //     display: none;
            //   }
            // }
          }
        }

        .header {
          .line {
            & > div {
              span {
                font-weight: 700;
                font-size: 0.9rem;
                line-height: 15px;
                letter-spacing: 0.15px;
                text-transform: uppercase;
                color: #666666;
              }
            }
          }
        }
        .body {
          .line {
            .left {
              display: flex;
              align-items: center;
              gap: 0.5rem;

              span {
                font-weight: 500;
                font-size: 1rem;
                line-height: 15px;
                color: #333333;
              }
              figure {
                width: 25px;
                height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }
            }
          }
        }
      }
    }
  }
  &--actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
  }
}
</style>
