<template>
  <div class="modal">
    <div class="modal__header">
      <div class="modal__header__left">
        <span>Selecionar audiência </span>
      </div>
      <div class="modal__header__right">
        <z-input
          v-model="searchAudience"
          label="Pesquisar"
          placeholder="Pesquisar"
          prependIcon="$search_rounded"
          type="text"
          :hide-details="true"
        />
        <z-btn icon="mdi-plus" primary text="nova audiência" @click="newAudience" />
      </div>
    </div>

    <!-- <div
      v-if="lastSavedAudience?.name && lastSavedAudienceProp"
      class="modal__current-journey"
    >
      <small
        >Última audiência salva: <strong>{{ lastSavedAudience.name }}</strong></small
      >
    </div> -->

    <div v-if="isLoaded" class="modal__body">
      <div v-if="hasSomeInformation" class="modal__body__left">
        <div class="title">
          <figure><img src="/icons/favorite-icon.svg" alt="Favoritos" /></figure>
          <span>Favoritos</span>
        </div>

        <div class="list">
          <div
            v-for="(item, i) in favoritesAudiencesList"
            :key="i"
            class="list__item"
            :class="{ active: item?.active }"
            @click="selectAudience(item, i)"
          >
            <div class="list__item__header">
              <div class="title">
                <span>{{ item?.name }}</span>
              </div>
              <div class="action">
                <figure>
                  <img :style="iconStyle" src="/icons/favorite-icon.svg" />
                </figure>
              </div>
            </div>
            <div class="list__item__body">
              <div class="left">
                <span>Usuários únicos</span>
                <br />
                <span>{{ item.uniqueCount }}</span>
              </div>
              <div class="right">
                <span>Ultima modificação</span>
                <br />
                <span>{{ formateDate(item.updated) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="hasSomeInformation" class="modal__body__right">
        <div class="title">
          <figure><img src="/icons/tree-2-icon.svg" alt="Todos" /></figure>
          <span>Todos</span>
        </div>

        <div class="list">
          <div
            v-for="(item, i) in searchAudience ? filtered : audiencesList"
            :key="item.id"
            class="list__item"
            :class="{ active: item?.active }"
            @click="selectAudience(item, i)"
          >
            <div class="list__item__header">
              <div class="title">
                <span>{{ item.name }}</span>
              </div>
              <div class="action">
                <figure>
                  <img
                    :style="iconStyle"
                    :src="
                      item.favorite
                        ? '/icons/favorite-icon.svg'
                        : '/icons/favorite-not-filled-icon.svg'
                    "
                  />
                </figure>
              </div>
            </div>
            <div class="list__item__body">
              <div class="left">
                <span>Usuários únicos</span>
                <br />
                <span>{{ item.uniqueCount }}</span>
              </div>
              <div class="right">
                <span>Ultima modificação</span>
                <br />
                <span>{{ formateDate(item.updated) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!hasSomeInformation" class="modal__body__no-data">
        <strong>Não foi encontrado nenhuma audiência correspondente a busca.</strong>
      </div>
    </div>

    <div v-else class="modal__loading">
      <z-loading />
    </div>

    <div class="modal--actions">
      <z-btn text="Cancelar" @click="$emit('close')"></z-btn>
      <z-btn
        text="Editar audiência"
        :disabled="!selectedAudiencia"
        @click="editAudience"
      ></z-btn>
      <z-btn
        :disabled="!selectedAudiencia"
        primary
        @click="selecionarAudiencia()"
        text="Selecionar audiência"
      ></z-btn>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ApiClient from "@/services/ApiClient";
export default {
  data() {
    return {
      isLoaded: true,
      filtered: [],
      iconStyle: {
        width: "20px",
        height: "20px",
        cursor: "pointer",
      },
      http: new ApiClient(),
      audiencesList: [],
      selectedAudiencia: null,
      searchAudience: "",
    };
  },
  props: {
    nodeProp: { type: Object, default: null },
    audienceIdProp: { type: String, default: null },
    lastSavedAudienceProp: { type: String, default: null },
  },
  async created() {
    //  console.log(this.nodeProp)
    await this.getAudiencesList();
    this.selectAudienceProp(this.audienceIdProp);
  },
  computed: {
    lastSavedAudience() {
      let obj = this.audiencesList.find((e) => e.id == this.lastSavedAudienceProp);
      return obj || {};
    },
    favoritesAudiencesList() {
      let ITEMS = [];
      if (this.searchAudience) {
        ITEMS = this.audiencesList
          .filter((d) => d.favorite == true)
          .filter((h) =>
            h.name.toLowerCase().includes(this.searchAudience.toLocaleLowerCase())
          );
      } else {
        ITEMS = this.audiencesList.filter((d) => d.favorite == true);
      }

      return ITEMS;
    },
    hasSomeInformation() {
      let INFORMATION = false;

      if (this.searchAudience) {
        INFORMATION = this.filtered.length > 0 || this.favoritesAudiencesList.length > 0;
      } else {
        INFORMATION =
          this.audiencesList.length > 0 || this.favoritesAudiencesList.length > 0;
      }

      return INFORMATION;
    },
  },

  watch: {
    searchAudience(nv) {
      this.filtered = this.audiencesList.filter((d) =>
        d.name.toLowerCase().includes(nv.toLowerCase())
      );
    },
  },
  methods: {
    editAudience() {
      if (this.selectedAudiencia)
        this.$emit("editSelectedAudience", this.selectedAudiencia);
    },
    newAudience() {
      this.$emit("newAudience");
    },
    formateDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    getFavorite() {},
    async getAudiencesList() {
      try {
        this.isLoaded = false;
        const req = await this.http.get("audience/list");
        this.audiencesList = req;
        const current_audience = req.find((e) => e.id == this.audienceIdProp);
        const current_audience_idx = req.findIndex((e) => e.id === this.audienceIdProp);

        if (current_audience_idx >= 0) {
          this.audiencesList.splice(current_audience_idx, 1);
          this.audiencesList.unshift(current_audience);
        }
        // this.audiencesList = this.audiencesList.map(x => {
        //   x.active = false;
        //   return x;
        // })
        this.showInformations = true;
        this.isLoaded = true;
      } catch (error) {
        this.$toast.error("Erro ao listar audiências");
        this.isLoaded = true;
      }
    },
    selectAudience(item) {
      this.audiencesList.map((item) => (item.active = false));
      item.active = !item.active;
      this.selectedAudiencia = item;
      this.$forceUpdate();
    },
    selectAudienceProp(data) {
      this.audiencesList.map((item) => (item.active = false));
      const FOUND = this.audiencesList.findIndex((d) => d.name === data || d.id === data);

      if (FOUND >= 0) {
        this.audiencesList[FOUND].active = !this.audiencesList[FOUND].active;
        this.selectedAudiencia = this.audiencesList[FOUND];
      }
      this.$forceUpdate();
    },
    selecionarAudiencia() {
      this.$emit("close", this.selectedAudiencia);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.modal {
  background: #ffffff;
  border-radius: 15px;
  color: $z-black-1;
  padding: $z-s-1;
  min-height: 89.5vh;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    &__left {
      span {
        font-weight: 700;
        font-size: 1.1rem;
        line-height: 15px;
      }
    }
    &__right {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &__body {
    flex: 1 1 100%;
    height: 100%;
    margin: 0.5rem 0;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: $z-s-1 $z-s-3;

    & > div {
      flex: 1 1 400px;

      .title {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: 0.5rem;
        padding: 0.5rem;

        figure {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        span {
          font-weight: 300;
          font-size: 20px;
          line-height: 23px;
        }
      }

      .list {
        padding: 0.5rem;
        max-height: 55vh;
        height: 100%;
        overflow-y: auto;
        @include trackScrollBar;
        &__item {
          cursor: pointer;
          padding: 0.8rem;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
          border-radius: 10px;
          // border: 5px solid;
          &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .title {
              span {
                font-weight: 700;
                font-size: 0.8rem;
                line-height: 15px;
                letter-spacing: 0.15px;
                text-transform: uppercase;
              }
            }
            .action {
              figure {
                width: 20px;
                height: 20px;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }
            }
          }
          &__body {
            display: flex;
            align-items: flex-start;
            gap: $z-s-1 $z-s-2;
          }
          &:not(:last-child) {
            margin-bottom: $z-s-1;
          }
        }
        .active {
          background: #0273c3;
          color: white;
        }
      }
    }

    &__no-data {
      height: 60vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__loading {
    flex: 1 1 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
  }
}
</style>
