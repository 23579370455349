<template>
  <div class="modal">
    <div class="modal__header">
      <div class="modal__header__left">
        <span>A divisão deve ser feita por?</span>
      </div>
    </div>
    <div class="modal__body">
      <v-form ref="form">
        <v-row align="center" justify="center" class="">
          <v-col cols="12">
            <v-radio-group
              :rules="[(v) => !!v || 'Campo obrigatório']"
              v-model="selectedChoose"
              hide-details
              small
            >
              <v-radio
                v-for="(item, i) in options"
                :key="i"
                style="width: 250px"
                :value="item.value"
                small
              >
                <template #label>
                  <span style="color: #000000">{{ item.text }}</span>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-form>
    </div>

    <div class="modal--actions">
      <z-btn text="Cancelar" @click="$emit('close')"></z-btn>
      <z-btn primary @click="insertChoose()" text="Incluir"></z-btn>
    </div>
  </div>
</template>

<script>
import ApiClient from "@/services/ApiClient";
export default {
  props: {
    currentNodeClickedProp: { type: Object, default: null },
    // mensageriaSteps: {
    //   type: Array,
    //   default: () => [],
    // },
  },
  data() {
    return {
      options: [
        { text: "Abertura", value: 'open' },
        { text: "Clique", value: 'click' },
      ],
      iconStyle: {
        width: "20px",
        height: "20px",
        cursor: "pointer",
      },
      http: new ApiClient(),
      mockDatas: [],
      selectedAudiencia: null,
      periodValue: null,
      selectedChoose: null,
      to: null,
      periods: [
        { value: "hour", text: "Horas" },
        { value: "day", text: "Dias" },
        { value: "week", text: "Semanas" },
      ],
      periodSelected: null,
    };
  },
  async created() {},
  methods: {
    insertChoose() {
      if (this.$refs.form.validate()) this.$emit("chooseDivision", this.selectedChoose);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.modal {
  background: #ffffff;
  border-radius: 15px;
  color: $z-black-1;
  padding: $z-s-1;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    &__left {
      span {
        font-weight: 700;
        font-size: 1.1rem;
        line-height: 15px;
      }
    }
    &__right {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &__body {
    margin: $z-s-1 0;
    // border: 2px solid;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: $z-s-1 $z-s-3;

    & > div {
      flex: 1 1 400px;
      .title {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: 0.5rem;
        padding: 0.5rem;

        span {
          font-weight: 300;
          font-size: 20px;
          line-height: 23px;
        }
      }

      .list {
        padding: 0.5rem;
        max-height: 60vh;
        height: 100%;
        overflow-y: auto;
        @include trackScrollBar;
        &__item {
          cursor: pointer;
          padding: 0.8rem;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
          border-radius: 10px;
          // border: 5px solid;
          &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .title {
              span {
                font-weight: 700;
                font-size: 0.8rem;
                line-height: 15px;
                letter-spacing: 0.15px;
                text-transform: uppercase;
              }
            }
            .action {
              figure {
                width: 20px;
                height: 20px;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }
            }
          }
          &__body {
            display: flex;
            align-items: flex-start;
            gap: $z-s-1 $z-s-2;
          }
          &:not(:last-child) {
            margin-bottom: $z-s-1;
          }
        }
        .active {
          background: #0273c3;
          color: white;
        }
      }
    }
  }
  &--actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
  }
}
</style>
