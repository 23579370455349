var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal" }, [
    _vm._m(0),
    _c("div", { staticClass: "modal__body" }),
    _c(
      "div",
      { staticClass: "modal--actions" },
      [
        _c("z-btn", {
          attrs: { text: "Cancelar" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        }),
        _c("z-btn", {
          attrs: { primary: "", text: "Remover" },
          on: { click: _vm.remove },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal__header" }, [
      _c("div", { staticClass: "modal__header__left" }, [
        _c("span", [_vm._v("Remoção de step ")]),
        _c("br"),
        _c("small", [_vm._v("Tem certeza que deseja remover o step")]),
      ]),
      _c("div", { staticClass: "modal__header__right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }