var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal" }, [
    _vm._m(0),
    _vm._m(1),
    _c(
      "div",
      { staticClass: "modal--actions" },
      [
        _c("z-btn", {
          attrs: { small: "", text: "Cancelar" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        }),
        _c("z-btn", {
          attrs: { primary: "", small: "", text: "Confirmar" },
          on: {
            click: function ($event) {
              return _vm.$emit("confirm")
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal__header" }, [
      _c("div", { staticClass: "modal__header__left" }, [
        _c("span", [_vm._v("ALERTA DE OPTOUT ")]),
        _c("br"),
        _c("small"),
      ]),
      _c("div", { staticClass: "modal__header__right" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal__body" }, [
      _c("div", { staticClass: "modal__body__list" }, [
        _c("p", [
          _vm._v(
            "Essa jornada está ignorando o optout, tem certeza que deseja continuar?"
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }